import { Subscription, Subject, Observable, BehaviorSubject } from 'rxjs';

const naturalCompare = require('string-natural-compare');

import { Customer } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { StorageService } from '../storage.service';
import { ApiService } from '../api.service';

export class CustomerList {
    private subscriptions: Array<Subscription>;
    private search: string;
    private nameSearch: string;
    private soSearch: string;
    private emailSearch: string;
    private orgSearch: string;
    private listUpdated = new Subject<boolean>();
    private currentPage = new BehaviorSubject<number>(1);
    private lastSearch: string;

    public customerList: Array<Customer>;
    public sortNameAsc: boolean;
    public sortSOAsc: boolean;
    public sortEmailAsc: boolean;
    public sortOrgAsc: boolean;
    public searchOkay: boolean;

    constructor(
        private dataService: DataService,
        private apiService: ApiService,
        private storageService: StorageService,
    ) {
        this.subscriptions = [];
        this.customerList = [];
        this.lastSearch = null;

        this.sortNameAsc = true;
        this.sortSOAsc = true;
        this.sortEmailAsc = true;
        this.sortOrgAsc = true;

        this.search = '';
        this.nameSearch = '';
        this.soSearch = '';
        this.emailSearch = '';
        this.orgSearch = '';
        this.searchOkay = false;
        if (this.storageService) {
            this.storageService.getSessionData(
                        [ 'customerSearch', 'customerNameSearch', 'customerSOSearch',
                            'customerEmailSearch', 'customerOrgSearch', 'currentPage' ]
                    ).toPromise().then(values => {
                if (values['customerSearch']) {
                    this.search = values['customerSearch'];
                } else {
                    if (values['customerNameSearch']) {
                        this.nameSearch = values['customerNameSearch'];
                    }
                    if (values['customerSOSearch']) {
                        this.soSearch = values['customerSOSearch'];
                    }
                    if (values['customerEmailSearch']) {
                        this.emailSearch = values['customerEmailSearch'];
                    }
                    if (values['customerOrgSearch']) {
                        this.orgSearch = values['customerOrgSearch'];
                    }
                }
                this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
                if (values['currentPage']) {
                    this.setCurrentPage(values['currentPage']);
                }
            });
        }
        this.subscriptions.push(this.dataService.getCustomersObservable().subscribe(customers => {
            if (Array.isArray(customers)) {
                if (customers) {
                    customers.sort(this.sortByOrg.bind(this));
                    customers.sort(this.sortByName.bind(this));
                    // customers.sort(this.sortCustomerBySO.bind(this));
                    customers.sort(this.sortByEmail.bind(this));
                }
                this.customerList = customers;
            } else {
                this.customerList = [];
            }
            this.setUpdated(true);
        }));
    }

    public destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public setUpdated(state: boolean) {
        this.listUpdated.next(state);
    }
    public getUpdatedObservable(): Observable<boolean> {
        return this.listUpdated.asObservable();
    }

    public toggleSortOrder(which: string) {
        switch (which) {
            case 'name':
                this.sortNameAsc = !this.sortNameAsc;
                this.customerList.sort(this.sortByName.bind(this));
                break;
            case 'so':
                this.sortSOAsc = !this.sortSOAsc;
                this.customerList.sort(this.sortBySO.bind(this));
                break;
            case 'email':
                this.sortEmailAsc = !this.sortEmailAsc;
                this.customerList.sort(this.sortByEmail.bind(this));
                break;
            case 'org':
                this.sortOrgAsc = !this.sortOrgAsc;
                this.customerList.sort(this.sortByOrg.bind(this));
                break;
        }
        this.setUpdated(true);
    }

    private sortByName(a: Customer, b: Customer): number {
        if (this.sortNameAsc) {
            return naturalCompare(a.name, b.name);
        } else {
            return naturalCompare(b.name, a.name);
        }
    }

    private sortBySO(a: Customer, b: Customer): number {
        if (this.sortNameAsc) {
            return naturalCompare(a.so, b.so);
        } else {
            return naturalCompare(b.so, a.so);
        }
    }

    private sortByEmail(a: Customer, b: Customer): number {
        if (this.sortEmailAsc) {
            return naturalCompare(a.email, b.email);
        } else {
            return naturalCompare(b.email, a.email);
        }
    }

    private sortByOrg(a: Customer, b: Customer): number {
        if (this.sortOrgAsc) {
            return naturalCompare(a.org, b.org);
        } else {
            return naturalCompare(b.org, a.org);
        }
    }

    public setCurrentPage(page: number) {
        if (this.storageService) {
            this.storageService.setSessionData({ currentPage: page });
        }
        this.currentPage.next(page);
    }
    public getCurrentPageObservable(): Observable<number> {
        return this.currentPage.asObservable();
    }

    public setSearch(search: string) {
        this.search = search;
        if (search) {
            this.nameSearch = '';
            this.emailSearch = '';
            this.orgSearch = '';
        }
        if (this.storageService) {
            this.storageService.setSessionData({ customerSearch: this.search });
        }
        this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
    }
    public getSearch(): string {
        return this.search;
    }
    public isSearchBlank(): boolean {
        return (!this.search && !this.nameSearch && !this.emailSearch && !this.orgSearch);
    }

    public setNameSearch(search: string) {
        if (search) {
            this.nameSearch = search;
            this.search = '';
        } else {
            this.nameSearch = '';
        }
        if (this.storageService) {
            this.storageService.setSessionData({ customerNameSearch: this.nameSearch });
        }
        this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
    }
    public getNameSearch(): string {
        return this.nameSearch;
    }

    public setSOSearch(search: string) {
        if (search) {
            this.soSearch = search;
            this.search = '';
        } else {
            this.soSearch = '';
        }
        if (this.storageService) {
            this.storageService.setSessionData({ customerSOSearch: this.soSearch });
        }
        this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
    }
    public getSOSearch(): string {
        return this.nameSearch;
    }

    public setEmailSearch(search: string) {
        if (search) {
            this.emailSearch = search;
            this.search = '';
        } else {
            this.emailSearch = '';
        }
        if (this.storageService) {
            this.storageService.setSessionData({ customerEmailSearch: this.emailSearch });
        }
        this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
    }
    public getEmailSearch(): string {
        return this.emailSearch;
    }

    public setOrgSearch(search: string) {
        if (search) {
            this.orgSearch = search;
            this.search = '';
        } else {
            this.orgSearch = '';
        }
        if (this.storageService) {
            this.storageService.setSessionData({ customerOrgSearch: this.orgSearch });
        }
        this.searchOkay = (!!this.search || !!this.nameSearch || !!this.soSearch || !!this.emailSearch || !!this.orgSearch);
    }
    public getOrgSearch(): string {
        return this.orgSearch;
    }

    public clearSearch() {
        this.nameSearch = '';
        this.soSearch = '';
        this.emailSearch = '';
        this.orgSearch = '';
        this.search = '';
        this.searchOkay = false;
        if (this.storageService) {
            this.storageService.setSessionData({ customerSearch: this.search });
            this.storageService.setSessionData({ customerNameSearch: this.nameSearch });
            this.storageService.setSessionData({ customerSOSearch: this.soSearch });
            this.storageService.setSessionData({ customerEmailSearch: this.emailSearch });
            this.storageService.setSessionData({ customerOrgSearch: this.orgSearch });
        }
        this.setCurrentPage(1);
    }

    public performSearch(): Promise<string> {
        const thisSearch = this.search + ' ' + this.emailSearch + ' ' + this.nameSearch
                                + ' ' + this.soSearch + ' ' + this.orgSearch;
        if ((this.lastSearch === null) || (this.lastSearch !== thisSearch)) {
            this.lastSearch = thisSearch;
            this.apiService.pleaseWait(30);
            this.setCurrentPage(1);
            return this.apiService.getCustomers(this.search, this.emailSearch, this.nameSearch, this.soSearch, this.orgSearch);
        }
        return new Promise((resolve, reject) => {
            resolve('');
        });
    }

}
