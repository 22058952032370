
// DeviceTypes indexed by third char of SN as number
export const DeviceTypes = [ '', '100', '200', '300', 'AIO', 'AIOP', 'LT1' ];
export const DeviceStatus = [ 'PEND', 'REG', 'UNREG' ]; // used only in FE to decode Device.stat
export const DeviceComm = [ 'OFFLINE', 'ONLINE', 'ERROR' ]; // used only in FE to decode Device.olst
// if entries added to DeviceLogTypes then must add migration step to add entries to device_log_types table
//  this used by db import as well and front end
export const DeviceLogTypes = [
    'LOG_UNK', // must be first!
    'LIC_ADD',
    'LIC_DEL',
    'LIC_APLY_ADD',
    'LIC_APLY_DEL',
    'REG_ADD',
    'REG_REG',
    'REG_UNREG',
];
export const JobLogTypes = [
    'JOB_ERROR',
    'JOB_CREATE',
    'JOB_BLOCK',
    'JOB_START',
    'JOB_FINISH',
];

// /api/v1/customers POST with CustomerSearchParams responds with array of Customer
export interface Customer {
    id: number;  // internal ID, not shown in front end but used to get devices
    name: string; // name of customer
    so: string; // sales order
    email: string; // contact email of customer
    org: string; // organization of customer
    devs: Array<number>; // 2 dimensional array of registered / total devices
    lics:  Array<number>; // 2 dimensional array of assigned / total licenses
}

export interface CustomerSearchParams {
    search: string; // takes precedence and performs OR search on following 4 fields unless blank.
    name: string; // ignored if search non-blank, AND search with email, org, so
    so: string; // ignored if search non-blank, AND search with email, org, name
    email: string; // ignored if search non-blank, AND search with name, org, so
    org: string; // ignored if search non-blank, AND search with name, email, so
}

export interface BriefLicense {
    id: number;
    pid: string;
    prod: string;
    seats: string;
    exp: string;
}
export interface BriefLicenseOrder {
    name: string;
    blics: Array<BriefLicense>;
}

// /api/v1/devices?customer=<customer id> POST with DeviceSearchParams responds with { cid: id, devices: array of Device }
export interface Device {
    id: number; // internal ID, not shown in front end but used to act on device
    sn: string; // serial number
    type: string; // type of device 'AIO', 'Laptop Gen1', etc... need to keep short but clear
    sel?: boolean; // selected - front end only
    exp?: boolean; // expanded - front end only
    slo?: boolean; // show license orders - front end only
    act?: string; // registration code
    stat?: number; // status: 0=pending, 1=registered, 2=unregistered
    stxt?: string; // status text for mouse over tooltip
    ip?: string; // ip address
    olst?: number; // online state: 0=offline, 1=online, 2=error
    so?: string; // zSpace sales order for this device
    po?: string; // customer purchase order for this device
    rits?: string; // request initiated date/time stamp
    name?: string; // system/domain name
    idate?: number; // GMT timestamp of when changes last applied
    tidt?: string; // string representation of above timestamp (set and used only in front end)
    lics: Array<License>; // array of License assigned to this device
    show?: boolean; // flag for filtering (set and used only in front end)
    blos?: Array<BriefLicenseOrder>; // array of license orders for choosing license to add (set and used only in front end)
    codeinput?: string; // holder for manual code input chars accross refreshes
}

export interface DeviceSearchParams {
    emails: string; // contact emails, comma separated
    refresh: boolean; // setting to true prevents extension of login idle timeout
}

// /api/v1/licenses?customer=<customer id> GET responsds with { cid: id, orders: array of LicenseOrder }
export interface License {
    id: number; // internal ID, not shown in front end but used to reference specific license
    pid?: string; // internal product id used to reference the product
    prod: string; // product name to which this license applies
    key: string; // license key
    avail: number; // number of seats not assigned
    total: number; // total seats for this license
    exp: number; // GMT timestamp of license expiration
    texp?: string; // string representation of above timestamp (set and used only in front end)
    show?: boolean; // flag for filtering (set and used only in front end)
    sel?: boolean; // flag for selection (set and used only in front end)
    stat?: number; // status of license (only valid for inclusion in device data)
                    // 0=added but not applied, 1=applied to device, 2=removed but not applied, 3=added not saved, 4=removed not saved
    so?: string; // zSpace sales order copy from LicenseOrder (only valid for inclusion in device data)
    po?: string; // customer purchase order copy from LicenseOrder (only valid for inclusion in device data)
}
export interface LicenseOrder {
    id: number; // internal ID, not shown in front end but used to reference license order
    so: string; // zSpace sales order for this set of licenses
    po: string; // customer purchase order for this set of licenses
    lics: Array<License>; // array of licenses for each product in the order
    show?: boolean; // flag to control expand/contract of order contents (set and used only in front end)
}

// /api/v1/dat GET responds with ConfigData
export interface ConfigData {
    ip: string;
}

// /api/v1/ping GET responds with 'OKAY' if user is logged in and not expired, otherwise no response
//      forces log out of user if session has just expired. Up to front end to redirect to login page

// /api/v1/log GET resonds with json array of strings
export interface LogSearchParams {
    type: string; // log type 'device' (default) or 'job'
    email: string; // contact email
    dids: string; // comma separated list of <device serial numbers>
    from: string; // ISO format date string to start inclusive date/time range
    to: string; // ISO format date string to end inclusive date/time range
}

export interface LogEntry {
    id: number; // internal db record id
    type: string; // type of log entry as specified in docs/device-logs.txt
    jtype: string; // job type for job log entry
    ts: string; // timestamp in ISO format
    sn: string; // serial number of device for device log entry
    prod: string; // description of product (from license) for device log entry
    data: Object; // various data based on type as specified in docs/device-logs.txt
}

// /api/v1/registerdevices POST
//      params: dids=<comma separated list of <device serial numbers>:<activation code>
//      attempts to register the devices specified in supplied list
//      responds with json { okay: array of SNs that succeeded, fail: array of SNs that failed }
//          TODO: add some type of reason for failure to fail array?

// /api/v1/unregisterdevices POST
//      params: dids=<comma separated list of <device serial numbers>:<activation code>
//      attempts to unregister the devices specified in supplied list
//      responds with json { okay: array of SNs that succeeded, fail: array of SNs that failed }
//          TODO: add some type of reason for failure to fail array?

// /api/v1/save POST
//      params: chgs: array of DeviceChange
//      responsds with json DeviceChangeResponse
export interface DeviceChange {
    did: number; // device id
    lid: number; // license id
    stat: number; // same as License.stat
    act: string; // action, del=remove license, add=add license
}
export interface DeviceChangeResponse {
    okay: Array<DeviceChange>
    fail: Array<DeviceChange>
}

export interface DeployStatusMessage {
    complete: boolean;
    lines: Array<string>;
}

export interface Job {
    creat: string;
    id: number;
    name: string;
    perc: number;
    sel?: boolean;
    show?: boolean;
    sn: string;
    status: string;
    type: string;
    updat: string;
    ct?: string;
    ut?: string;
}
