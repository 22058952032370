import { MatDialog } from '@angular/material/dialog';

import { Subscription, Subject, Observable } from 'rxjs';

const naturalCompare = require('string-natural-compare');
import html2pdf from 'html2pdf.js';

import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';
import * as Utils from '../utils';

export interface LicenseViewerResponse {
    title: string;
    msg: string;
    email: boolean;
    added: boolean;
}
export interface LicenseViewer {
    user_id: string;
    email: string;
}
export interface PendingOrder {
    internal_id: string;
    order_date: string;
    sales_order: string;
    customer_name: string;
    product_part_number: string;
    product_code: string;
    qty: string;
    status: string;
    end_user_licensee: string;
    end_user_email: string;
    reseller_access_id: string;
    estimated_ship_date: string;
    product_name: string;
}
export interface AppLicense {
    id: number;
    name: string;
    key: string;
    qty: string;
    exp: string;
    so: string;
    po: string;
    org: string;
    sel?: boolean;
    show?: boolean;
}
export interface AppLicenseResult {
    InternalID: number;
    Type: string; // 'License Key'
    LimeLM_Product_ID: string;
    third_party: string;
    licensekey: string;
    organization: string;
    organization_email: string;
    end_user: string;
    max_activations: string;
    activations_used: string;
    expire_date: string;
    expiry_date: string;
    end_user_email: string;
    reseller: string;
    LimeLM_LicenseKey_ID: number;
    sales_order_number: string;
    purchase_order_number: string;
    download_link: string;
    end_user_contact: string;
    notes: string;
    'max(so.sales_order_number)': number;
    ship_date: string;
    eula_status: string;
    sn: string;
}

export class AppLicenseList {
    private licenseList: Array<AppLicense>;
    private subscriptions: Array<Subscription>;
    private nameFilter: string;
    private statusFilter: string;
    private soFilter: string;
    private poFilter: string;
    private orgFilter: string;
    private filter: string;
    private listUpdated = new Subject<boolean>();

    public sortExpireAsc: boolean;
    public filteredLicenseList: Array<AppLicense>;
    public selectedCount: number;

    constructor(
                private dataService: DataService,
                private matDialog: MatDialog,
                private messageDialog: MessageDialog,
            ) {
        this.licenseList = [];
        this.subscriptions = [];
        this.sortExpireAsc = true;
        this.filteredLicenseList = [];
        this.nameFilter = '';
        this.statusFilter = 'ALL';
        this.soFilter = '';
        this.poFilter = '';
        this.orgFilter = '';
        this.filter = '';
        this.selectedCount = 0;

        this.subscriptions.push(dataService.getAppLicensesObservable().subscribe(licenses => {
            if (!Array.isArray(licenses) || (licenses.length === 0)) {
                this.licenseList = [];
            } else {
                this.licenseList = licenses;
            }
            this.applyFilters(false);
        }));
    }

    private formatDate(dstr: string): string {
        if (dstr) {
            const date = new Date(dstr);
            return date.toLocaleDateString();
        }
        return '';
    }

    public destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public setUpdated(state: boolean) {
        this.listUpdated.next(state);
    }
    public getUpdatedObservable(): Observable<boolean> {
        return this.listUpdated.asObservable();
    }

    public getSelected(): Array<AppLicense> {
        const selected: Array<AppLicense> = [];

        for (const license of this.licenseList) {
            if (license.sel) {
                selected.push(license);
            }
        }

        return selected;
    }

    public clearFilters() {
        this.filter = '';
        this.nameFilter = '';
        this.statusFilter = 'ALL';
        this.soFilter = '';
        this.poFilter = '';
        this.orgFilter = '';
        this.applyFilters();
    }

    public applyFilter(filter: string) {
        this.filter = filter;
        if (filter) {
            this.nameFilter = '';
            this.statusFilter = 'ALL';
            this.soFilter = '';
            this.poFilter = '';
            this.orgFilter = '';
        }
        this.applyFilters();
    }

    public applyNameFilter(filter: string) {
        if (!filter) {
            this.nameFilter = '';
        } else {
            this.nameFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyStatusFilter(filter: string) {
        if (!filter || (filter === 'ALL')) {
            this.statusFilter = 'ALL';
        } else {
            this.statusFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applySOFilter(filter: string) {
        if (!filter) {
            this.soFilter = '';
        } else {
            this.soFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyPOFilter(filter: string) {
        if (!filter) {
            this.poFilter = '';
        } else {
            this.poFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyOrgFilter(filter: string) {
        if (!filter) {
            this.orgFilter = '';
        } else {
            this.orgFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public toggleSortOrder(which: string) {
        switch (which) {
            case 'expire':
                this.sortExpireAsc = !this.sortExpireAsc;
                this.licenseList.sort(this.sortByExpire.bind(this));
                break;
        }
        this.applyFilters();
    }

    private sortByExpire(a: AppLicense, b: AppLicense): number {
        const adate = new Date(a.exp);
        const bdate = new Date(b.exp);
        return ((adate.getTime() - bdate.getTime()) * (this.sortExpireAsc ? 1 : -1));
    }

    public toggleSelect(license: AppLicense) {
        license.sel = !license.sel;
        const found = this.filteredLicenseList.find(fdev => {
            return fdev.id === license.id;
        });
        if (found) {
            found.sel = license.sel;
        }
        this.countSelected();
    }

    public toggleAllSelect(state: boolean) {
        for (let ii = 0; ii < this.licenseList.length; ii++) {
            if (this.licenseList[ii].show) {
                this.licenseList[ii].sel = state;
            }
        }
        this.countSelected();
    }

    private countSelected() {
        this.selectedCount = 0;
        this.licenseList.forEach(license => {
            if (license.sel) {
                this.selectedCount++;
            }
        });
    }

    private applyFilters(clear: boolean = true) {
        for (const license of this.licenseList) {
            license.show = false;
            if (clear) {
                license.sel = false;
            }
        }
        let filteredList = this.licenseList;
        if (this.filter) {
            const lFilter = this.filter.toLocaleLowerCase();
            filteredList = filteredList.filter(license => {
                return ((license.name.toLocaleLowerCase().indexOf(lFilter) >= 0)
                        || (license.org.toLocaleLowerCase().indexOf(lFilter) >= 0));
            });
        } else {
            if (this.nameFilter) {
                const lFilter = this.nameFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(license => {
                    return (license.name.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if (this.statusFilter === 'ACT') {
                const now = (new Date()).getTime();
                filteredList = filteredList.filter(license => {
                    return ((new Date(license.exp)).getTime() > now);
                });
            }
            if (this.soFilter) {
                const lFilter = this.soFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(license => {
                    return (license.so.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if (this.poFilter) {
                const lFilter = this.poFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(license => {
                    return (license.po.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if (this.orgFilter) {
                const lFilter = this.orgFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(license => {
                    return (license.org.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
        }
        for (const license of filteredList) {
            license.show = true;
        }
        this.filteredLicenseList = filteredList;
        this.setUpdated(true);
    }

    private createColumn(text: string, header: boolean = false) {
        const col = document.createElement(header ? 'th' : 'td');
        col.innerHTML = text;
        col.style.color = 'black';
        col.style.border = '1px solid grey';
        return col;
    }

    private licenseCols(license: AppLicense): Array<string> {
        if (!license) {
            return [
                'Name',
                'Key',
                'Quantity',
                'Expires',
                'Sales Order',
                'Purchase Order',
                'Organization / Email',
            ];
        } else {
            return [
                license.name,
                license.key,
                license.qty,
                license.exp,
                license.so,
                license.po,
                license.org,
            ];
        }
    }

    public exportPDF() {
        const table = document.createElement('table');
        const thead = document.createElement('thead');
        table.append(thead);
        const hrow = document.createElement('tr');
        thead.append(hrow);
        for (const col of this.licenseCols(null)) {
            hrow.append(this.createColumn(col, true));
        }
        const tbody = document.createElement('tbody');
        table.append(tbody);
        for (const license of this.filteredLicenseList) {
            const row = document.createElement('tr');
            tbody.append(row);
            const dcols = this.licenseCols(license);
            for (const col of dcols) {
                row.append(this.createColumn(col));
            }
        }
        const opts = {
            margin: 1,
            filename: 'licenses.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        };
        html2pdf().from(table, 'element').set(opts).save();
    }

    public exportCSV() {
        const csv: Array<string> = [];
        for (const license of this.filteredLicenseList) {
            const line: Array<string> = [];
            for (const col of this.licenseCols(license)) {
                line.push(col.replace(/(\n|,)/g, ' '));
            }
            csv.push(line.join(','));
        }
        const content = 'data:text/csv;base64,' + btoa(unescape(encodeURIComponent(csv.join('\n'))));
        Utils.downloadFromDataURL('licenses.csv', content);
    }

}
