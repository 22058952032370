
export function downloadFromDataURL(fileName: string, dataURL: string): void {
    // create blob
    const blob = contentToBlob(dataURL);
    // download the blob
    downloadFromBlob(blob, fileName);
}

function contentToBlob(content: string): Blob {
    // get content string and extract mime type
    const arr = content.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

function downloadFromBlob(blob: Blob, fileName: string) {
    // get object url
    const url = window.URL.createObjectURL(blob);
    // check for microsoft internet explorer
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // use IE download or open if the user using IE
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        // if not using IE then create link element
        const element = document.createElement('a');
        // set download attr with file name
        element.setAttribute('download', fileName);
        // set the element as hidden
        element.style.display = 'none';
        // append the body
        document.body.appendChild(element);
        // set href attr
        element.href = url;
        // click on it to start downloading
        element.click();
        // remove the link from the dom
        document.body.removeChild(element);
    }
}
