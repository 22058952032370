import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import axios, { AxiosInstance } from 'axios';
import { DeviceChange, CustomerSearchParams, DeviceSearchParams,
        LogSearchParams, LogEntry, ConfigData} from '../../../server/src/web-front-end/api-interfaces';
import { DataService } from './data.service';
import { StorageService } from './storage.service';
import { AppLicenseResult, AppLicense, PendingOrder, LicenseViewer, LicenseViewerResponse } from './models/app-license-list';

@Injectable({
    providedIn: 'root'
})
export class ApiService implements OnDestroy {
    private readonly apiEndPoint: string = '/api/v1/';
    private readonly eulaStatusUrl: string = 'https://api.zspace.com/eula/status/1';
    private readonly eulaTextUrl: string = 'https://api.zspace.com/legal/view/end-user-license-agreements';
    private readonly eulaAcceptUrl: string = 'https://api.zspace.com/eula/accept/1';
    private readonly appLicenseUrl: string = 'https://api.zspace.com/ns/search';
    private readonly viewersUrl: string = 'https://api.zspace.com/ns/viewers';
    private readonly pendingOrdersUrl: string = 'https://api.zspace.com/ns/pending';
    // following 2 for documentation purposes only
    // private readonly licenseSyncUrl: string = 'https://api.zspace.com/ns/current_sync'; // POST
    // private readonly pendingOrdersSyncUrl: string = 'https://api.zspace.com/ns/pending_sync'; // GET

    private myAxios: AxiosInstance;
    private subscriptions: Array<Subscription> = [];
    private liveLogEvtSource: EventSource;
    private waitTimeout: number;

    constructor(
                private dataService: DataService,
                private storageService: StorageService,
            ) {
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.myAxios = axios.create({
            timeout: 60000, // 60s timeout
        });
        this.setupSubscriptions();
        window.setTimeout(this.ping.bind(this), 4000);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    private setupSubscriptions(): void {
    }

    public pleaseWait(duration: number, msg: string = '') {
        if (this.waitTimeout !== 0) {
            window.clearTimeout(this.waitTimeout);
            this.waitTimeout = 0;
        }
        const ele = document.querySelector('#wait-overlay');
        if (ele) {
            if (duration === 0) {
                ele.className = 'hidden';
            } else {
                ele.className = '';
                this.waitTimeout = window.setTimeout(function () {
                        ele.className = 'hidden';
                        this.waitTimeout = 0;
                    }.bind(this), duration * 1000);
            }
            const msgElement = ele.querySelector('#wait-msg');
            if (msgElement) {
                msgElement.innerHTML = msg;
            }
        }
    }

    public ping(): void {
        if (location.pathname !== '/') {
            this.myAxios.get(this.apiEndPoint + 'ping' + Math.random())
                .then(function (response: any) {
                    if (!response || (response.data !== 'OKAY')) {
                        location.href = '/';
                    } else {
                        this.storageService.getCookieData(['dmpuser'], '').toPromise().then((values: any) => {
                            if (values['dmpuser'] === '') {
                                location.href = '/';
                            }
                        });
                    }
                }.bind(this)).catch(function (error: any) {
                    if (error) {
                        console.error(error);
                        location.href = '/';
                    }
                });
            window.setTimeout(this.ping.bind(this), 30000);
        }
    }

    public async getConfig(): Promise<ConfigData> {
        const response = await this.myAxios.get(this.apiEndPoint + 'dat' + Math.random());
        return response.data;
    }

    public async getCustomers(search: string, email: string, name: string, so: string, org: string): Promise<string> {
        const params: CustomerSearchParams = { search: search, email: email, name: name, so: so, org: org };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'customers' + Math.random(), params);
            if (response && response.data) {
                this.dataService.setCustomers(response.data);
                return '';
            } else {
                this.dataService.setCustomers([]);
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async getDevices(email: string, refresh: boolean): Promise<string> {
        if (!email) {
            return '';
        }
        if (!refresh) {
            // clear old devices so properly loads new list
            this.dataService.setDevices([]);
        }
        // only single email for now
        const params: DeviceSearchParams = { emails: email, refresh: refresh };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'devices' + Math.random(), params);
            this.storageService.getCookieData(['dmpuser'], '').toPromise().then(values => {
                if (values['dmpuser'] === '') {
                    location.href = '/';
                }
            });
            if (response && response.data && Array.isArray(response.data)) {
                this.dataService.setDevices(response.data);
                if (response.data.length === 0) {
                    return 'NO_DEVICES';
                }
                return '';
            } else {
                this.dataService.setDevices([]);
            }
        } catch (err) {
            this.dataService.setDevices([]);
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async getJobs(email: string, refresh: boolean): Promise<string> {
        if (!email) {
            return '';
        }
        if (!refresh) {
            // clear old devices so properly loads new list
            this.dataService.setJobs([]);
        }
        // only single email for now
        const params: DeviceSearchParams = { emails: email, refresh: refresh };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'jobs' + Math.random(), params);
            this.storageService.getCookieData(['dmpuser'], '').toPromise().then(values => {
                if (values['dmpuser'] === '') {
                    location.href = '/';
                }
            });
            if (response && response.data && Array.isArray(response.data)) {
                this.dataService.setJobs(response.data);
                if (response.data.length === 0) {
                    return 'NO_JOBS';
                }
                return '';
            } else {
                this.dataService.setJobs([]);
            }
        } catch (err) {
            this.dataService.setJobs([]);
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async getLicenseOrders(email: string): Promise<string> {
        if (!email) {
            return '';
        }
        const params: DeviceSearchParams = { emails: email, refresh: false };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'licenseorders' + Math.random(), params);
            if (response && response.data && Array.isArray(response.data)) {
                this.dataService.setLicenseOrders(response.data);
                if (response.data.length === 0) {
                    return 'NO_LICENSE_ORDERS';
                }
                return '';
            } else {
                this.dataService.setLicenseOrders([]);
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async getLog(type: string, email: string, ids: string, dateFrom: string, dateTo: string): Promise<Array<LogEntry> | string> {
        const params: LogSearchParams = { type: type, email: email, dids: ids, from: dateFrom, to: dateTo };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'log' + Math.random(), params);
            if (response && response.data) {
                return response.data;
            } else {
                return 'EMPTY_LOG';
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
    }

    public async setDeviceRegistration(email: string, deviceIds: Array<string>, state: boolean): Promise<Array<string>> {
        if (deviceIds.length > 0) {
            const okay = (state ? 'MOD_DEV_REG_OKAY' : 'MOD_DEV_UNREG_OKAY');
            const fail = (state ? 'MOD_DEV_REG_FAIL' : 'MOD_DEV_UNREG_FAIL');
            const command =  (state ? 'registerdevices' : 'unregisterdevices');
            const params = { email: email, dids: deviceIds };
            try {
                const response = await this.myAxios.post(this.apiEndPoint + command + Math.random(), params);
                if (response) {
                    // handle display of a json structure:
                    //  { okay: array of ids, fail: array of ids }
                    if (response.data) {
                        const result: Array<string> = [];
                        const devices = this.dataService.getDevices();
                        if (response.data.okay && (response.data.okay.length > 0)) {
                            result.push(okay);
                            response.data.okay.forEach((sn: string) => {
                                const device = devices.find(dev => {
                                    return dev.sn === sn;
                                });
                                if (device) {
                                    result.push('    ' + device.sn + ' / ' + device.name);
                                }
                            });
                        }
                        if (response.data.fail && (response.data.fail.length > 0)) {
                            result.push(fail);
                            response.data.fail.forEach((sn: string) => {
                                const device = devices.find(dev => {
                                    return dev.sn === sn;
                                });
                                if (device) {
                                    result.push('    ' + device.sn + ' / ' + device.name);
                                }
                            });
                        }
                        return result;
                    } else {
                        return [fail];
                    }
                }
            } catch (err) {
                return ['NO_RESPONSE'];
            }
        }
        return ['NO_RESPONSE'];
    }

    public async saveDeviceLicenseChanges(email: string, changes: Array<DeviceChange>): Promise<any> {
        const params = { email: email, chgs: changes };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'save' + Math.random(), params);
            if (response.data) {
                return response.data;
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async applyDeviceLicenses(email: string, deviceIds: Array<string>): Promise<Array<string>> {
        const params = { email: email, dids: deviceIds };
        try {
            const response = await this.myAxios.post(this.apiEndPoint + 'apply' + Math.random(), params);
            if (response) {
                // handle display of a json structure:
                //  { okay: array of ids, fail: array of ids }
                if (response.data) {
                    const result: Array<string> = [];
                    const devices = this.dataService.getDevices();
                    if (response.data.okay && (response.data.okay.length > 0)) {
                        result.push('MOD_APPLY_OKAY');
                        response.data.okay.forEach((sn: string) => {
                            const device = devices.find(dev => {
                                return dev.sn === sn;
                            });
                            if (device) {
                                result.push('    ' + device.sn + ' / ' + device.name);
                            }
                        });
                    }
                    if (response.data.fail && (response.data.fail.length > 0)) {
                        result.push('MOD_APPLY_FAIL');
                        if (!response.data.job.id) {
                            result.push('MOD_APPLY_NOJOB');
                        }
                        response.data.fail.forEach((sn: string) => {
                            const device = devices.find(dev => {
                                return dev.sn === sn;
                            });
                            if (device) {
                                result.push('    ' + device.sn + ' / ' + device.name);
                            }
                        });
                    }
                    return result;
                } else {
                    return ['MOD_APPLY_FAIL'];
                }
            }
        } catch (err) {
            return ['NO_RESPONSE'];
        }
        return ['NO_RESPONSE'];
    }

    public startLiveLogStream(type: string, email: string, from: string, to: string, latest: number, ids: string) {
        this.stopLiveLogStream();
        this.dataService.clearLiveLog();
        this.liveLogEvtSource = new EventSource(this.apiEndPoint + 'liveLog' + Math.random()
                + '?type=' + type + '&email=' + email + '&from=' + from + '&to=' + to
                + '&latest=' + latest + '&dids=' + ids);
        this.liveLogEvtSource.onmessage = this.dataService.updateLiveLog.bind(this.dataService);
    }
    public stopLiveLogStream() {
        if (this.liveLogEvtSource) {
            this.liveLogEvtSource.close();
            this.liveLogEvtSource = null;
        }
    }

    public async getAppLicenses(email: string): Promise<string> {
        if (!email) {
            return '';
        }
        try {
            const params = { email: [email] };
            const response = await this.myAxios.post(this.appLicenseUrl, params, { withCredentials: true });
            if (response && response.data && response.data.payload) {
                const payload = response.data.payload;
                this.dataService.setPendingOrdersFlag(payload.pending_orders === true);
                if (Array.isArray(payload.data)) {
                    const licenses: Array<AppLicense> = [];
                    const licResults: Array<AppLicenseResult> = payload.data;
                    for (const licResult of licResults) {
                        if (licResult.Type === 'License Key') {
                            let org = licResult.organization;
                            if (licResult.organization_email) {
                                org += '\n' + licResult.organization_email;
                            }
                            if (licResult.end_user_email) {
                                org += '\n' + licResult.end_user_email;
                            }
                            let qty = licResult.max_activations;
                            if (licResult.third_party === '0') {
                                qty += ' (Used: ' + licResult.activations_used + ')';
                            }
                            licenses.push({
                                id: licResult.InternalID,
                                name: licResult.LimeLM_Product_ID,
                                key: licResult.licensekey,
                                qty: qty,
                                exp: licResult.expiry_date,
                                so: 'SO' + licResult['max(so.sales_order_number)'],
                                po: licResult.purchase_order_number,
                                org: org,
                            });
                        }
                    }
                    this.dataService.setAppLicenses(licenses);
                    if (licenses.length === 0) {
                        return 'NO_ALICENSES';
                    }
                }
                return '';
            } else {
                this.dataService.setAppLicenses([]);
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async needEulaAcceptance(user: string): Promise<boolean> {
        // only check if need eula acceptance if logged name in same as customer
        const customer = this.dataService.getSelectedCustomer();
        if (customer && customer.name && (user === customer.email)) {
            try {
                const response = await this.myAxios.get(this.eulaStatusUrl, { withCredentials: true });
                // {status: "success", msg: "", payload: {status: "unset", message: "Pending EULA acceptance"}}
                if (response && response.data && (response.data.status === 'success')) {
                    if (response.data.payload && (typeof response.data.payload.message === 'string')
                            && (response.data.payload.message.substr(0, 7) === 'Pending')) {
                        return true;
                    }
                }
            } catch (err) {
                return false;
            }
        }
        return false;
    }

    public async getEulaText(): Promise<Array<string>> {
        try {
            const response = await this.myAxios.get(this.eulaTextUrl, { withCredentials: true });
            if (response && (response.status === 200) && Array.isArray(response.data)
                    && (response.data.length > 0)) {
                const texts = [];
                for (const data of response.data) {
                    if (data.content) {
                        texts.push(data.content.replace(/\n/g, ''));
                    }
                }
                return texts;
            }
        } catch (err) {
            return [];
        }
        return [];
    }

    public async acceptEula(): Promise<boolean> {
        try {
            const response = await this.myAxios.get(this.eulaAcceptUrl, { withCredentials: true });
            if (response && response.data && (response.data.status === 'success')) {
                return true;
            }
        } catch (err) {
            return false;
        }
        return false;
    }

    public async getPendingOrders(email: string): Promise<Array<PendingOrder> | string> {
        try {
            const params = { email: email };
            const response = await this.myAxios.post(this.pendingOrdersUrl, params, { withCredentials: true });
            if (response && (response.status === 200)) {
                if (response.data && (response.data.pending_orders !== false) && Array.isArray(response.data)) {
                    return response.data;
                }
                return 'NO_PORDERS';
            }
        } catch (err) {
            return 'NO_RESPONSE';
        }
        return 'NO_RESPONSE';
    }

    public async getViewers(email: string): Promise<Array<LicenseViewer>> {
        try {
            // const params = { email: email };
            const response = await this.myAxios.get(this.viewersUrl, { withCredentials: true });
            if (response && (response.status === 200) && response.data
                    && Array.isArray(response.data.members)) {
                return response.data.members;
            }
        } catch (err) {
            return [];
        }
        return [];
    }

    public async addViewer(email: string): Promise<LicenseViewerResponse> {
        try {
            const params = { email: email };
            const response = await this.myAxios.post(this.viewersUrl, params, { withCredentials: true });
            if (response && (response.status === 200) && response.data) {
                return response.data;
            }
        } catch (err) {
            return { title: '', msg: '', email: false, added: false };
        }
        return { title: '', msg: '', email: false, added: false };
    }

    public async deleteViewer(user_id: string): Promise<LicenseViewerResponse> {
        try {
            const params = { user_id: user_id };
            const response = await this.myAxios.delete(this.viewersUrl, { params: params, withCredentials: true });
            if (response && (response.status === 200) && response.data) {
                return response.data;
            }
        } catch (err) {
            return { title: '', msg: '', email: false, added: false };
        }
        return { title: '', msg: '', email: false, added: false };
    }

}
