import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { faTimes, faFileCsv, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import html2pdf from 'html2pdf.js';

import * as Utils from '../utils';

interface MessageLines {
    text: Array<string>;
    show: boolean;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MessageDialogComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription>;

    public title: string;
    public confirm: boolean;
    public requireEnd: boolean;
    public messageLines: Array<MessageLines>;
    public filterForm: FormGroup;
    public showFilter: boolean;
    public labelYes: string;
    public labelNo: string;
    public atEnd: boolean;

    // instantiate icon references
    public faTimes = faTimes;
    public faFileCsv = faFileCsv;
    public faFilePdf = faFilePdf;

    constructor(
            private formBuilder: FormBuilder,
            private dialogRef: MatDialogRef<MessageDialogComponent>,
            @Inject(MAT_DIALOG_DATA) data: any) {

        this.subscriptions = [];
        this.filterForm = this.formBuilder.group({
            filter: ['']
        });

        this.title = data.title;
        this.confirm = !!data.confirm;
        this.requireEnd = !!data.requireEnd;
        this.showFilter = !!data.filter;
        this.labelYes = ((data.labelYes !== '') ? data.labelYes : (this.confirm ? 'YES' : 'OKAY'));
        this.labelNo = ((data.labelNo !== '') ? data.labelNo : 'NO');
        this.atEnd = false;

        this.messageLines = [];
        if (!!data.split) {
            for (const line of data.message) {
                const lparts = line.split(',');
                this.messageLines.push({text: lparts, show: true});
            }
        } else {
            for (const line of data.message) {
                this.messageLines.push({text: [line], show: true});
            }
        }
    }

    public ngOnInit() {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.filterForm.get('filter').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    this.applyFilter(value);
            }));
            this.subscriptions.push(this.dialogRef.keydownEvents().subscribe(event => {
                if (event.key.toUpperCase() === 'ESCAPE') {
                    event.preventDefault();
                    this.close();
                }
            }));
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    private applyFilter(filter: string) {
        const lFilter = filter.toLocaleLowerCase();
        for (const messageLine of this.messageLines) {
            if (!filter) {
                messageLine.show = true;
            } else {
                let show = false;
                for (const part of messageLine.text) {
                    if (part.toLocaleLowerCase().indexOf(lFilter) >= 0) {
                        show = true;
                        break;
                    }
                }
                messageLine.show = show;
            }
        }
    }

    public scrolledBody(event: any) {
        const targ = event.target;
        if (targ) {
            this.atEnd = (targ.scrollTop + targ.offsetHeight >= targ.scrollHeight);
        }
    }

    public exportPDF() {
        const opts = {
            margin: 1,
            filename: this.title + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        const list: HTMLElement = document.getElementById('md_list');
        html2pdf().from(list, 'element').set(opts).save();
    }

    public exportCSV() {
        const list: HTMLElement = document.getElementById('md_list');
        const csv: Array<string> = [];
        const rows = list.querySelectorAll('tr');
        rows.forEach(row => {
            const line: Array<string> = [];
            const cols = row.querySelectorAll('td');
            cols.forEach(col => {
                line.push(col.innerText.replace(/,/g, ''));
            });
            csv.push(line.join(','));
        });
        const content = 'data:text/csv;base64,' + btoa(unescape(encodeURIComponent(csv.join('\n'))));
        Utils.downloadFromDataURL(this.title + '.csv', content);
    }

    public close(result: string = '') {
        this.dialogRef.close(result);
    }

}

export interface MessageDialogOptions {
    confirm?: boolean;
    requireEnd?: boolean;
    filter?: boolean;
    split?: boolean;
    labelYes?: string;
    labelNo?: string;
}

export class MessageDialog {
    private dialogConfig: MatDialogConfig;

    public confirmation = new Subject<boolean>();

    constructor() {
        this.dialogConfig = new MatDialogConfig();
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;
        this.dialogConfig.hasBackdrop = true;
        this.dialogConfig.closeOnNavigation = false;
        this.dialogConfig.backdropClass = 'cdk-overlay-connected-position-bounding-box';
        this.dialogConfig.panelClass = 'md-dialog';
        this.dialogConfig.maxHeight = '80vh';
        this.dialogConfig.minHeight = '300px';
        this.dialogConfig.maxWidth = '80vw';
        this.dialogConfig.minWidth = '400px';
    }

    public open(matDialog: MatDialog, title: string, lines: Array<string>, options: MessageDialogOptions = {}) {
        this.dialogConfig.disableClose = true;
        this.dialogConfig.data = {
            title: title,
            message: lines,
            confirm: (options.confirm === true) ? true : false,
            requireEnd: (options.requireEnd === true) ? true : false,
            filter: (options.filter === true) ? true : false,
            split: (options.split === true) ? true : false,
            labelYes: (typeof options.labelYes === 'string') ? options.labelYes : '',
            labelNo: (typeof options.labelNo === 'string') ? options.labelNo : '',
        };
        const dialog = matDialog.open(MessageDialogComponent, this.dialogConfig);
        if (confirm) {
            dialog.afterClosed().subscribe(result => {
                this.confirmation.next(result === 'yes');
            });
        }
    }

}
