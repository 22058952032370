import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import axios from 'axios';
import { DataService } from './data.service';
import { StorageService } from './storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./storage.service";
export class ApiService {
    constructor(dataService, storageService) {
        this.dataService = dataService;
        this.storageService = storageService;
        this.apiEndPoint = '/api/v1/';
        this.eulaStatusUrl = 'https://api.zspace.com/eula/status/1';
        this.eulaTextUrl = 'https://api.zspace.com/legal/view/end-user-license-agreements';
        this.eulaAcceptUrl = 'https://api.zspace.com/eula/accept/1';
        this.appLicenseUrl = 'https://api.zspace.com/ns/search';
        this.viewersUrl = 'https://api.zspace.com/ns/viewers';
        this.pendingOrdersUrl = 'https://api.zspace.com/ns/pending';
        this.subscriptions = [];
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.myAxios = axios.create({
            timeout: 60000,
        });
        this.setupSubscriptions();
        window.setTimeout(this.ping.bind(this), 4000);
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
    setupSubscriptions() {
    }
    pleaseWait(duration, msg = '') {
        if (this.waitTimeout !== 0) {
            window.clearTimeout(this.waitTimeout);
            this.waitTimeout = 0;
        }
        const ele = document.querySelector('#wait-overlay');
        if (ele) {
            if (duration === 0) {
                ele.className = 'hidden';
            }
            else {
                ele.className = '';
                this.waitTimeout = window.setTimeout(function () {
                    ele.className = 'hidden';
                    this.waitTimeout = 0;
                }.bind(this), duration * 1000);
            }
            const msgElement = ele.querySelector('#wait-msg');
            if (msgElement) {
                msgElement.innerHTML = msg;
            }
        }
    }
    ping() {
        if (location.pathname !== '/') {
            this.myAxios.get(this.apiEndPoint + 'ping' + Math.random())
                .then(function (response) {
                if (!response || (response.data !== 'OKAY')) {
                    location.href = '/';
                }
                else {
                    this.storageService.getCookieData(['dmpuser'], '').toPromise().then((values) => {
                        if (values['dmpuser'] === '') {
                            location.href = '/';
                        }
                    });
                }
            }.bind(this)).catch(function (error) {
                if (error) {
                    console.error(error);
                    location.href = '/';
                }
            });
            window.setTimeout(this.ping.bind(this), 30000);
        }
    }
    getConfig() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.myAxios.get(this.apiEndPoint + 'dat' + Math.random());
            return response.data;
        });
    }
    getCustomers(search, email, name, so, org) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = { search: search, email: email, name: name, so: so, org: org };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'customers' + Math.random(), params);
                if (response && response.data) {
                    this.dataService.setCustomers(response.data);
                    return '';
                }
                else {
                    this.dataService.setCustomers([]);
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    getDevices(email, refresh) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!email) {
                return '';
            }
            if (!refresh) {
                // clear old devices so properly loads new list
                this.dataService.setDevices([]);
            }
            // only single email for now
            const params = { emails: email, refresh: refresh };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'devices' + Math.random(), params);
                this.storageService.getCookieData(['dmpuser'], '').toPromise().then(values => {
                    if (values['dmpuser'] === '') {
                        location.href = '/';
                    }
                });
                if (response && response.data && Array.isArray(response.data)) {
                    this.dataService.setDevices(response.data);
                    if (response.data.length === 0) {
                        return 'NO_DEVICES';
                    }
                    return '';
                }
                else {
                    this.dataService.setDevices([]);
                }
            }
            catch (err) {
                this.dataService.setDevices([]);
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    getJobs(email, refresh) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!email) {
                return '';
            }
            if (!refresh) {
                // clear old devices so properly loads new list
                this.dataService.setJobs([]);
            }
            // only single email for now
            const params = { emails: email, refresh: refresh };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'jobs' + Math.random(), params);
                this.storageService.getCookieData(['dmpuser'], '').toPromise().then(values => {
                    if (values['dmpuser'] === '') {
                        location.href = '/';
                    }
                });
                if (response && response.data && Array.isArray(response.data)) {
                    this.dataService.setJobs(response.data);
                    if (response.data.length === 0) {
                        return 'NO_JOBS';
                    }
                    return '';
                }
                else {
                    this.dataService.setJobs([]);
                }
            }
            catch (err) {
                this.dataService.setJobs([]);
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    getLicenseOrders(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!email) {
                return '';
            }
            const params = { emails: email, refresh: false };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'licenseorders' + Math.random(), params);
                if (response && response.data && Array.isArray(response.data)) {
                    this.dataService.setLicenseOrders(response.data);
                    if (response.data.length === 0) {
                        return 'NO_LICENSE_ORDERS';
                    }
                    return '';
                }
                else {
                    this.dataService.setLicenseOrders([]);
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    getLog(type, email, ids, dateFrom, dateTo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = { type: type, email: email, dids: ids, from: dateFrom, to: dateTo };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'log' + Math.random(), params);
                if (response && response.data) {
                    return response.data;
                }
                else {
                    return 'EMPTY_LOG';
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
        });
    }
    setDeviceRegistration(email, deviceIds, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (deviceIds.length > 0) {
                const okay = (state ? 'MOD_DEV_REG_OKAY' : 'MOD_DEV_UNREG_OKAY');
                const fail = (state ? 'MOD_DEV_REG_FAIL' : 'MOD_DEV_UNREG_FAIL');
                const command = (state ? 'registerdevices' : 'unregisterdevices');
                const params = { email: email, dids: deviceIds };
                try {
                    const response = yield this.myAxios.post(this.apiEndPoint + command + Math.random(), params);
                    if (response) {
                        // handle display of a json structure:
                        //  { okay: array of ids, fail: array of ids }
                        if (response.data) {
                            const result = [];
                            const devices = this.dataService.getDevices();
                            if (response.data.okay && (response.data.okay.length > 0)) {
                                result.push(okay);
                                response.data.okay.forEach((sn) => {
                                    const device = devices.find(dev => {
                                        return dev.sn === sn;
                                    });
                                    if (device) {
                                        result.push('    ' + device.sn + ' / ' + device.name);
                                    }
                                });
                            }
                            if (response.data.fail && (response.data.fail.length > 0)) {
                                result.push(fail);
                                response.data.fail.forEach((sn) => {
                                    const device = devices.find(dev => {
                                        return dev.sn === sn;
                                    });
                                    if (device) {
                                        result.push('    ' + device.sn + ' / ' + device.name);
                                    }
                                });
                            }
                            return result;
                        }
                        else {
                            return [fail];
                        }
                    }
                }
                catch (err) {
                    return ['NO_RESPONSE'];
                }
            }
            return ['NO_RESPONSE'];
        });
    }
    saveDeviceLicenseChanges(email, changes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = { email: email, chgs: changes };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'save' + Math.random(), params);
                if (response.data) {
                    return response.data;
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    applyDeviceLicenses(email, deviceIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = { email: email, dids: deviceIds };
            try {
                const response = yield this.myAxios.post(this.apiEndPoint + 'apply' + Math.random(), params);
                if (response) {
                    // handle display of a json structure:
                    //  { okay: array of ids, fail: array of ids }
                    if (response.data) {
                        const result = [];
                        const devices = this.dataService.getDevices();
                        if (response.data.okay && (response.data.okay.length > 0)) {
                            result.push('MOD_APPLY_OKAY');
                            response.data.okay.forEach((sn) => {
                                const device = devices.find(dev => {
                                    return dev.sn === sn;
                                });
                                if (device) {
                                    result.push('    ' + device.sn + ' / ' + device.name);
                                }
                            });
                        }
                        if (response.data.fail && (response.data.fail.length > 0)) {
                            result.push('MOD_APPLY_FAIL');
                            if (!response.data.job.id) {
                                result.push('MOD_APPLY_NOJOB');
                            }
                            response.data.fail.forEach((sn) => {
                                const device = devices.find(dev => {
                                    return dev.sn === sn;
                                });
                                if (device) {
                                    result.push('    ' + device.sn + ' / ' + device.name);
                                }
                            });
                        }
                        return result;
                    }
                    else {
                        return ['MOD_APPLY_FAIL'];
                    }
                }
            }
            catch (err) {
                return ['NO_RESPONSE'];
            }
            return ['NO_RESPONSE'];
        });
    }
    startLiveLogStream(type, email, from, to, latest, ids) {
        this.stopLiveLogStream();
        this.dataService.clearLiveLog();
        this.liveLogEvtSource = new EventSource(this.apiEndPoint + 'liveLog' + Math.random()
            + '?type=' + type + '&email=' + email + '&from=' + from + '&to=' + to
            + '&latest=' + latest + '&dids=' + ids);
        this.liveLogEvtSource.onmessage = this.dataService.updateLiveLog.bind(this.dataService);
    }
    stopLiveLogStream() {
        if (this.liveLogEvtSource) {
            this.liveLogEvtSource.close();
            this.liveLogEvtSource = null;
        }
    }
    getAppLicenses(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!email) {
                return '';
            }
            try {
                const params = { email: [email] };
                const response = yield this.myAxios.post(this.appLicenseUrl, params, { withCredentials: true });
                if (response && response.data && response.data.payload) {
                    const payload = response.data.payload;
                    this.dataService.setPendingOrdersFlag(payload.pending_orders === true);
                    if (Array.isArray(payload.data)) {
                        const licenses = [];
                        const licResults = payload.data;
                        for (const licResult of licResults) {
                            if (licResult.Type === 'License Key') {
                                let org = licResult.organization;
                                if (licResult.organization_email) {
                                    org += '\n' + licResult.organization_email;
                                }
                                if (licResult.end_user_email) {
                                    org += '\n' + licResult.end_user_email;
                                }
                                let qty = licResult.max_activations;
                                if (licResult.third_party === '0') {
                                    qty += ' (Used: ' + licResult.activations_used + ')';
                                }
                                licenses.push({
                                    id: licResult.InternalID,
                                    name: licResult.LimeLM_Product_ID,
                                    key: licResult.licensekey,
                                    qty: qty,
                                    exp: licResult.expiry_date,
                                    so: 'SO' + licResult['max(so.sales_order_number)'],
                                    po: licResult.purchase_order_number,
                                    org: org,
                                });
                            }
                        }
                        this.dataService.setAppLicenses(licenses);
                        if (licenses.length === 0) {
                            return 'NO_ALICENSES';
                        }
                    }
                    return '';
                }
                else {
                    this.dataService.setAppLicenses([]);
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    needEulaAcceptance(user) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // only check if need eula acceptance if logged name in same as customer
            const customer = this.dataService.getSelectedCustomer();
            if (customer && customer.name && (user === customer.email)) {
                try {
                    const response = yield this.myAxios.get(this.eulaStatusUrl, { withCredentials: true });
                    // {status: "success", msg: "", payload: {status: "unset", message: "Pending EULA acceptance"}}
                    if (response && response.data && (response.data.status === 'success')) {
                        if (response.data.payload && (typeof response.data.payload.message === 'string')
                            && (response.data.payload.message.substr(0, 7) === 'Pending')) {
                            return true;
                        }
                    }
                }
                catch (err) {
                    return false;
                }
            }
            return false;
        });
    }
    getEulaText() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.myAxios.get(this.eulaTextUrl, { withCredentials: true });
                if (response && (response.status === 200) && Array.isArray(response.data)
                    && (response.data.length > 0)) {
                    const texts = [];
                    for (const data of response.data) {
                        if (data.content) {
                            texts.push(data.content.replace(/\n/g, ''));
                        }
                    }
                    return texts;
                }
            }
            catch (err) {
                return [];
            }
            return [];
        });
    }
    acceptEula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.myAxios.get(this.eulaAcceptUrl, { withCredentials: true });
                if (response && response.data && (response.data.status === 'success')) {
                    return true;
                }
            }
            catch (err) {
                return false;
            }
            return false;
        });
    }
    getPendingOrders(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const params = { email: email };
                const response = yield this.myAxios.post(this.pendingOrdersUrl, params, { withCredentials: true });
                if (response && (response.status === 200)) {
                    if (response.data && (response.data.pending_orders !== false) && Array.isArray(response.data)) {
                        return response.data;
                    }
                    return 'NO_PORDERS';
                }
            }
            catch (err) {
                return 'NO_RESPONSE';
            }
            return 'NO_RESPONSE';
        });
    }
    getViewers(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // const params = { email: email };
                const response = yield this.myAxios.get(this.viewersUrl, { withCredentials: true });
                if (response && (response.status === 200) && response.data
                    && Array.isArray(response.data.members)) {
                    return response.data.members;
                }
            }
            catch (err) {
                return [];
            }
            return [];
        });
    }
    addViewer(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const params = { email: email };
                const response = yield this.myAxios.post(this.viewersUrl, params, { withCredentials: true });
                if (response && (response.status === 200) && response.data) {
                    return response.data;
                }
            }
            catch (err) {
                return { title: '', msg: '', email: false, added: false };
            }
            return { title: '', msg: '', email: false, added: false };
        });
    }
    deleteViewer(user_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const params = { user_id: user_id };
                const response = yield this.myAxios.delete(this.viewersUrl, { params: params, withCredentials: true });
                if (response && (response.status === 200) && response.data) {
                    return response.data;
                }
            }
            catch (err) {
                return { title: '', msg: '', email: false, added: false };
            }
            return { title: '', msg: '', email: false, added: false };
        });
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.StorageService)); }, token: ApiService, providedIn: "root" });
