import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { cloneDeep } from 'lodash';
import { Customer, Device, LicenseOrder, LogEntry, Job} from '../../../server/src/web-front-end/api-interfaces';
import { StorageService } from './storage.service';
import { AppLicense } from './models/app-license-list';

@Injectable({
    providedIn: 'root'
})
export class DataService implements OnDestroy {
    private selectedCustomer = new BehaviorSubject<Customer>(null);
    private customers = new BehaviorSubject<Array<Customer>>([]);
    private liveLog = new BehaviorSubject<Array<LogEntry>>([]);
    private actionValue = new BehaviorSubject<string>('');
    private devices = new BehaviorSubject<Array<Device>>([]);
    private licenseOrders = new BehaviorSubject<Array<LicenseOrder>>([]);
    private originalDevices: Array<Device>; // used to detect which changes to send to back end
    private jobs = new BehaviorSubject<Array<Job>>([]);
    private appLicenses = new BehaviorSubject<Array<AppLicense>>([]);
    private pendingOrdersFlag = new BehaviorSubject<boolean>(false);

    constructor(
                private storageService: StorageService,
            ) {
    }

    public ngOnDestroy() {
    }

    public clearLiveLog() {
        this.liveLog.next([]);
    }
    public updateLiveLog(ev: MessageEvent): any {
        if (ev && ev.data) {
            try {
                const pdata: Array<LogEntry> = JSON.parse(ev.data);
                if (Array.isArray(pdata)) {
                    this.liveLog.next(pdata);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }
    public getLiveLogObservable(): Observable<Array<LogEntry>> {
        return this.liveLog.asObservable();
    }

    public updateSelectedCustomer(customer: Customer) {
        this.storageService.setSessionData({ selectedCustomer: customer });
        this.selectedCustomer.next(customer);
    }
    public getSelectedCustomerObservable(): Observable<Customer> {
        return this.selectedCustomer.asObservable();
    }
    public getSelectedCustomer(): Customer {
        return this.selectedCustomer.value;
    }

    public setCustomers(customers: Array<Customer>) {
        if (Array.isArray(customers)) {
            for (const customer of customers) {
                customer.org = customer.org.replace(/&amp;/g, '&').replace(/&apos;/g, '\'');
            }
            this.customers.next(customers);
        }
    }
    public getCustomersObservable(): Observable<Array<Customer>> {
        return this.customers.asObservable();
    }
    public getCustomers(): Array<Customer> {
        return this.customers.getValue();
    }

    public setDevices(devices: Array<Device>) {
        // do deep copy of devices
        this.originalDevices = [];
        devices.forEach(dev => {
            this.originalDevices.push(cloneDeep(dev)); // make sure it's a copy
        });
        this.devices.next(devices);
    }
    public getDevicesObservable(): Observable<Array<Device>> {
        return this.devices.asObservable();
    }
    public getDevices(): Array<Device> {
        return this.devices.getValue();
    }
    public getOriginalDevices(): Array<Device> {
        return this.originalDevices;
    }

    public setLicenseOrders(orders: Array<LicenseOrder>) {
        for (const order of orders) {
            for (const lic of order.lics) {
                lic.prod = lic.prod.replace(/&amp;/g, '&').replace(/&apos;/g, '\'');
            }
        }
        this.licenseOrders.next(orders);
    }
    public getLicenseOrdersObservable(): Observable<Array<LicenseOrder>> {
        return this.licenseOrders.asObservable();
    }
    public getLicenseOrders(): Array<LicenseOrder> {
        return this.licenseOrders.getValue();
    }

    public setAppLicenses(orders: Array<AppLicense>) {
        this.appLicenses.next(orders);
    }
    public getAppLicensesObservable(): Observable<Array<AppLicense>> {
        return this.appLicenses.asObservable();
    }
    public getAppLicenses(): Array<AppLicense> {
        return this.appLicenses.getValue();
    }

    public setPendingOrdersFlag(flag: boolean) {
        this.pendingOrdersFlag.next(flag);
    }
    public getPendingOrdersFlagObservable(): Observable<boolean> {
        return this.pendingOrdersFlag.asObservable();
    }
    public getPendingOrdersFlag(): boolean {
        return this.pendingOrdersFlag.getValue();
    }

    public setAction(action: string) {
        this.actionValue.next(action);
    }
    public getActionObservable(): Observable<string> {
        return this.actionValue.asObservable();
    }

    public setJobs(jobs: Array<Job>) {
        this.jobs.next(jobs);
    }
    public getJobsObservable(): Observable<Array<Job>> {
        return this.jobs.asObservable();
    }
    public getJobs(): Array<Job> {
        return this.jobs.getValue();
    }

}
