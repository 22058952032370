<div class='app-container' (pinch)='pinch($event)' (pinchend)='pinch($event)'>
    <header id='header_1' class='header'>
        <div class='logo-container'>
            <a href="{{ 'ZSPACE_URL' | regionstring }}" rel='noopener' target='_blank'><img [src]='logoImage' /></a>
        </div>
        <div class='nav-main'>
            {{ 'PRODUCT' | translate }}<br>
            <span class='version'>{{ 'VERSION' | translate }} {{ versionInfo.packageVersion }}</span>
        </div>
        <div class='nav-right-column'>
            <div class='nav-right-sub-column'>
                <div class='address'>{{ 'DEV_IP' | translate }}: {{ ipAddress }}</div>
                <div class='email-above'>{{ userNameFull | translate }}</div>
                <div *ngIf='false' class='search-language'>
                    <div class='drop-down-header pointer' (click)='toggleLanguageDropDown($event)'>
                        <span>{{ selectedLanguage.title }}</span>
                        <fa-icon [icon]='faCaretDown' class='drop-arrow-blue'></fa-icon>
                    </div>
                    <div class='drop-down-bg' [class.expand]='languagesVisible'>
                        <ul class='drop-down'>
                            <li *ngFor='let language of availableLanguages' (click)='selectLanguage(language)' class='pointer'>
                                {{ language.title }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class='icons-container'>
                <a *ngIf='userNameFull' (click)='logout()'><span>{{ 'LOGOUT' | translate }} <fa-icon [icon]='faSignOut'></fa-icon></span></a>
            </div>
        </div>
    </header>
    <router-outlet></router-outlet>
</div>
<div id="wait-overlay">
    <div class='svg-container'>
        <fa-icon [icon]="faHourglassHalf"></fa-icon>
    </div>
    <div class='title'><p>{{ 'PWAIT' | translate }}</p></div>
    <div class='title'><p id='wait-msg'></p></div>
</div>
<div *ngIf="showViewEula" class="ack-eula">
    <div [innerHTML]="'EULA_PROMPT' | translate"></div>
    <span (click)='viewEula($event)'>{{ 'VIEW_EULA' | translate }}</span>
</div>

