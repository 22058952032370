import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import zSpaceLogo from '../../assets/images/zSpaceLogo_110x32.png';
import { AuthenticationService } from './authentication.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthenticationComponent implements OnInit, OnDestroy {
    @Output() closeDialog = new EventEmitter<boolean>();

    private subscriptions: Array<Subscription> = [];
    private listeners: Array<{action: string, function: any}> = [];

    public faTimes = faTimes;
    public logoImage: SafeUrl;
    public wait: boolean;
    public allowMS: boolean;
    public showReset: boolean;
    public showFinishReset: boolean;
    public showSocialLogins: boolean;
    public errorMessage: string;
    public enableForgotten: boolean;

    constructor(
            private sanitizer: DomSanitizer,
            private authenticationService: AuthenticationService,
        ) {
        this.allowMS = false;
        this.showReset = false;
        this.showFinishReset = false;
        this.errorMessage = '';
        this.showSocialLogins = false; // disable social logins for now
        this.enableForgotten = false; // disable forgotten password for now
    }

    ngOnInit() {
        this.logoImage = this.sanitizer.bypassSecurityTrustUrl(atob(zSpaceLogo.split(',').pop()).match(/"(.*?)"/)[1]);
        this.wait = false;
        this.updateError('');
        this.setupSubscriptions();
        this.addListeners();
        this.authenticationService.clearError();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.removeListeners();
        this.authenticationService.clearError();
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.authenticationService.getErrorSubscription().subscribe(error => {
                this.updateError(error);
                if (error) {
                    this.wait = false;
                }
            }));
            this.subscriptions.push(this.authenticationService.getResetStatusSubscription().subscribe(status => {
                this.wait = false;
                switch (status) {
                    case 2:
                        // successful password change - goto login
                        this.updateError('');
                        this.showReset = false;
                        this.showFinishReset = false;
                        // this.showSocialLogins = true; // disabled for now
                        break;
                    case 1:
                        // possible error changing password, show enter new password
                        this.showReset = true;
                        this.showSocialLogins = false;
                        this.showFinishReset = true;
                        break;
                    case 0:
                    default:
                        // do nothing
                        break;
                }
            }));
        }
    }

    private addListeners() {
        if (this.listeners.length === 0) {
            this.listeners.push({action: 'keydown', function: this.handleKey});
            window.addEventListener('keydown', this.handleKey.bind(this));
        }
    }
    private removeListeners() {
        this.listeners.forEach(listener => {
            window.removeEventListener(listener.action, listener.function.bind(this));
        });
    }

    private updateError(err: string) {
        this.errorMessage = err;
    }

    public close() {
        this.authenticationService.clearError();
        this.closeDialog.emit(true);
        this.wait = false;
        this.showReset = false;
        this.showFinishReset = false;
    }

    public handleKey(event: any) {
        if (event.key) {
            const key = event.key.toUpperCase();
            switch (key) {
                case 'ESCAPE':
                    event.preventDefault();
                    event.stopPropagation();
                    this.close();
                    break;
                case 'ENTER':
                    event.preventDefault();
                    event.stopPropagation();
                    this.submitLogin(event);
                    break;
            }
        }
    }

    public oauth2Login(provider: string) {
        // this.authenticationService.oauth2Login(provider);
    }

    public submitLogin(event: any) {
        // ignore if already waiting for login response
        if (!this.wait) {
            this.wait = true;
            const eele: HTMLInputElement = document.getElementById('auth_email') as HTMLInputElement;
            const email = (eele ? eele.value : '');
            const pele: HTMLInputElement = document.getElementById('auth_password') as HTMLInputElement;
            const password = (pele ? pele.value : '');

            this.authenticationService.login(email, password);
        }
    }

}
