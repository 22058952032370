import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { StorageService } from '../storage.service';
import { DataService } from '../data.service';
import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "../storage.service";
import * as i2 from "../data.service";
import * as i3 from "../api.service";
export class AuthenticationService {
    constructor(storageService, dataService, apiService) {
        this.storageService = storageService;
        this.dataService = dataService;
        this.apiService = apiService;
        this.loginError = new BehaviorSubject('');
        this.resetStatus = new BehaviorSubject(0);
        this.cognitoLogin = new BehaviorSubject({ name: '', id: '', cc: 0, vc: 0 });
        this.updateLogin({ name: '', id: '', cc: 0, vc: 0 });
        this.errorChanged('');
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.myAxios = axios.create({
            timeout: 60000,
        });
    }
    clearError() {
        this.errorChanged('');
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true });
            return this.myAxios.post('/logout', { withCredentials: true });
        });
    }
    validateEmail(email) {
        return /.+@.+\..+/.test(email);
    }
    login(email, password) {
        this.errorChanged('');
        if (!this.validateEmail(email)) {
            this.errorChanged('ENTER_EMAIL');
            return false;
        }
        else if (!password) {
            this.errorChanged('ENTER_PASSWORD');
            return false;
        }
        const authenticationData = {
            un: email,
            pw: password,
        };
        this.apiService.pleaseWait(30);
        this.storageService.clearSessionData();
        this.dataService.updateSelectedCustomer(null);
        this.myAxios.post('https://api.zspace.com/cognito/login', authenticationData, { withCredentials: true })
            .then(function (response) {
            if (response && response.data && (response.data.status === 'success')
                && response.data.payload && response.data.payload.cookie_hash) {
                this.storageService.setCookieData({ zs: response.data.payload.cookie_hash });
                this.getUserInfo(true);
            }
            else {
                this.apiService.pleaseWait(0);
                this.errorChanged('INVALID_CREDENTIALS');
            }
        }.bind(this)).catch(function (error) {
            this.apiService.pleaseWait(0);
            console.error(error);
            this.errorChanged('HTTP_TIMEOUT');
        }.bind(this));
        return true;
    }
    getUserInfo(setInsufficient) {
        this.errorChanged('');
        this.apiService.pleaseWait(10);
        this.myAxios.post('/login', { withCredentials: true }).then(resp => {
            if (resp && (resp.status === 200) && resp.data && resp.data.res && (resp.data.res === 'success')) {
                this.updateLogin(resp.data);
            }
            else {
                if (setInsufficient) {
                    this.errorChanged('NOT_AUTHORIZED');
                }
                // if unsufficient, then logout of zspace.com
                document.cookie = 'dmpuser=';
                document.cookie = 'dmpid=';
                this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true }).then(lo1resp => {
                    this.apiService.pleaseWait(0);
                }).catch(locatch => {
                    this.apiService.pleaseWait(0);
                });
            }
        }).catch(error => {
            // if error, then logout of zspace.com
            document.cookie = 'dmpuser=';
            document.cookie = 'dmpid=';
            this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true }).then(lo1resp => {
                this.apiService.pleaseWait(0);
            }).catch(locatch => {
                this.apiService.pleaseWait(0);
            });
        });
    }
    updateLogin(login) {
        this.cognitoLogin.next(login);
    }
    getLoginObservable() {
        return this.cognitoLogin.asObservable();
    }
    getLogin() {
        return this.cognitoLogin.getValue();
    }
    getUserEmail() {
        return this.getLogin().name;
    }
    errorChanged(error) {
        this.loginError.next(error);
    }
    getErrorSubscription() {
        return this.loginError.asObservable();
    }
    resetStatusChanged(status) {
        this.resetStatus.next(status);
    }
    getResetStatusSubscription() {
        return this.resetStatus.asObservable();
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.ApiService)); }, token: AuthenticationService, providedIn: "root" });
