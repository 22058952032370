import { Language } from './language';

export class Constants {

    static readonly languages: Array<Language> = [
        { title: 'English', languageCode: 'en-US', subtag: 'en' },
        // { title: '中文 (Chinese)', languageCode: 'zh-CN', subtag: 'zhx' },
        // { title: 'Español (Spanish)', languageCode: 'es-US', subtag: 'es' }
    ];

    static readonly jobStatusList = [
        'new',
        'blocked',
        'ready',
        'in-progress',
        'complete',
        'finalized',
        'ready-to-delete',
    ];

    // LogHeader arrays should all be same length
    static readonly deviceLogHeader = [
        'Date',
        'Event',
        'Device',
        'Product',
        'User',
        'From',
        'Device ip',
        'Code', ''
    ];
    static readonly jobLogHeader = [
        'Date',
        'Event',
        'Devices',
        'Job Type',
        'User',
        'Completion Status',
        'Blocked by Job',
        '',
    ];

}

export const versionInfo = (() => {
    try {
        // tslint:disable-next-line:no-var-requires
        return require('../../../version-info.json');
    } catch {
        // In dev the file might not exist:
        return { tag: 'v0.0.0', hash: 'dev' };
    }
})();

