import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes, CanDeactivate } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { TranslateModule, TranslateService, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import * as Hammer from 'hammerjs';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { AppComponent } from './app.component';
import { ImageService } from './image.service';
import { LanguageService, WebpackTranslateLoader, MyMissingTranslationHandler } from './language.service';
import { RegionService, RegionStringPipe } from './region.service';
import { StorageService } from './storage.service';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthenticationService } from './authentication/authentication.service';
import { CustomersComponent } from './customers/customers.component';
import { DataService } from './data.service';
import { DevicesComponent } from './devices/devices.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { LogViewComponent } from './log-view/log-view.component';
import { ApiService } from './api.service';
import { JobsComponent } from './jobs/jobs.component';
import { NavComponent } from './nav/nav.component';
import { LicensesComponent } from './licenses/licenses.component';

polyfill({
    // use this to make use of the scroll behaviour
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});
// workaround to make scroll prevent work in iOS Safari > 10
try {
    window.addEventListener('touchmove', function () { }, { passive: false });
} catch (e) { }

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<DevicesComponent> {
    canDeactivate(component: DevicesComponent): boolean {
        return component.canDeactivate();
    }
}

// include both with and without hash so can work with both
const appRoutes: Routes = [
    { path: 'licenses', component: LicensesComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'customers', component: CustomersComponent },
    { path: 'devices', component: DevicesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: '**', component: AuthenticationComponent },
];

export class MyHammerConfig extends HammerGestureConfig  {
    overrides = <any>{
        // override hammerjs default configuration
        'swipe':    { direction: Hammer.DIRECTION_HORIZONTAL },
        'pan':      { direction: Hammer.DIRECTION_ALL },
        'pinch':    { enable: true }
    };
}
delete Hammer.defaults.cssProps.userSelect; // allow text selection


@NgModule({
    declarations: [
        AppComponent,
        RegionStringPipe,
        AuthenticationComponent,
        CustomersComponent,
        DevicesComponent,
        MessageDialogComponent,
        LogViewComponent,
        JobsComponent,
        NavComponent,
        LicensesComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, {useHash: false}),
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
            loader: { provide: TranslateLoader, useClass: WebpackTranslateLoader }
        }),
        NgxWebstorageModule.forRoot(),
        MatFormFieldModule,
        MatInputModule,
        FontAwesomeModule,
        MatDialogModule,
        DeviceDetectorModule.forRoot(),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        VirtualScrollerModule,
    ],
    providers: [
        HttpClient,
        TranslateService,
        RegionService,
        LanguageService,
        StorageService,
        CookieService,
        ImageService,
        AuthenticationService,
        DataService,
        ApiService,
        CanDeactivateGuard,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
    ],
    entryComponents: [
        MessageDialogComponent,
        LogViewComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
