import { MatDialog } from '@angular/material/dialog';

import { Subscription, Subject, Observable } from 'rxjs';

const naturalCompare = require('string-natural-compare');
import html2pdf from 'html2pdf.js';

import { Job } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';
import * as Utils from '../utils';
import { Constants } from './constants';

export class JobList {
    private jobList: Array<Job>;
    private subscriptions: Array<Subscription>;
    private snFilter: string;
    private statusFilter: string;
    private filter: string;
    private listUpdated = new Subject<boolean>();

    public sortSNAsc: boolean;
    public sortTypeAsc: boolean;
    public sortStatusAsc: boolean;
    public sortPercentAsc: boolean;
    public sortCDateAsc: boolean;
    public sortUDateAsc: boolean;
    public filteredJobList: Array<Job>;
    public selectedCount: number;

    constructor(
                private dataService: DataService,
                private matDialog: MatDialog,
                private messageDialog: MessageDialog,
            ) {
        this.jobList = [];
        this.subscriptions = [];
        this.sortSNAsc = true;
        this.sortTypeAsc = true;
        this.sortStatusAsc = true;
        this.sortPercentAsc = true;
        this.sortCDateAsc = false;
        this.sortUDateAsc = false;
        this.filteredJobList = [];
        this.snFilter = '';
        this.filter = '';
        this.statusFilter = '';
        this.selectedCount = 0;

        this.subscriptions.push(dataService.getJobsObservable().subscribe(jobs => {
            if (!Array.isArray(jobs) || (jobs.length === 0)) {
                this.jobList = [];
            } else {
                this.updateList(jobs);
            }
            this.applyFilters(false);
        }));
    }

    private formatDate(dstr: string): string {
        if (dstr) {
            const date = new Date(dstr);
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        }
        return '';
    }

    private updateList(jobs: Array<Job>) {
        // update selected status data in existing job list
        if (this.jobList.length === 0) {
            for (const job of jobs) {
                job.sn = job.sn || ''; // insure null is blank
                job.ct = this.formatDate(job.creat);
                job.ut = this.formatDate(job.updat);
                job.type = job.type ? job.type.split('/').pop().replace(/-/g, ' ') : '';
            }
            this.jobList = jobs;
        } else {
            for (const job of jobs) {
                const ojob = this.jobList.find(item => {
                    return item.id === job.id;
                });
                if (!ojob) {
                    // new job
                    job.sn = job.sn || ''; // insure null is blank
                    job.ct = this.formatDate(job.creat);
                    job.ut = this.formatDate(job.updat);
                    job.type = job.type ? job.type.split('/').pop().replace(/-/g, ' ') : '';
                    this.jobList.unshift(job);
                } else {
                    ojob.ut = this.formatDate(job.updat);
                    ojob.status = job.status;
                    ojob.perc = job.perc;
                }
            }
        }

    }

    public destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public setUpdated(state: boolean) {
        this.listUpdated.next(state);
    }
    public getUpdatedObservable(): Observable<boolean> {
        return this.listUpdated.asObservable();
    }

    public getSelected(): Array<Job> {
        const selected: Array<Job> = [];

        for (const job of this.jobList) {
            if (job.sel) {
                selected.push(job);
            }
        }

        return selected;
    }

    public clearFilters() {
        this.filter = '';
        this.snFilter = '';
        this.statusFilter = '';
        this.applyFilters();
    }

    public applyFilter(filter: string) {
        this.filter = filter;
        if (filter) {
            this.snFilter = '';
            this.statusFilter = '';
        }
        this.applyFilters();
    }

    public applySNFilter(filter: string) {
        if (!filter) {
            this.snFilter = '';
        } else {
            this.snFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyStatusFilter(filter: string) {
        if (!filter) {
            this.statusFilter = '';
        } else {
            this.statusFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public toggleSortOrder(which: string) {
        switch (which) {
            case 'sn':
                this.sortSNAsc = !this.sortSNAsc;
                this.jobList.sort(this.sortBySN.bind(this));
                break;
            case 'type':
                this.sortTypeAsc = !this.sortTypeAsc;
                this.jobList.sort(this.sortByType.bind(this));
                break;
            case 'status':
                this.sortStatusAsc = !this.sortStatusAsc;
                this.jobList.sort(this.sortByStatus.bind(this));
                break;
            case 'percent':
                this.sortPercentAsc = !this.sortPercentAsc;
                this.jobList.sort(this.sortByPercent.bind(this));
                break;
            case 'cdate':
                this.sortCDateAsc = !this.sortCDateAsc;
                this.jobList.sort(this.sortByCDate.bind(this));
                break;
            case 'udate':
                this.sortUDateAsc = !this.sortUDateAsc;
                this.jobList.sort(this.sortByUDate.bind(this));
                break;
        }
        this.applyFilters();
    }

    private sortByType(a: Job, b: Job): number {
        if (this.sortTypeAsc) {
            return naturalCompare(a.status, b.status);
        } else {
            return naturalCompare(b.status, a.status);
        }
    }

    private sortBySN(a: Job, b: Job): number {
        if (this.sortSNAsc) {
            return naturalCompare(a.sn, b.sn);
        } else {
            return naturalCompare(b.sn, a.sn);
        }
    }

    private sortByStatus(a: Job, b: Job): number {
        const aidx = Constants.jobStatusList.indexOf(a.status);
        const bidx = Constants.jobStatusList.indexOf(b.status);
        return ((aidx - bidx) * (this.sortStatusAsc ? 1 : -1));
    }

    private sortByPercent(a: Job, b: Job): number {
        return ((a.perc - b.perc) * (this.sortPercentAsc ? 1 : -1));
    }

    private sortByCDate(a: Job, b: Job): number {
        const adate = new Date(a.creat);
        const bdate = new Date(b.creat);
        return ((adate.getTime() - bdate.getTime()) * (this.sortCDateAsc ? 1 : -1));
    }

    private sortByUDate(a: Job, b: Job): number {
        const adate = new Date(a.updat);
        const bdate = new Date(b.updat);
        return ((adate.getTime() - bdate.getTime()) * (this.sortUDateAsc ? 1 : -1));
    }

    public toggleSelect(job: Job) {
        job.sel = !job.sel;
        const found = this.filteredJobList.find(fdev => {
            return fdev.id === job.id;
        });
        if (found) {
            found.sel = job.sel;
        }
        this.countSelected();
    }

    public toggleAllSelect(state: boolean) {
        for (let ii = 0; ii < this.jobList.length; ii++) {
            if (this.jobList[ii].show) {
                this.jobList[ii].sel = state;
            }
        }
        this.countSelected();
    }

    private countSelected() {
        this.selectedCount = 0;
        this.jobList.forEach(job => {
            if (job.sel) {
                this.selectedCount++;
            }
        });
    }

    private applyFilters(clear: boolean = true) {
        for (const job of this.jobList) {
            job.show = false;
            if (clear) {
                job.sel = false;
            }
        }
        let filteredList = this.jobList;
        if (this.filter) {
            const lFilter = this.filter.toLocaleLowerCase();
            filteredList = filteredList.filter(job => {
                return ((job.sn.toLocaleLowerCase().indexOf(lFilter) >= 0)
                        || (job.status.toLocaleLowerCase().indexOf(lFilter) >= 0));
            });
        } else {
            if (this.snFilter) {
                const lFilter = this.snFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(job => {
                    return (job.sn.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if (this.statusFilter) {
                const lFilter = this.statusFilter.toLocaleLowerCase();
                filteredList = filteredList.filter(job => {
                    return (job.status.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
        }
        for (const job of filteredList) {
            job.show = true;
        }
        this.filteredJobList = filteredList;
        this.setUpdated(true);
    }

    private createColumn(text: string, header: boolean = false) {
        const col = document.createElement(header ? 'th' : 'td');
        col.innerHTML = text;
        col.style.color = 'black';
        col.style.border = '1px solid grey';
        return col;
    }

    private jobCols(job: Job): Array<string> {
        if (!job) {
            return [
                'Name',
                'Type',
                'Status',
                'Percent',
                'Devices',
                'Created',
                'Updated',
            ];
        } else {
            return [
                job.name,
                job.type,
                job.status,
                job.perc.toLocaleString(),
                job.sn,
                job.ct,
                job.ut,
            ];
        }
    }

    public exportPDF() {
        const table = document.createElement('table');
        const thead = document.createElement('thead');
        table.append(thead);
        const hrow = document.createElement('tr');
        thead.append(hrow);
        for (const col of this.jobCols(null)) {
            hrow.append(this.createColumn(col, true));
        }
        const tbody = document.createElement('tbody');
        table.append(tbody);
        for (const job of this.filteredJobList) {
            const row = document.createElement('tr');
            tbody.append(row);
            const dcols = this.jobCols(job);
            for (const col of dcols) {
                row.append(this.createColumn(col));
            }
        }
        const opts = {
            margin: 1,
            filename: 'jobs.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        };
        html2pdf().from(table, 'element').set(opts).save();
    }

    public exportCSV() {
        const csv: Array<string> = [];
        for (const job of this.filteredJobList) {
            const line: Array<string> = [];
            for (const col of this.jobCols(job)) {
                line.push(col.replace(/(\n|,)/g, ' '));
            }
            csv.push(line.join(','));
        }
        const content = 'data:text/csv;base64,' + btoa(unescape(encodeURIComponent(csv.join('\n'))));
        Utils.downloadFromDataURL('jobs.csv', content);
    }

}
