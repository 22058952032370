import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

function chromeCSSLoadBugRecovery() {
    // Edge LIES and says it is Chrome so may fail in future as csi is internal item...
    const isChrome = !!window['chrome'] && !!window['chrome'].csi;
    if (isChrome) {
        const ele = document.getElementById('overlayWSSWait');
        if (ele) {
            const styles = window.getComputedStyle(ele);
            if (styles) {
                const attr = 'border';
                const style = styles.getPropertyValue(attr);
                console.log(attr + ' = \'' + style + '\'');
                if (style.indexOf('rgb(13,') < 0) {
                    location.reload();
                }
            }
        }
    }
}
setTimeout(chromeCSSLoadBugRecovery, 1000); // delay in case of network slowness

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

