import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { versionInfo } from './models/constants';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
    private region: string;

    private readonly usRegionStrings = {
        'ZSPACE_URL': 'https://zspace.com',
        'LOGIN': 'LOGIN',
        'LOGIN_TAB_CONTACT': '',
        'LOGIN_TAB_FEEDBACK': '<a href=\'https://support.zspace.com/hc/en-us/requests/new\' '
            + 'rel=\'noopener\' target=\'_blank\'>Support Request Form</a>',
        'FEEDBACK_URL': '',
        'HOW_TO': '<a href=\'https://support.zspace.com/hc/en-us/articles/205703499\''
            + 'rel=\'noopener\' target=\'_blank\'>Click Here</a>',
        'DOWNLOADS': '<a href=\'https://zspace.com/downloads\''
            + 'rel=\'noopener\' target=\'_blank\'>https://zspace.com/downloads</a>',
    };
    private readonly chinaRegionStrings = {
        'ZSPACE_URL': 'https://zspace.asia',
        'LOGIN': '',
        'LOGIN_TAB_CONTACT': '',
        'LOGIN_TAB_FEEDBACK': '<a href=\'https://support.zspace.com/hc/en-us/requests/new\' '
            + 'rel=\'noopener\' target=\'_blank\'>Support Request Form</a>',
        'FEEDBACK_URL': '',
        'HOW_TO': 'a href=\'https://support.zspace.com/hc/en-us/articles/205703499\''
            + 'rel=\'noopener\' target=\'_blank\'>Click Here</a>',
        'DOWNLOADS': '<a href=\'https://zspace.com/downloads\''
            + 'rel=\'noopener\' target=\'_blank\'>https://zspace.com/downloads</a>',
    };

    constructor() {
        this.region = 'US';

        // have to initialize these here to get version included
        this.usRegionStrings['LOGIN_TAB_CONTACT'] = '<a href=\'mailto:support@zspace.com?subject=DMP version '
            + versionInfo.packageVersion + '\' rel=\'noopener\' target=\'_blank\'>support@zspace.com</a>';
        this.chinaRegionStrings['LOGIN_TAB_CONTACT'] = '<a href=\'mailto:support@zspace.com?subject=DMP version '
            + versionInfo.packageVersion + '\' rel=\'noopener\' target=\'_blank\'>support@zspace.com</a>';
        this.usRegionStrings['FEEDBACK_URL'] = 'https://support.zspace.com/hc/en-us/requests/new?subject=DMP version '
            + versionInfo.packageVersion;
        this.chinaRegionStrings['FEEDBACK_URL'] = 'https://support.zspace.com/hc/en-us/requests/new?subject=DMP version '
            + versionInfo.packageVersion ;
    }

    public setRegion(region: string): void {
        this.region = region;
        if (this.region === 'CN') {
            (window as any)._hmt = (window as any)._hmt || [];
            (window as any)._hmt.push(['_setAccount', '63627248e79d9f0d97fd22a0fedb8c80']);
            (window as any)._hmt.push(['_setAutoPageview', false]);
            (window as any)._hmt.push(['_setPageTag', '6436', 'zCentral']);
            (function (i, s, o, g, r, a, m) {
                i['BaiduAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }, i[r].l = 1 * Date.now();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 0;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            }) (window, document, 'script', 'https://hm.baidu.com/hm.js?a7a6c0ce683dad4449cfa3b873284d1b', 'ba');
        }
        if (this.region !== 'CN') {
            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }, i[r].l = 1 * Date.now();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 0;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            }) (window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            (window as any).ga('create', 'UA-27264310-1', 'auto'); // add your tracking ID here.
            (window as any).ga('set', 'page', window.location.href);
            (window as any).ga('send', 'pageview');

        }

        (function (i, s, o, g, r, a, m) {
            i['CrazyEggObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * Date.now();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        }) (window, document, 'script', '//script.crazyegg.com/pages/scripts/0028/0083.js', 'cegg');
    }

    public sendPageView(event: any): void {
        if ((this.region === 'CN') && event.routerEvent) {
            (window as any)._hmt.push(['_setPageTag', '6436', 'zCentral']);
            (window as any)._hmt.push(['_trackPageview', event.routerEvent.urlAfterRedirects]);
        }
        if (event.routerEvent) {
            if ((<any>window).ga) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', {
                    hitType: 'event',
                    eventCategory: 'page',
                    eventAction: 'view',
                    eventLabel: event.urlAfterRedirects
                });
            }
        } else if (typeof event === 'string') {
            if (this.region === 'CN') {
                (window as any)._hmt.push(['_setPageTag', '6436', 'zCentral']);
                (window as any)._hmt.push(['_trackPageview', event]);
            }
            if ((<any>window).ga) {
                (<any>window).ga('send', {
                    hitType: 'event',
                    eventCategory: 'page',
                    eventAction: 'view',
                    eventLabel: event
                });
            }
        }
    }

    public sendLaunchEvent(name: string): void {
        if (this.region === 'CN') {
            (window as any)._hmt.push(['_trackEvent', 'ContentItem', 'launch', name]);
        }
        if ((<any>window).ga) {
            (<any>window).ga('send', {
                hitType: 'event',
                eventCategory: 'item',
                eventAction: 'launch',
                eventLabel: name
            });
        }
    }

    public sendFeedbackEvent(name: string): void {
        if (this.region === 'CN') {
            (window as any)._hmt.push(['_trackEvent', 'ContentItem', 'feedback', name]);
        }
        if ((<any>window).ga) {
            (<any>window).ga('send', {
                hitType: 'event',
                eventCategory: 'item',
                eventAction: 'feedback',
                eventLabel: name
            });
        }
    }

    public allowLogin(): boolean {
        return (this.region !== 'CN');
    }

    public getRegionName(): string {
        let name = '';

        switch (this.region) {
            case 'CN':
                name = 'china';
                break;
            case 'US':
            default:
                name = 'world';
                break;
        }
        return name;
    }

    public lookupKey(key: string): string {
        let str = key; // default to key if not found

        switch (this.region) {
            case 'CN':
                if (this.chinaRegionStrings.hasOwnProperty(key)) {
                    str = this.chinaRegionStrings[key];
                }
                break;
            case 'US':
            default:
                if (this.usRegionStrings.hasOwnProperty(key)) {
                    str = this.usRegionStrings[key];
                }
                break;
        }

        return str;
    }
}

@Pipe({ name: 'regionstring' })
export class RegionStringPipe implements PipeTransform {
    constructor(private regionService: RegionService) {
    }

    public transform(str: string): string {
         return this.regionService.lookupKey(str);
    }
}
