<app-nav (setMode)="goDevices($event)" (viewLog)="viewLog($event)" [mode]="'job'" [logLabel]="'VIEW_JOB_LOG'"></app-nav>
<div class='jobs-panel-right'>
    <div class='jobs-head-row'>
        <div class='jobs-customer jobs-header'>
            <span *ngIf='selectedCustomer' class='jobs-label'>{{ 'VIEW_FOR' | translate }}: {{ selectedCustomer.name }} ({{ selectedCustomer.email }})</span>
        </div>
        <div class='jobs-count'>{{ jobList.selectedCount }} {{ 'JOBS_SELECTED' | translate }}</div>
    </div>
    <div class='jobs-head-row jobs-fixed'>
        <form [formGroup]='filterJobForm' class='jobs-filter'>
            <input formControlName='filter' placeholder="{{ 'FILTER_TEXT' | translate }}" type='search'>
            <fa-icon *ngIf='!showSearch' [icon]='faCaretDown' class='header' (click)='toggleSearch($event)'></fa-icon>
            <fa-icon *ngIf='showSearch' [icon]='faCaretUp' class='header' (click)='toggleSearch($event)'></fa-icon>
            <div class='jobs-filter-dropdown' [class.expand]='showSearch' (click)="ignoreClick($event)">
                <div class="jobs-filter-row">
                    <div class="jobs-filter-col">{{ 'DEVICE_SN' | translate }}</div>
                    <div class="jobs-filter-col">
                        <input formControlName='sn' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'sn')">
                    </div>
                </div>
                <div class="jobs-filter-row">
                    <div class="jobs-filter-col">{{ 'STATUS' | translate }}</div>
                    <div class="jobs-filter-col">
                        <input formControlName='status' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'status')">
                    </div>
                </div>
            </div>
        </form>
        <div class='status-message'>
            <span>{{ message | translate }}</span>
            <div>
                <fa-icon class='pointer' [icon]="faFileCsv" (click)="jobList.exportCSV()"></fa-icon>
                <fa-icon class='pointer' [icon]="faFilePdf" (click)="jobList.exportPDF()"></fa-icon>
            </div>
        </div>
    </div>
    <div class='jobs-head-row'>
        <div class='jobs-check jobs-header' (click)='toggleAllJobSelect($event)'>
            <div class="square-box">
                <input id="sub_job_all" type='checkbox' [(ngModel)]="allJobSelect" />
                <label for="sub_job_all"></label>
            </div>
        </div>
        <div class='jobs-name jobs-header'>
            {{ 'JOB_ID' | translate }}
        </div>
        <div class='jobs-sn jobs-header' (click)='toggleSortOrder($event, "sn")'>
            {{ 'DEVICE_SN' | translate }}
            <fa-icon *ngIf='jobList.sortSNAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortSNAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='jobs-type jobs-header pointer' (click)='toggleSortOrder($event, "type")'>
            {{ 'JOB_TYPE' | translate }}
            <fa-icon *ngIf='jobList.sortTypeAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortTypeAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='jobs-status jobs-header pointer' (click)='toggleSortOrder($event, "status")'>
            {{ 'STATUS' | translate }}
            <fa-icon *ngIf='jobList.sortStatusAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortStatusAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='jobs-perc jobs-header' (click)='toggleSortOrder($event, "percent")'>
            {{ 'JOB_PERC' | translate }}
            <fa-icon *ngIf='jobList.sortPercentAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortPercentAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='jobs-date jobs-header' (click)='toggleSortOrder($event, "cdate")'>
            {{ 'JOB_CDATE' | translate }}
            <fa-icon *ngIf='jobList.sortCDateAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortCDateAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='jobs-date jobs-header' (click)='toggleSortOrder($event, "udate")'>
            {{ 'JOB_UDATE' | translate }}
            <fa-icon *ngIf='jobList.sortUDateAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!jobList.sortUDateAsc' [icon]='faSortUp'></fa-icon>
        </div>
    </div>
    <virtual-scroller class='jobs-list' #jscroll [items]="jobList.filteredJobList"
            [bufferAmount]='16' [enableUnequalChildrenSizes]='true' [stripedTable]='true'>
        <ng-container *ngFor='let job of jscroll.viewPortItems' >
            <section *ngIf='job.show' class='jobs-section-row'>
                <div class='jobs-row'>
                    <div class='jobs-cell jobs-check' (click)='toggleJobSelect($event, job)'>
                        <div class="square-box">
                            <input id="sub_{{ job.id }}" type='checkbox' [(ngModel)]="job.sel" />
                            <label for="sub_{{ job.id }}"></label>
                        </div>
                    </div>
                    <div class='jobs-cell jobs-name'>{{ job.name }}</div>
                    <div class='jobs-cell jobs-sn'>{{ job.sn }}</div>
                    <div class='jobs-cell jobs-type'>{{ job.type | translate }}</div>
                    <div class='jobs-cell jobs-status'>{{ job.status | translate }}</div>
                    <div class='jobs-cell jobs-perc'>{{ job.perc.toString() + '%' }}</div>
                    <div class='jobs-cell jobs-date'>{{ job.ct }}</div>
                    <div class='jobs-cell jobs-date'>{{ job.ut }}</div>
                </div>
            </section>
        </ng-container>
    </virtual-scroller>
</div>
