import { MatDialog } from '@angular/material/dialog';

import { Subscription, Subject, Observable } from 'rxjs';

const naturalCompare = require('string-natural-compare');
import html2pdf from 'html2pdf.js';

import { Device, License, DeviceChange, DeviceStatus, DeviceComm } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';
import { LicenseOrderList } from '../models/license-order-list';
import * as Utils from '../utils';

export class DeviceList {
    private deviceList: Array<Device>;
    private subscriptions: Array<Subscription>;
    private nameFilter: string;
    private productFilter: string;
    private soFilter: string;
    private poFilter: string;
    private filter: string;
    private listUpdated = new Subject<boolean>();

    public changesPending: number;
    public sortNameAsc: boolean;
    public sortSOAsc: boolean;
    public sortPOAsc: boolean;
    public sortStatusAsc: boolean;
    public sortTypeAsc: boolean;
    public filteredDeviceList: Array<Device>;
    public selectedStatusFilter: string;
    public selectedTypeFilter: string;
    public selectedCommFilter: string;
    public selectedCount: number;

    constructor(
                private dataService: DataService,
                private matDialog: MatDialog,
                private messageDialog: MessageDialog,
            ) {
        this.deviceList = [];
        this.subscriptions = [];
        this.changesPending = 0;
        this.sortNameAsc = false; // name (SN) defaults to desc order
        this.sortSOAsc = true;
        this.sortPOAsc = true;
        this.sortStatusAsc = true;
        this.sortTypeAsc = true;
        this.filteredDeviceList = [];
        this.nameFilter = '';
        this.soFilter = '';
        this.poFilter = '';
        this.filter = '';
        this.productFilter = '';
        this.selectedStatusFilter = '';
        this.selectedTypeFilter = '';
        this.selectedCommFilter = '';
        this.selectedCount = 0;

        this.subscriptions.push(dataService.getDevicesObservable().subscribe(devices => {
            if (!Array.isArray(devices) || (devices.length === 0)) {
                this.changesPending = 0;
                this.deviceList = [];
            } else {
                if (Array.isArray(this.deviceList) && (this.deviceList.length > 0)) {
                    this.updateList(devices);
                } else {
                    this.changesPending = 0;
                    for (const device of devices) {
                        device.lics.sort(this.sortLicenseByExpireProduct);
                        if (device.idate) {
                            const date = new Date(device.idate);
                            device.tidt = date.toLocaleDateString();
                        } else {
                            device.tidt = '';
                        }
                        for (const lic of device.lics) {
                            if (lic.exp === 0) {
                                lic.texp = 'Never';
                            } else {
                                const date = new Date(lic.exp);
                                lic.texp = date.toLocaleDateString();
                            }
                        }
                    }
                    devices.sort(this.sortBySO.bind(this));
                    devices.sort(this.sortBySerialName.bind(this));
                    devices.sort(this.sortByStatus.bind(this));
                    this.deviceList = devices;
                }
            }
            this.applyFilters(false);
        }));
    }

    private updateList(devices: Array<Device>) {
        // update selected status data in existing device list
        for (const device of devices) {
            const listDevice = this.deviceList.find(dev => {
                return dev.id === device.id;
            });
            if (listDevice) {
                listDevice.act = device.act;
                listDevice.stat = device.stat;
                listDevice.stxt = device.stxt;
                listDevice.ip = device.ip;
                listDevice.olst = device.olst;
                listDevice.rits = device.rits;
                listDevice.name = device.name;
                listDevice.idate = device.idate;
                const date = new Date(device.idate);
                listDevice.tidt = date.toLocaleDateString();
                for (let ii = listDevice.lics.length - 1; ii >= 0; ii--) {
                    // if no unsaved change, update state
                    //  if license is saved but not in new data, remove it
                    if ((this.changesPending === 0)
                            || ((listDevice.lics[ii].stat !== 3) && (listDevice.lics[ii].stat !== 4))) {
                        const fdlic = device.lics.find(dlic => {
                            return dlic.id === listDevice.lics[ii].id;
                        });
                        if (fdlic) {
                            listDevice.lics[ii].stat = fdlic.stat;
                        } else {
                            // remove it
                            listDevice.lics.splice(ii, 1);
                        }
                    }
                }
                let added = false;
                for (const lic of device.lics) {
                    const newlic = listDevice.lics.find(dlic => {
                        return dlic.id === lic.id;
                    });
                    if (!newlic) {
                        // this was in new but not in old, add it
                        listDevice.lics.push(lic);
                        added = true;
                    }
                }
                if (added) {
                    listDevice.lics.sort(this.sortLicenseByExpireProduct);
                }
            }
        }
    }

    public destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public setUpdated(state: boolean) {
        this.listUpdated.next(state);
    }
    public getUpdatedObservable(): Observable<boolean> {
        return this.listUpdated.asObservable();
    }

    public getSelected(): Array<Device> {
        const selected: Array<Device> = [];

        for (const device of this.deviceList) {
            if (device.sel) {
                selected.push(device);
            }
        }

        return selected;
    }

    public getOldNewById(id: number): Array<Device> {
        const devices: Array<Device> = [];
        const orgDeviceList = this.dataService.getOriginalDevices();
        devices[0] = orgDeviceList.find(device => {
            return device.id === id;
        });
        devices[1] = this.deviceList.find(device => {
            return device.id === id;
        });
        return devices;
    }

    private getRemovedLicenses(org: Device, cur: Device): Array<License> {
        const rem: Array<License> = [];

        for (const olic of org.lics) {
            let found = false;
            for (const clic of cur.lics) {
                // if current is marked with status 3 then it is to be deleted
                if ((clic.id === olic.id) && (clic.stat !== 4)) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                rem.push(olic);
            }
        }
        return rem;
    }

    private getAddedLicenses(org: Device, cur: Device): Array<License> {
        const add: Array<License> = [];

        for (const clic of cur.lics) {
            let found = false;
            for (const olic of org.lics) {
                if (clic.id === olic.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                add.push(clic);
            }
        }
        return add;
    }

    public getLicenseChanges(): Array<DeviceChange> {
        const changes: Array<DeviceChange> = [];
        const orgDeviceList = this.dataService.getOriginalDevices();

        for (const curDevice of this.deviceList) {
            const orgDevice = orgDeviceList.find(orgDev => {
                return orgDev.id === curDevice.id;
            });
            if (orgDevice) {
                const removed = this.getRemovedLicenses(orgDevice, curDevice);
                if (removed) {
                    for (const remove of removed) {
                        changes.push({did: curDevice.id, lid: remove.id, stat: remove.stat, act: 'del'});
                    }
                }

                const added = this.getAddedLicenses(orgDevice, curDevice);
                if (added) {
                    for (const add of added) {
                        changes.push({did: curDevice.id, lid: add.id, stat: 0, act: 'add'});
                    }
                }
            }
        }
        return changes;
    }

    public clearFilters() {
        this.nameFilter = '';
        this.soFilter = '';
        this.poFilter = '';
        this.selectedStatusFilter = '';
        this.selectedTypeFilter = '';
        this.selectedCommFilter = '';
        this.productFilter = '';
        this.applyFilters();
    }

    public applyFilter(filter: string) {
        this.filter = filter;
        if (filter) {
            this.nameFilter = '';
            this.soFilter = '';
            this.poFilter = '';
            this.selectedStatusFilter = '';
            this.selectedTypeFilter = '';
            this.selectedCommFilter = '';
            this.productFilter = '';
        }
        this.applyFilters();
    }

    public applyNameFilter(filter: string) {
        if (!filter) {
            this.nameFilter = '';
        } else {
            this.nameFilter = filter;
        }
        this.applyFilters();
    }

    public applySOFilter(filter: string) {
        if (!filter) {
            this.soFilter = '';
        } else {
            this.soFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyPOFilter(filter: string) {
        if (!filter) {
            this.poFilter = '';
        } else {
            this.poFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyProductFilter(filter: string) {
        if (!filter) {
            this.productFilter = '';
        } else {
            this.productFilter = filter;
            this.filter = '';
        }
        this.applyFilters();
    }

    public applyStatusFilter(filter: string) {
        this.selectedStatusFilter = (filter ? filter : '');
        this.filter = '';
        this.applyFilters();
    }

    public applyTypeFilter(filter: string) {
        this.selectedTypeFilter = (filter ? filter : '');
        this.filter = '';
        this.applyFilters();
    }

    public applyCommFilter(filter: string) {
        this.selectedCommFilter = (filter ? filter : '');
        this.filter = '';
        this.applyFilters();
    }

    public toggleSortOrder(which: string) {
        switch (which) {
            case 'type':
                this.sortTypeAsc = !this.sortTypeAsc;
                this.deviceList.sort(this.sortByType.bind(this));
                break;
            case 'sname':
                this.sortNameAsc = !this.sortNameAsc;
                this.deviceList.sort(this.sortBySerialName.bind(this));
                break;
            case 'so':
                this.sortSOAsc = !this.sortSOAsc;
                this.deviceList.sort(this.sortBySO.bind(this));
                break;
            case 'po':
                this.sortPOAsc = !this.sortPOAsc;
                this.deviceList.sort(this.sortByPO.bind(this));
                break;
            case 'status':
                this.sortStatusAsc = !this.sortStatusAsc;
                this.deviceList.sort(this.sortByStatus.bind(this));
                break;
        }
        this.applyFilters();
    }

    private sortBySerialName(a: Device, b: Device): number {
        if (this.sortNameAsc) {
            return naturalCompare(a.sn + a.name, b.sn + b.name);
        } else {
            return naturalCompare(b.sn + b.name, a.sn + a.name);
        }
    }

    private sortBySO(a: Device, b: Device): number {
        if (this.sortSOAsc) {
            return naturalCompare(a.so, b.so);
        } else {
            return naturalCompare(b.so, a.so);
        }
    }

    private sortByPO(a: Device, b: Device): number {
        if (this.sortPOAsc) {
            return naturalCompare(a.po, b.po);
        } else {
            return naturalCompare(b.po, a.po);
        }
    }

    private sortLicenseByExpireProduct(a: License, b: License) {
        if (a.exp === b.exp) {
            // sort by name asc
            return naturalCompare(a.prod, b.prod);
        }
        // sort by expiration asc
        return a.exp - b.exp;
    }

    private sortByType(a: Device, b: Device): number {
        // sort by type, order TBD
        let res = 0;

        res = naturalCompare(a.type, b.type);

        if ((res !== 0) && !this.sortTypeAsc) {
            res = -res; // invert for desc
        }
        return res;
    }

    private sortByStatus(a: Device, b: Device): number {
        // order by status 0=pending, 1=activated, 2=deactivated
        //  should be in pending, deactivated, activated order
        let res = 0;
        if (a.stat === b.stat) {
            res = 0;
        } else if (a.stat === 0) {
            res = -1;
        } else if (b.stat === 0) {
            res = 1;
        } else if (a.stat === 2) {
            res = -1;
        } else if (b.stat === 2) {
            res = 1;
        }
        if ((res !== 0) && !this.sortStatusAsc) {
            res = -res; // invert for desc
        }
        return res;
    }

    public aboveExpanded(device: Device): boolean {
        let result = false;

        for (const dev of this.filteredDeviceList) {
            if (dev.id === device.id) {
                break;
            }
            if (dev.exp) {
                result = true;
                break;
            }
        }
        return result;
    }

    public toggleExpand(device: Device) {
        if (!device) {
            // close then all
            for (const dev of this.filteredDeviceList) {
                dev.exp = false;
            }
        } else if (device.exp) {
            device.exp = false;
        } else {
            // if expanding a row then close all others
            for (const dev of this.filteredDeviceList) {
                dev.exp = false;
            }
            device.exp = true;
        }
    }

    public toggleSelect(device: Device) {
        device.sel = !device.sel;
        const dev = this.filteredDeviceList.find(fdev => {
            return fdev.id === device.id;
        });
        if (dev) {
            dev.sel = device.sel;
        }
        this.countSelected();
    }

    public toggleAllSelect(state: boolean) {
        for (let ii = 0; ii < this.deviceList.length; ii++) {
            if (this.deviceList[ii].show) {
                this.deviceList[ii].sel = state;
            }
        }
        this.countSelected();
    }

    private countSelected() {
        this.selectedCount = 0;
        this.deviceList.forEach(device => {
            if (device.sel) {
                this.selectedCount++;
            }
        });
    }

    private applyFilters(clear: boolean = true) {
        for (const device of this.deviceList) {
            device.show = false;
            if (clear) {
                device.exp = false;
                device.sel = false;
            }
        }
        let filteredDeviceList = this.deviceList;
        if (this.filter) {
            const lFilter = this.filter.toLocaleLowerCase();
            filteredDeviceList = filteredDeviceList.filter(device => {
                return ((device.sn.toLocaleLowerCase().indexOf(lFilter) >= 0)
                            || (device.name.toLocaleLowerCase().indexOf(lFilter) >= 0)
                            || (device.so.toLocaleLowerCase().indexOf(lFilter) >= 0)
                            || (device.po.toLocaleLowerCase().indexOf(lFilter) >= 0));
            });
        } else {
            if (this.nameFilter) {
                const lFilter = this.nameFilter.toLocaleLowerCase();
                filteredDeviceList = filteredDeviceList.filter(device => {
                    return ((device.sn.toLocaleLowerCase().indexOf(lFilter) >= 0)
                                || (device.name.toLocaleLowerCase().indexOf(lFilter) >= 0));
                });
            }
            if (this.soFilter) {
                const lFilter = this.soFilter.toLocaleLowerCase();
                filteredDeviceList = filteredDeviceList.filter(device => {
                    return (device.so.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if (this.poFilter) {
                const lFilter = this.poFilter.toLocaleLowerCase();
                filteredDeviceList = filteredDeviceList.filter(device => {
                    return (device.po.toLocaleLowerCase().indexOf(lFilter) >= 0);
                });
            }
            if ((this.productFilter) && (this.productFilter !== '!')) {
                let lFilter = this.productFilter.toLocaleLowerCase();
                let negate = false;
                if (lFilter.substr(0, 1) === '!') {
                    negate = true;
                    lFilter = lFilter.substr(1);
                }
                filteredDeviceList = filteredDeviceList.filter(device => {
                    for (const lic of device.lics) {
                        if (negate) {
                            return (lic.prod.toLocaleLowerCase().indexOf(lFilter) < 0);
                        } else {
                            return (lic.prod.toLocaleLowerCase().indexOf(lFilter) >= 0);
                        }
                    }
                    return negate; // if no licenses then return true if negating or false otherwise
                });

            }
            if (this.selectedStatusFilter) {
                const status = DeviceStatus.indexOf(this.selectedStatusFilter);
                if (status >= 0) {
                    filteredDeviceList = filteredDeviceList.filter(device => {
                        return (device.stat === status);
                    });
                }
            }
            if (this.selectedTypeFilter) {
                filteredDeviceList = filteredDeviceList.filter(device => {
                    return (device.type === this.selectedTypeFilter);
                });
            }
            if (this.selectedCommFilter) {
                const olst = DeviceComm.indexOf(this.selectedCommFilter);
                if (olst >= 0) {
                    filteredDeviceList = filteredDeviceList.filter(device => {
                        return (device.olst === olst);
                    });
                }
            }
        }
        for (const device of filteredDeviceList) {
            device.show = true;
        }
        this.filteredDeviceList = filteredDeviceList;
        this.setUpdated(true);
    }

    public removeLicenses(licenseOrderList: LicenseOrderList,
            devices: Array<Device>, licenses: Array<License>, refresh: boolean = false) {
        for (const device of devices) {
            for (const license of licenses) {
                for (let ii = 0; ii < device.lics.length; ii++) {
                    if (device.lics[ii].id === license.id) {
                        let remove = false;
                        let nothing = false;
                        switch (device.lics[ii].stat) {
                            case 0:
                                // added and saved but not applied so just remove
                                remove = true;
                                this.changesPending++;
                                break;
                            case 1:
                                // applied to device, mark to be removed
                                device.lics[ii].stat = 4;
                                this.changesPending++;
                                break;
                            case 3:
                                // added but not saved so just remove
                                remove = true;
                                this.changesPending--;
                                break;
                            default: // case 2 or unknown
                                // do nothing.
                                nothing = true;
                                break;
                        }
                        if (!nothing) {
                            if (!refresh) {
                                licenseOrderList.incrementAvail(license.id);
                            }
                            if (remove) {
                                device.lics.splice(ii, 1);
                            }
                        }
                        break; // do next license
                    }
                }
            }
        }
    }

    public addLicenses(licenseOrderList: LicenseOrderList,
            devices: Array<Device>, licenses: Array<License>, refresh: boolean = false) {
        const dupList: Array<string> = [];

        for (const license of licenses) {
            const order = licenseOrderList.getOrderWithLicense(license.id);
            if (!order) {
                continue;
            }
            const nlic: License = {
                id: license.id,
                pid: license.pid,
                prod: license.prod,
                key: license.key,
                avail: 0,
                total: 0,
                exp: license.exp,
                texp: license.texp,
                stat: 3, // 0=added but not applied, 1=applied to device, 2=removed but not applied, 3=added not saved
                so: order.so,
                po: order.po,
            };
            let added = 0;
            for (const device of devices) {
                let exists = false;
                for (const tlic of device.lics) {
                    if (tlic.pid === license.pid) {
                        exists = true;
                        dupList.push(device.sn + ': ' + tlic.prod);
                    }
                }
                if (!exists) {
                    device.lics.push({...nlic});
                    added++;
                }
            }
            if (!refresh) {
                licenseOrderList.decrementAvail(license.id, added);
            }
        }

        for (const device of devices) {
            device.lics.sort(this.sortLicenseByExpireProduct);
        }

        this.changesPending += devices.length;
        if (dupList.length > 0) {
            this.messageDialog.open(this.matDialog, 'LIC_PROD_DUPLICATE', dupList);
        }
    }

    private createColumn(text: string, header: boolean = false) {
        const col = document.createElement(header ? 'th' : 'td');
        col.innerHTML = text;
        col.style.color = 'black';
        col.style.border = '1px solid grey';
        return col;
    }

    private deviceCols(device: Device): Array<string> {
        if (!device) {
            return [
                'SN',
                'Name',
                'Status',
                'type',
                'SO',
                'PO',
                'Connection',
                'Registration',
            ];
        } else {
            return [
                device.sn,
                device.name,
                device.stxt,
                device.type,
                device.so,
                device.po,
                device.ip + ' ' + DeviceComm[device.olst],
                DeviceStatus[device.stat] + (device.stat === 0 ? (' ' + device.act) : ''),
            ];
        }
    }

    private deviceLicenseCols(license: License): Array<string> {
        if (!license) {
            return [
                'Product',
                'License Key',
                'Expires',
                'SO',
                'PO',
            ];
        } else {
            return [
                license.prod,
                license.key,
                license.texp,
                license.so,
                license.po,
            ];
        }
    }

    public exportPDF() {
        const table = document.createElement('table');
        const thead = document.createElement('thead');
        table.append(thead);
        const hrow = document.createElement('tr');
        thead.append(hrow);
        for (const col of this.deviceCols(null)) {
            hrow.append(this.createColumn(col, true));
        }
        const tbody = document.createElement('tbody');
        table.append(tbody);
        for (const device of this.filteredDeviceList) {
            const row = document.createElement('tr');
            tbody.append(row);
            const dcols = this.deviceCols(device);
            for (const col of dcols) {
                row.append(this.createColumn(col));
            }
            if (device.lics.length > 0) {
                const lrow = document.createElement('tr');
                tbody.append(lrow);
                lrow.append(this.createColumn(''));
                lrow.append(this.createColumn(''));
                const lcol = this.createColumn('');
                lrow.append(lcol);
                lcol.colSpan = dcols.length - 2;
                const ltable = document.createElement('table');
                lcol.append(ltable);
                const lthead = document.createElement('thead');
                ltable.append(lthead);
                const lhrow = document.createElement('tr');
                lthead.append(lhrow);
                for (const col of this.deviceLicenseCols(null)) {
                    lhrow.append(this.createColumn(col, true));
                }
                const ltbody = document.createElement('tbody');
                ltable.append(ltbody);
                for (const license of device.lics) {
                    const ltrow = document.createElement('tr');
                    ltbody.append(ltrow);
                    for (const col of this.deviceLicenseCols(license)) {
                        ltrow.append(this.createColumn(col));
                    }
                }
            }
        }
        const opts = {
            margin: 1,
            filename: 'devices.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        };
        html2pdf().from(table, 'element').set(opts).save();
    }

    public exportCSV() {
        const csv: Array<string> = [];
        for (const device of this.filteredDeviceList) {
            const line: Array<string> = [];
            for (const col of this.deviceCols(device)) {
                line.push(col.replace(/(\n|,)/g, ' '));
            }
            for (const license of device.lics) {
                for (const col of this.deviceLicenseCols(license)) {
                    line.push(col.replace(/(\n|,)/g, ' '));
                }
            }
            csv.push(line.join(','));
        }
        const content = 'data:text/csv;base64,' + btoa(unescape(encodeURIComponent(csv.join('\n'))));
        Utils.downloadFromDataURL('devices.csv', content);
    }

}
