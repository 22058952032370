<div class='status-message'>{{ message | translate }}</div>
<form [formGroup]='searchCustForm' class='cust-search'>
        <input formControlName='search' placeholder="{{ 'SEARCH_TEXT' | translate }}"
                type='search' (keydown)='handleSearchKey($event)' autofocus>
        <fa-icon *ngIf='!showSearch' [icon]='faCaretDown' (click)='toggleSearch($event)'></fa-icon>
        <fa-icon *ngIf='showSearch' [icon]='faCaretUp' (click)='toggleSearch($event)'></fa-icon>
        <div class='cust-search-dropdown' [class.expand]='showSearch'>
            <table>
                <tr>
                    <td>{{ 'EMAIL' | translate }}</td>
                    <td>
                        <input formControlName='email' placeholder="{{ 'SEARCH_TEXT' | translate }}" type='search'>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'CUSTOMER_NAME' | translate }}</td>
                    <td>
                        <input formControlName='name' placeholder="{{ 'SEARCH_TEXT' | translate }}" type='search'>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'ORGANIZATION' | translate }}</td>
                    <td>
                        <input formControlName='org' placeholder="{{ 'SEARCH_TEXT' | translate }}" type='search'>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <button [class.disabled]='!customerList.searchOkay' (click)='search($event)'>{{ 'SEARCH' | translate }}</button>
                    </td>
                </tr>
            </table>
        </div>
</form>
<div class='cust-head-row'>
    <div class='cust-name cust-header' (click)='toggleSortOrder($event, "email")'>
        {{ 'EMAIL' | translate }}
        <fa-icon *ngIf='customerList.sortEmailAsc' [icon]='faSortDown'></fa-icon>
        <fa-icon *ngIf='!customerList.sortEmailAsc' [icon]='faSortUp'></fa-icon>
    </div>
    <div class='cust-name cust-header' (click)='toggleSortOrder($event, "name")'>
        {{ 'CUSTOMER_NAME' | translate }}
        <fa-icon *ngIf='customerList.sortNameAsc' [icon]='faSortDown'></fa-icon>
        <fa-icon *ngIf='!customerList.sortNameAsc' [icon]='faSortUp'></fa-icon>
    </div>
    <div class='cust-org cust-header' (click)='toggleSortOrder($event, "org")'>
        {{ 'ORGANIZATION' | translate }}
        <fa-icon *ngIf='customerList.sortOrgAsc' [icon]='faSortDown'></fa-icon>
        <fa-icon *ngIf='!customerList.sortOrgAsc' [icon]='faSortUp'></fa-icon>
    </div>
    <div class='cust-dev cust-header'>{{ 'DEVICES' | translate }}</div>
    <div class='cust-lic cust-header'>{{ 'LICENSES' | translate }}</div>
</div>
<virtual-scroller class='cust-panel' #cscroll [items]="customerList.customerList"
        [bufferAmount]='16' [enableUnequalChildrenSizes]='true' [stripedTable]='true'>
    <ng-container *ngFor='let customer of cscroll.viewPortItems' >
        <div class='cust-row' (click)='showDevices(customer)'>
            <div class='cust-email'>{{ customer.email }}</div>
            <div class='cust-name'>{{ customer.name }}</div>
            <div class='cust-org'>{{ customer.org }}</div>
            <div class='cust-dev'>{{ customer.devs[0] }} / {{ customer.devs[1] }}</div>
            <div class='cust-lic'>{{ customer.lics[0] }} / {{ customer.lics[1] }}</div>
        </div>
    </ng-container>
</virtual-scroller>
