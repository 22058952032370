<div class='md-title'>
    <span>{{ title | translate }}</span>
    <div class='pull-right'>
        <fa-icon class='clickable' [icon]="faFileCsv" (click)="exportCSV()"></fa-icon>
        <fa-icon class='clickable' [icon]="faFilePdf" (click)="exportPDF()"></fa-icon>
        <fa-icon class='clickable' [icon]="faTimes" (click)="close()"></fa-icon>
    </div>
</div>
<form *ngIf='showFilter' class='md-filter' [formGroup]='filterForm'>
    <input formControlName='filter' placeholder="{{ 'FILTER_TEXT' | translate }}" type='text' value=''>
</form>
<div id='md_body' class='md-body' (scroll)="scrolledBody($event)">
    <table id='md_list' class='md-list' style="color: black">
        <ng-container *ngFor="let line of messageLines">
            <tr *ngIf='line.show' class='md-line'>
                <td *ngFor='let col of line.text'><span [innerHTML]="col | translate"></span></td>
            </tr>
        </ng-container>
    </table>
</div>
<mat-dialog-actions *ngIf='confirm'>
    <button mat-button (click)="close('yes')" [disabled]="requireEnd && !atEnd">{{ labelYes | translate }}</button>
    <button mat-button (click)="close('no')">{{ labelNo | translate }}</button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf='!confirm'>
    <button mat-button (click)="close()">{{ labelYes | translate }}</button>
</mat-dialog-actions>
