<app-nav (setMode)="setManageMode($event)" (viewLog)="viewLog($event)" [mode]="manageMode" [logLabel]="'VIEW_DEV_LOG'"></app-nav>
<div class='dev-panel-right'>
    <div class='dev-head-row'>
        <div class='dev-customer dev-header'>
            <span *ngIf='selectedCustomer' class='dev-label'>{{ 'VIEW_FOR' | translate }}: {{ selectedCustomer.name }} ({{ selectedCustomer.email }})</span>
        </div>
        <div class='dev-count'>{{ deviceList.selectedCount }} {{ 'DEVS_SELECTED' | translate }}</div>
        <div *ngIf='manageMode == "dev"' class='dev-hdr-butts'>
            <button (click)='deviceRegistration($event, false)'
                    class='dev-button wide back-blue'
                    [disabled]='deviceList.selectedCount == 0'>
                    {{ 'SEL_DEV_UNREG' | translate }}</button>
        </div>
        <div *ngIf='manageMode == "lic"' class='dev-hdr-butts'>
            <div class='butt-container'>
                <button (click)='showLicensesForSelected($event, true)'
                    class='dev-button mid back-blue'
                    [disabled]='deviceList.selectedCount == 0'>
                    {{ 'ADD_LIC_TO_SEL' | translate }}</button>
                <div class='dev-license-dropdown' [class.expand]='showAddDeviceId == "addselect"'
                        (click)="ignoreClick($event)" [attr.allow_wheel]="true">
                    <div class='dev-license-dd-row dev-license-dd-header'>
                        <div class='dev-license-dd-col1'>{{ 'AVAIL_TOTAL' | translate }}</div>
                        <div class='dev-license-dd-col2'>{{ 'DEV_PRODUCT' | translate }}</div>
                        <div class='dev-license-dd-col3'>{{ 'EXPIRES' | translate }}</div>
                    </div>
                    <div class='dev-license-dd-body'>
                        <ng-container *ngFor="let border of briefLicenseOrders">
                            <div class='dev-license-dd-row dev-license-dd-so'
                                    (click)="ignoreClick($event)">{{ border.name }}</div>
                            <div *ngFor='let blic of border.blics' class='dev-license-dd-row pointer'
                                    (click)="addLicense($event, null, blic.id)">
                                <div class='dev-license-dd-col1'>{{ blic.seats }}</div>
                                <div class='dev-license-dd-col2'>{{ blic.prod }}</div>
                                <div class='dev-license-dd-col3'>{{ blic.exp }}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class='butt-container'>
                <button (click)='showLicensesForSelected($event, false)'
                    class='dev-button mid back-blue'
                    [disabled]='deviceList.selectedCount == 0'>
                {{ 'REM_LIC_FM_SEL' | translate }}</button>
                <div class='dev-license-dropdown'
                        [class.expand]='showAddDeviceId == "remselect" && briefLicenseOrders.length > 0'
                        (click)="ignoreClick($event)" [attr.allow_wheel]="true">
                    <div class='dev-license-dd-row dev-license-dd-header'>
                        <div class='dev-license-dd-col1'></div>
                        <div class='dev-license-dd-col2'>{{ 'DEV_PRODUCT' | translate }}</div>
                        <div class='dev-license-dd-col3'>{{ 'EXPIRES' | translate }}</div>
                    </div>
                    <div class='dev-license-dd-body'>
                        <ng-container *ngFor="let border of briefLicenseOrders">
                            <div class='dev-license-dd-row dev-license-dd-so'
                                    (click)="ignoreClick($event)">{{ border.name }}</div>
                            <div *ngFor='let blic of border.blics' class='dev-license-dd-row pointer'
                                    (click)="remLicense($event, null, blic.id)">
                                <div class='dev-license-dd-col1'></div>
                                <div class='dev-license-dd-col2'>{{ blic.prod }}</div>
                                <div class='dev-license-dd-col3'>{{ blic.exp }}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <button (click)='saveChanges($event)'
                class='dev-button mid back-gold'
                [disabled]='deviceList.changesPending == 0'>
                {{ 'DEV_SAVE_LIC' | translate }}</button>
            <button (click)='applyChanges($event)'
                class='dev-button mid back-green'
                [disabled]='deviceList.selectedCount == 0'>
                {{ 'DEV_APPLY_LIC' | translate }}</button>
        </div>
    </div>
    <div class='dev-head-row dev-fixed'>
        <form [formGroup]='filterDevForm' class='dev-filter'>
            <input formControlName='filter' placeholder="{{ 'FILTER_TEXT' | translate }}" type='search'>
            <fa-icon *ngIf='!showSearch' [icon]='faCaretDown' class='header' (click)='toggleSearch($event)'></fa-icon>
            <fa-icon *ngIf='showSearch' [icon]='faCaretUp' class='header' (click)='toggleSearch($event)'></fa-icon>
            <div class='dev-filter-dropdown' [class.expand]='showSearch' (click)="ignoreClick($event)">
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'STATUS' | translate }}</div>
                    <div class="dev-filter-col">
                        <div class='dev-filter dev-sub-filter' (click)='toggleStatusDropDown($event)'>
                            <span>{{ deviceList.selectedStatusFilter | translate }}</span>
                            <fa-icon *ngIf='!statusVisible' [icon]='faCaretDown'></fa-icon>
                            <fa-icon *ngIf='statusVisible' [icon]='faCaretUp' ></fa-icon>
                            <div class='dev-filter-dropdown' (click)='ignoreClick($event)'
                                    [class.expand]='statusVisible' [attr.allow_wheel]="true">
                                <ul>
                                    <li (click)='applyStatusFilter($event, "")'></li>
                                    <li *ngFor='let status of DeviceStatus' (click)='applyStatusFilter($event, status)'>
                                        {{ status | translate }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'DEV_TYPE' | translate }}</div>
                    <div class="dev-filter-col">
                        <div class='dev-filter dev-sub-filter' (click)='toggleTypesDropDown($event)'>
                            <span>{{ deviceList.selectedTypeFilter | translate }}</span>
                            <fa-icon *ngIf='!typesVisible' [icon]='faCaretDown'></fa-icon>
                            <fa-icon *ngIf='typesVisible' [icon]='faCaretUp' ></fa-icon>
                            <div class='dev-filter-dropdown' (click)='ignoreClick($event)'
                                    [class.expand]='typesVisible' [attr.allow_wheel]="true">
                                <ul>
                                    <li (click)='applyTypeFilter($event, "")'></li>
                                    <li *ngFor='let type of DeviceTypes' (click)='applyTypeFilter($event, type)'>
                                        {{ type | translate }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col"></div>
                    <div class="dev-filter-col pointer" (click)='toggleOnlineOnly($event)'>
                        <div class='dev-check'>
                            <div class="square-box">
                                <input id="sub_dev_oo" type='checkbox' formControlName="online" />
                                <label for="sub_dev_oo"></label>
                            </div>
                        </div>
                        <span>{{ "ONLY_ONLINE" | translate }}</span>
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'DEV_SORDER' | translate }}</div>
                    <div class="dev-filter-col">
                        <input formControlName='so' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'so')">
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'DEV_PORDER' | translate }}</div>
                    <div class="dev-filter-col">
                        <input formControlName='po' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'po')">
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'DEVICE_NAME' | translate }}</div>
                    <div class="dev-filter-col">
                        <input formControlName='name' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'name')">
                    </div>
                </div>
                <div class="dev-filter-row">
                    <div class="dev-filter-col">{{ 'DEV_PRODUCT' | translate }}</div>
                    <div class="dev-filter-col">
                        <input formControlName='product' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'product')">
                    </div>
                </div>
            </div>
        </form>
        <div class='status-message'>
            <span>{{ message | translate }}</span>
            <div>
                <fa-icon class='pointer' [icon]="faFileCsv" (click)="deviceList.exportCSV()"></fa-icon>
                <fa-icon class='pointer' [icon]="faFilePdf" (click)="deviceList.exportPDF()"></fa-icon>
            </div>
        </div>
    </div>
    <div class='dev-head-row'>
        <div class='dev-check' (click)='toggleAllDeviceSelect($event)'>
            <div class="square-box">
                <input id="sub_dev_all" type='checkbox' [(ngModel)]="allDeviceSelect" />
                <label for="sub_dev_all"></label>
            </div>
        </div>
        <div class='dev-status dev-header pointer' (click)='toggleSortOrder($event, "status")'>
            {{ 'STATUS' | translate }}
            <fa-icon *ngIf='deviceList.sortStatusAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!deviceList.sortStatusAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='dev-type dev-header pointer' (click)='toggleSortOrder($event, "type")'>
            {{ 'DEV_TYPE' | translate }}
            <fa-icon *ngIf='deviceList.sortTypeAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!deviceList.sortTypeAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='dev-name dev-header pointer' (click)='toggleSortOrder($event, "sname")'>
            {{ 'DEVICE_NAME' | translate }}
            <fa-icon *ngIf='deviceList.sortNameAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!deviceList.sortNameAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='dev-sorder dev-header pointer' (click)='toggleSortOrder($event, "so")'>
            {{ 'DEV_SORDER' | translate }}
            <fa-icon *ngIf='deviceList.sortSOAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!deviceList.sortSOAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='dev-porder dev-header pointer' (click)='toggleSortOrder($event, "po")'>
            {{ 'DEV_PORDER' | translate }}
            <fa-icon *ngIf='deviceList.sortPOAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!deviceList.sortPOAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='dev-comm dev-header'>{{ 'DEV_ON_OFFLINE' | translate }}</div>
        <div *ngIf='manageMode == "dev"' class='dev-registration dev-header'>{{ 'DEV_REGISTRATION' | translate }}</div>
        <div *ngIf='manageMode == "lic"' class='dev-registration dev-header'>{{ 'DEV_LICENSE' | translate }}</div>
    </div>
    <virtual-scroller id='dev_list' class='dev-list' #dscroll [items]="deviceList.filteredDeviceList"
            [bufferAmount]='16' [enableUnequalChildrenSizes]='true' [stripedTable]='true'>
        <ng-container *ngFor='let device of dscroll.viewPortItems' >
            <section *ngIf='device.show' class='dev-section-row'>
                <div class='dev-row' [class.pointer]='manageMode == "lic"' (click)='toggleDeviceExpand($event, device)'>
                    <div class='dev-check' (click)='toggleDeviceSelect($event, device)'>
                        <div class="square-box">
                            <input id="sub_{{ device.sn }}" type='checkbox' [(ngModel)]="device.sel" />
                            <label for="sub_{{ device.sn }}"></label>
                        </div>
                    </div>
                    <div class='dev-status' [attr.motip]='device.stxt+"\n"+device.ip'>
                        <fa-icon *ngIf='!device.stat' class='dev-pending' [icon]='faSyncAlt'></fa-icon>
                        <fa-icon *ngIf='device.stat == 1' class='dev-activated' [icon]='faLaptop'></fa-icon>
                        <fa-icon *ngIf='device.stat == 2' class='dev-deactivated' [icon]='faLaptop'></fa-icon>
                    </div>
                    <div class='dev-type'>{{ device.type | translate }}</div>
                    <div class='dev-name'>{{ device.sn }} / {{ device.name }}</div>
                    <div class='dev-sorder'>{{ device.so }}</div>
                    <div class='dev-porder'>{{ device.po }}</div>
                    <div class='dev-comm' [class.red]='device.olst != 1'>
                        <span *ngIf='device.olst == 0'>{{ 'OFFLINE' | translate }}</span>
                        <span *ngIf='device.olst == 1'>{{ 'ONLINE' | translate }}</span>
                        <span *ngIf='device.olst == 2'>{{ 'ERROR' | translate }}</span>
                    </div>
                    <div *ngIf='manageMode == "dev"' class='dev-registration'>
                        <button *ngIf='!device.stat && device.olst && device.act'
                                (click)='deviceRegistration(device.sn, true)'
                                class='dev-button back-green' [attr.motip]="'REG_DEVICE' | translate">{{ device.act }}</button>
                        <input *ngIf='!device.stat && device.olst == 2 && !device.act'
                                [attr.autofocus]="focusedDevice && (focusedDevice.id == device.id)"
                                (keyup)="handleCodeInputKey($event, device)"
                                (click)="focusCodeInput($event, device)"
                                id="{{'dev_code_' + device.id}}" type='text' placeholder="{{ 'REG_CODE' | translate }}">
                        <fa-icon *ngIf='!device.stat && device.olst == 2 && !device.act'
                                [icon]='faCheck' [attr.motip]="'SAVE_DEV_CODE' | translate"
                                class='check pointer' (click)="registerWithCode($event, device)"></fa-icon>
                        <button *ngIf='device.stat == 1'
                                (click)='deviceRegistration(device.sn, false)'
                                class='dev-button back-blue' [attr.motip]="'UNREG_DEVICE' | translate">{{ 'UNREGISTER' | translate }}</button>
                    </div>
                    <div *ngIf='manageMode == "lic"' class='dev-license'>
                        <fa-icon *ngIf='!device.exp' [icon]='faCaretDown' (click)='toggleDeviceExpand($event, device)'></fa-icon>
                        <fa-icon *ngIf='device.exp' [icon]='faCaretUp' (click)='toggleDeviceExpand($event, device)'></fa-icon>
                        <div class='dev-license-prods' (click)='showLicenseChoices($event, device)'>
                            <ng-container *ngFor="let lic of device.lics">
                                <span class='dev-lic-product'
                                        [class.back-plum]='lic.stat == 4'
                                        [class.back-blue]='lic.stat == 3'
                                        [class.back-gold]='lic.stat == 2'
                                        [class.back-green]='lic.stat == 1'
                                        [class.back-grey]='lic.stat == 0'
                                        [class.expiring]="lic.exp <= licExpiringCutoff">
                                    {{ lic.prod }}
                                </span>
                                <span>, </span>
                            </ng-container>
                        </div>
                        <div class='dev-license-dropdown' [class.expand]='device.slo'
                                (click)="ignoreClick($event)" [attr.allow_wheel]="true">
                            <div class='dev-license-dd-row dev-license-dd-header'>
                                <div class='dev-license-dd-col1'>{{ 'AVAIL_TOTAL' | translate }}</div>
                                <div class='dev-license-dd-col2'>{{ 'DEV_PRODUCT' | translate }}</div>
                                <div class='dev-license-dd-col3'>{{ 'EXPIRES' | translate }}</div>
                            </div>
                            <div id="dev_slo_lic_{{ device.id }}" class='dev-license-dd-body'>
                                <ng-container *ngFor="let border of device.blos">
                                    <div class='dev-license-dd-row dev-license-dd-so'
                                            (click)="ignoreClick($event)">{{ border.name }}</div>
                                    <div *ngFor='let blic of border.blics' class='dev-license-dd-row pointer'
                                            (click)="addLicense($event, device, blic.id)">
                                        <div class='dev-license-dd-col1'>{{ blic.seats }}</div>
                                        <div class='dev-license-dd-col2'>{{ blic.prod }}</div>
                                        <div class='dev-license-dd-col3'>{{ blic.exp }}</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='dev-row-expanded' [attr.allow_wheel]="true"
                        [class.expanded]='device.exp' [class.stillexpanded]='refreshing'>
                    <div class='dev-left-expanded'>
                        <div>{{ device.sn }}</div>
                        <div *ngIf='!device.stat' class='dev-status wide'>
                            <fa-icon class='dev-pending' [icon]='faSyncAlt'></fa-icon> {{ 'PENDING' | translate }} (
                                {{ 'DEV_NEED_REGISTRATION' | translate }})
                        </div>
                        <div *ngIf='device.stat == 1' class='dev-status wide'>
                            <fa-icon class='dev-activated' [icon]='faLaptop'></fa-icon> {{ 'REG' | translate }}
                        </div>
                        <div *ngIf='device.stat == 2' class='dev-status wide'>
                            <fa-icon class='dev-deactivated' [icon]='faLaptop'></fa-icon> {{ 'UNREG' | translate }}
                        </div>
                        <br>
                        <div>{{ 'DEV_REQUEST_DT' | translate }}: {{ device.tidt }}</div>
                        <div>{{ 'DEV_REG_CODE' | translate }}: {{ device.act }}</div>
                        <div>{{ device.name }}</div>
                        <div>
                            {{ 'DEV_IP' | translate }}:
                            <span *ngIf='device.olst == 0'>{{ 'OFFLINE' | translate }}</span>
                            <span *ngIf='device.olst == 1'>{{ device.ip }}</span>
                            <span *ngIf='device.olst == 2'>{{ 'ERROR' | translate }}</span>
                        </div>
                        <div>{{ 'DEV_SORDER' | translate }}: {{ device.so }}</div>
                        <div>{{ 'DEV_PORDER' | translate }}: {{ device.po }}</div>
                    </div>
                    <div class='dev-lic-list' [attr.devid]='device.id'>
                        <div class='dev-lic-header'>
                            <div class='dev-lic-remove'></div>
                            <div class='dev-lic-product'>{{ 'DEV_PRODUCT' | translate }}</div>
                            <div class='dev-lic-key'>{{ 'DEV_LIC_KEY' | translate }}</div>
                            <div class='dev-lic-expire'>{{ 'DEV_EXPIRE' | translate }}</div>
                            <div class='dev-lic-order'>{{ 'DEV_SORDER' | translate }}</div>
                            <div class='dev-lic-order'>{{ 'DEV_PORDER' | translate }}</div>
                        </div>
                        <div id="dev_exp_lic_{{ device.id }}" class='dev-lic-body'>
                            <div *ngFor='let lic of device.lics' class='dev_lic_row'>
                                <div class='dev-lic-remove' [class.disabled]='lic.stat == 2'>
                                    <fa-icon [icon]='faTimes' (click)='remLicense($event, device, lic.id)'></fa-icon>
                                </div>
                                <div class='dev-lic-product'
                                        [class.back-plum]='lic.stat == 4'
                                        [class.back-blue]='lic.stat == 3'
                                        [class.back-gold]='lic.stat == 2'
                                        [class.back-green]='lic.stat == 1'
                                        [class.back-grey]='lic.stat == 0'
                                        [attr.motip]='getLicenseStateText(lic.stat) | translate'>
                                    {{ lic.prod }}
                                </div>
                                <div class='dev-lic-key' [attr.motip]="lic.key"><div>{{ lic.key }}</div></div>
                                <div class='dev-lic-expire' [class.expiring]="lic.exp <= licExpiringCutoff">{{ lic.texp }}</div>
                                <div class='dev-lic-order'>{{ lic.so }}</div>
                                <div class='dev-lic-order'>{{ lic.po }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>
    </virtual-scroller>
</div>
