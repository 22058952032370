import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { faTimes, faFileCsv, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleRight, faStopCircle } from '@fortawesome/pro-regular-svg-icons';
import html2pdf from 'html2pdf.js';

import * as Utils from '../utils';
import { DataService } from '../data.service';
import { DeviceLogTypes, LogEntry, JobLogTypes } from '../../../../server/src/web-front-end/api-interfaces';
import { ApiService } from '../api.service';
import { Constants } from '../models/constants';

interface LogLines {
    text: Array<string>;
    show: boolean;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'log-view',
    templateUrl: './log-view.component.html',
    styleUrls: ['./log-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LogViewComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription>;
    private ids: string;
    private email: string;
    private newestLogEntryId: number;
    private logTypeStrings = {};
    private viewMode: string;

    public title: string;
    public filterForm: FormGroup;
    public logLines: Array<LogLines>;
    public liveLogActive: boolean;
    public headerColumns: Array<string>;

    // instantiate icon references
    public faTimes = faTimes;
    public faFileCsv = faFileCsv;
    public faFilePdf = faFilePdf;
    public faCaretCircleRight = faCaretCircleRight;
    public faStopCircle = faStopCircle;

    constructor(
            private dataService: DataService,
            private apiService: ApiService,
            private translate: TranslateService,
            private formBuilder: FormBuilder,
            private dialogRef: MatDialogRef<LogViewComponent>,
            @Inject(MAT_DIALOG_DATA) data: any) {

        this.liveLogActive = false;
        this.newestLogEntryId = 0;
        this.subscriptions = [];
        this.filterForm = this.formBuilder.group({
            filter: [''],
            range: [''],
        });

        this.title = data.title;
        this.ids = data.ids;
        this.email = data.email;
        this.viewMode = data.viewMode;

        this.headerColumns = null;

        this.logLines = [];
        for (const line of data.message) {
            const lparts = line.split(',');
            this.logLines.push({text: lparts, show: true});
        }

        switch (this.viewMode) {
            case 'job':
                this.translate.get(JobLogTypes).toPromise().then(results => {
                    this.logTypeStrings = results;
                });
                break;
            case 'device':
                this.translate.get(DeviceLogTypes).toPromise().then(results => {
                    this.logTypeStrings = results;
                });
                break;
        }
    }

    public ngOnInit() {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.filterForm.get('filter').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    this.applyFilter(value);
            }));
            this.subscriptions.push(this.filterForm.get('range').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (Array.isArray(value) && (value.length === 2)) {
                        if (!value[1]) {
                            // default end to now if not specified
                            value[1] = new Date();
                            this.filterForm.get('range').setValue(value);
                        }
                        const startStr = (value[0] as Date).toISOString();
                        const endStr = (value[1] as Date).toISOString();
                        this.apiService.getLog(this.viewMode, this.email, this.ids, startStr, endStr).then(log => {
                            this.apiService.pleaseWait(0);

                            const lines: Array<LogLines> = [];
                            if (typeof log === 'string') {
                                this.headerColumns = null;
                                lines.push({ text: [log], show: true });
                            } else {
                                this.newestLogEntryId = 0;
                                for (const entry of log) {
                                    this.newestLogEntryId = Math.max(this.newestLogEntryId, entry.id);
                                    const line = this.formatLogLine(entry);
                                    lines.push({ show: true, text: line });
                                }
                                switch (this.viewMode) {
                                    case 'job':
                                        this.headerColumns = Constants.jobLogHeader;
                                        break;
                                    case 'device':
                                        this.headerColumns = Constants.deviceLogHeader;
                                        break;
                                }
                            }
                            this.logLines = lines;
                            if (this.logLines.length === 0) {
                                this.headerColumns = null;
                                this.translate.get('EMPTY_LOG').toPromise().then(str => {
                                    this.logLines.push({ text: [str], show: true });
                                });
                            }
                        });
                    }
            }));
            this.subscriptions.push(this.dialogRef.keydownEvents().subscribe(event => {
                if (event.key.toUpperCase() === 'ESCAPE') {
                    event.preventDefault();
                    this.close();
                }
            }));
            this.subscriptions.push(this.dataService.getLiveLogObservable().subscribe(records => {
                if (this.liveLogActive && records) {
                    if ((records.length > 0) && (this.logLines.length === 1) && (this.logLines[0].text.length === 1)) {
                        this.logLines.pop(); // remove empty log or error text
                    }
                    for (let ii = records.length - 1; ii >= 0; ii--) {
                        this.newestLogEntryId = Math.max(this.newestLogEntryId, records[ii].id);
                        const line = this.formatLogLine(records[ii]);
                        this.logLines.unshift({ show: true, text: line });
                    }
                }
            }));
        }
        // default to show last 7 days of logs
        const start = new Date();
        start.setTime(start.getTime() - 604800000); // 7 * 24 * 60 * 60 * 1000
        const end = new Date();
        end.setTime(end.getTime() + 3600000); // 60 * 60 * 1000
        this.filterForm.get('range').setValue([start, end]);
    }

    private formatLogLine(entry: LogEntry): Array<string> {
        const type = this.logTypeStrings[entry.type] || entry.type;
        const jtype = entry.jtype ? entry.jtype.split('/').pop().replace(/-/g, ' ') : '';
        let sn = entry.sn || '';
        if (entry.data && Array.isArray((entry.data as any).deviceSerialNumbers)) {
            sn = (entry.data as any).deviceSerialNumbers.join(', ');
        }
        let finishStatus = (entry.data as any).finishStatus ? (entry.data as any).finishStatus.replace(/-/g, ' ') : '';
        if ((entry.data as any).errorDescriptionInternal) {
            finishStatus += '\n' + (entry.data as any).errorDescriptionInternal;
        }
        let blocked = '';
        if (Array.isArray((entry.data as any).conflictingJobs)) {
            for (const conflict of (entry.data as any).conflictingJobs) {
                if (blocked !== '') {
                    blocked += '\n';
                }
                blocked += conflict.jobId + ': ' + conflict.jobType.split('/').pop().replace(/-/g, ' ');
            }
        }
        return [
            (new Date(entry.ts)).toLocaleString(),
            type,
            sn,
            entry.prod || jtype || '',
            (entry.data as any).who || (entry.data as any).creatorUser || '',
            (entry.data as any).from || finishStatus || '',
            (entry.data as any).deviceIp || blocked || '',
            (entry.data as any).userCode || '',
        ];
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public startLiveLog() {
        this.filterForm.get('filter').setValue(''); // clear filter
        // default to show last 2hours + tomorrows logs
        const start = new Date();
        start.setTime(start.getTime() - 7200000); // 2 * 60 * 60 * 1000
        const end = new Date();
        end.setTime(end.getTime() + 86400000); // 24 * 60 * 60 * 1000
        this.filterForm.get('range').setValue([start, end]);
        this.apiService.startLiveLogStream(this.viewMode, this.email,
                start.toISOString(), end.toISOString(), this.newestLogEntryId, this.ids);
        this.liveLogActive = true;
    }
    public stopLiveLog() {
        this.liveLogActive = false;
        this.apiService.stopLiveLogStream();
    }

    private applyFilter(filter: string) {
        const regex = RegExp(filter, 'i');
        for (const logLine of this.logLines) {
            if (!filter) {
                logLine.show = true;
            } else {
                let show = false;
                for (const part of logLine.text) {
                    if (regex.test(part)) {
                        show = true;
                        break;
                    }
                }
                logLine.show = show;
            }
        }
    }

    public exportPDF() {
        const opts = {
            margin: 1,
            filename: this.viewMode + '_log.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
        };
        const list: HTMLElement = document.getElementById('lv_list');
        html2pdf().from(list, 'element').set(opts).save();
    }

    public exportCSV() {
        const list: HTMLElement = document.getElementById('lv_list');
        const csv: Array<string> = [];
        const rows = list.querySelectorAll('tr');
        rows.forEach(row => {
            const line: Array<string> = [];
            const cols = row.querySelectorAll('td');
            cols.forEach(col => {
                line.push(col.innerText.replace(/,/g, ''));
            });
            csv.push(line.join(','));
        });
        const content = 'data:text/csv;base64,' + btoa(unescape(encodeURIComponent(csv.join('\n'))));
        Utils.downloadFromDataURL(this.viewMode + '_log.csv', content);
    }

    public close(result: string = '') {
        this.dialogRef.close(result);
    }

}

export class ViewLogDialog {
    private dialogConfig: MatDialogConfig;

    constructor() {
        this.dialogConfig = new MatDialogConfig();
        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;
        this.dialogConfig.hasBackdrop = true;
        this.dialogConfig.closeOnNavigation = false;
        this.dialogConfig.backdropClass = 'cdk-overlay-connected-position-bounding-box';
        this.dialogConfig.panelClass = 'md-dialog';
        this.dialogConfig.maxHeight = '80vh';
        this.dialogConfig.minHeight = '300px';
        this.dialogConfig.maxWidth = '80vw';
        this.dialogConfig.minWidth = '700px';
    }

    public open(matDialog: MatDialog, title: string, email: string, ids: string, viewMode: string) {
        this.dialogConfig.disableClose = true;
        this.dialogConfig.data = {
            title: title,
            message: [],
            filter: true,
            email: email,
            ids: ids,
            viewMode: viewMode,
        };
        const dialog = matDialog.open(LogViewComponent, this.dialogConfig);
    }

}
