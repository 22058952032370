import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root',
  })
export class StorageService {

    constructor(private cookieService: CookieService,
        private sessionStorage: SessionStorageService,
        private localStorage: LocalStorageService) { }

    // set methods take object which should be a map of key=>value of items to be set
    public setCookieData(obj: Object): void {
        const expires = new Date();
        expires.setTime(expires.getTime() + (18 * 60 * 60 * 1000));
        for (const key in obj) {
            if (obj[key]) {
                this.cookieService.set(key, obj[key], expires, '/', '.zspace.com', true, 'Lax');
            } else {
                this.removeCookieData([key]);
            }
        }
    }
    public getCookieData(keys: string[], defaultValue: string = ''): Observable<Object> {
        const valueMap = {};

        for (const key of keys) {
            const tmp = this.cookieService.get(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }

        return observableOf(valueMap);
    }
    public removeCookieData(keys: string[]): void {
        for (const key of keys) {
            this.cookieService.delete(key);
        }
    }
    public clearCookieData(): void {
        this.cookieService.deleteAll();
    }

    public setSessionData(obj: Object): void {
        for (const key in obj) {
            if (obj[key]) {
                this.sessionStorage.store(key, obj[key]);
            } else {
                this.removeSessionData([key]);
            }
        }
    }
    public getSessionData(keys: string[], defaultValue: string = ''): Observable<Object> {
        const valueMap = {};

        for (const key of keys) {
            const tmp = this.sessionStorage.retrieve(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }

        return observableOf(valueMap);
    }
    public removeSessionData(keys: string[]): void {
        for (const key of keys) {
            this.sessionStorage.clear(key);
        }
    }
    public clearSessionData(): void {
        this.sessionStorage.clear();
    }

    public setLocalData(obj: Object): void {
        for (const key in obj) {
            if (obj[key]) {
                this.localStorage.store(key, obj[key]);
            } else {
                this.removeLocalData([key]);
            }
        }
    }
    public getLocalData(keys: string[], defaultValue: string = ''): Observable<Object> {
        const valueMap = {};

        for (const key of keys) {
            const tmp = this.localStorage.retrieve(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }

        return observableOf(valueMap);
    }
    public removeLocalData(keys: string[]): void {
        for (const key of keys) {
            this.localStorage.clear(key);
        }
    }
    public clearLocalData(): void {
        this.localStorage.clear();
    }

}
