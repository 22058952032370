import { Component, OnInit, OnDestroy, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { faHourglassHalf } from '@fortawesome/pro-regular-svg-icons';

import { Constants } from './models/constants';
import { Language } from './models/language';
import { LanguageService } from './language.service';
import { StorageService } from './storage.service';
import { RegionService } from './region.service';
import { AuthenticationService } from './authentication/authentication.service';
import { ImageService } from './image.service';
import { DataService } from './data.service';
import { versionInfo } from './models/constants';
import { ApiService } from './api.service';
import { MessageDialog } from './message-dialog/message-dialog.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
    @ViewChild('emailBeside', { static: true })
    emailBeside: ElementRef;

    private subscriptions: Array<Subscription> = [];
    private listeners: Array<{action: string, function: any}> = [];
    private zoomScale = 1;
    private isTouch: boolean;
    private messageDialog: MessageDialog;
    private messageDialogSubscription: Subscription;

    public activeTab: string;
    public userNameFull: string;
    public userId: string;
    public availableLanguages = Constants.languages;
    public logoImage: SafeUrl;
    public imagePathCDN: string;
    public languagesVisible: boolean;
    public selectedLanguage: Language;
    public showEmailAbove: boolean;
    public ipAddress: string;
    public showViewEula: boolean;

    // instantiate icon references
    public faCaretDown = faCaretDown;
    public faSignOut = faSignOut;
    public faHourglassHalf = faHourglassHalf;

    public versionInfo = versionInfo;
    public Constants = Constants;

    constructor(
            private translate: TranslateService,
            private regionService: RegionService,
            private router: Router,
            private languageService: LanguageService,
            private storageService: StorageService,
            private authenticationService: AuthenticationService,
            private imageService: ImageService,
            private matDialog: MatDialog,
            private apiService: ApiService,
            private dataService: DataService,
        ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang(window.navigator.language || 'en-US');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use(window.navigator.language || 'en-US');
        this.selectedLanguage = this.availableLanguages[0];

        this.logoImage = this.imageService.getSafeUrl(this.imageService.getIconByContentType('logo'));
        this.userNameFull = '';
        this.userId = '';
        this.imagePathCDN = '';
        this.languagesVisible = false;
        this.showEmailAbove = false;
        this.ipAddress = '';
        this.showViewEula = false;
        this.messageDialog = new MessageDialog();
    }

    public ngOnInit(): void {
        this.isTouch = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0);

        this.apiService.getConfig().then(config => {
            if (config) {
                this.ipAddress = config.ip;
            }
        });

        this.storageService.getLocalData(['language']).toPromise().then(vals => {
            let languageSet = false;
            if (vals['language']) {
                for (let ii = 0; ii < this.availableLanguages.length; ii++) {
                    if (this.availableLanguages[ii].languageCode === vals['language'].languageCode) {
                        this.selectedLanguage = this.availableLanguages[ii];
                        this.languageService.languageChanged(this.availableLanguages[ii].languageCode);
                        languageSet = true;
                        break;
                    }
                }
            }
            if (!languageSet) {
                // get locale from browser
                const lang = navigator.language.toLocaleLowerCase().substr(0, 2);
                let langCode = this.availableLanguages[0].languageCode; // default english
                let saveLanguage = this.availableLanguages[0];
                switch (lang) {
                    case 'zh':
                        // any version of chinese
                        langCode = this.availableLanguages[1].languageCode;
                        saveLanguage = this.availableLanguages[1];
                        break;
                    case 'es':
                        // spanish
                        langCode = this.availableLanguages[2].languageCode;
                        saveLanguage = this.availableLanguages[2];
                        break;
                    case 'en':
                    default:
                        langCode = this.availableLanguages[0].languageCode;
                        saveLanguage = this.availableLanguages[0];
                        break;
                }
                this.translate.use(langCode);
                this.languageService.languageChanged(langCode);
                this.selectedLanguage = saveLanguage;
                this.storageService.setLocalData({language: saveLanguage});
            }
        }).catch(error => {
            // console.error(error);
        });
        this.storageService.getSessionData([ 'selectedCustomer' ]).toPromise().then(values => {
            if (values['selectedCustomer']) {
                this.dataService.updateSelectedCustomer(values['selectedCustomer']);
            }
        });

        this.setupSubscriptions();
        this.addListeners();
        this.authenticationService.getUserInfo(false);
    }

    public ngAfterContentInit() {
        if (this.isTouch) {
            this.zoomScale = Math.min(screen.availWidth / document.body.scrollWidth, screen.availHeight / document.body.scrollHeight);
            if (this.zoomScale < 1) {
                this.zoomScale *= 0.85;
            } else {
                this.zoomScale = 1;
            }
            this.pinch(null);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.removeListeners();
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
        }
    }

    private addListeners() {
        if (this.listeners.length === 0) {
            this.listeners.push({action: 'click', function: this.handleGeneralClick});
            window.addEventListener('click', this.handleGeneralClick.bind(this));

            this.listeners.push({action: 'keydown', function: this.handleKey});
            window.addEventListener('keydown', this.handleKey.bind(this));
        }
    }
    private removeListeners() {
        this.listeners.forEach(listener => {
            window.removeEventListener(listener.action, listener.function.bind(this));
        });
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.router.events.subscribe(event => {
                this.regionService.sendPageView(event);
            }));

            this.subscriptions.push(this.router.events.subscribe(event => {
                this.navEnd(event);
            }));
            this.subscriptions.push(this.languageService.getLanguageSubscription().subscribe(language => {
                this.translate.use(language);
                this.translate.get('CLIENT_TITLE').toPromise().then(text => {
                    const titles = document.getElementsByTagName('TITLE');
                    titles.item(0).setAttribute('innerHTML', text);
                    document.title = text;
                });

                for (let ii = 0; ii < this.availableLanguages.length; ii++) {
                    if (this.availableLanguages[ii].languageCode === language) {
                        this.selectedLanguage = this.availableLanguages[ii];
                        break;
                    }
                }
            }));
            this.subscriptions.push(this.authenticationService.getLoginObservable().subscribe(login => {
                if (login && login.res && (login.name !== '') && (login.id !== '')) {
                    if (login.cc !== 0) {
                        if (this.router.url === '/') {
                            this.router.navigateByUrl('/customers');
                        } else {
                            //  this may come after customer set if reload
                            this.apiService.needEulaAcceptance(this.authenticationService.getUserEmail()).then(need => {
                                this.showViewEula = need;
                            });
                        }
                    } else {
                        if (this.router.url !== '/licenses') {
                            this.router.navigateByUrl('/licenses');
                        }
                    }
                }
            }));
            this.subscriptions.push(this.dataService.getSelectedCustomerObservable().subscribe(customer => {
                this.apiService.needEulaAcceptance(this.authenticationService.getUserEmail()).then(need => {
                    this.showViewEula = need;
                });
            }));
        }
    }

    public logout() {
        // clear all session data
        if (this.storageService) {
            this.storageService.setSessionData({ currentPage: '' });
            this.storageService.setSessionData({ customerSearch: '' });
            this.storageService.setSessionData({ customerNameSearch: '' });
            this.storageService.setSessionData({ customerEmailSearch: '' });
            this.storageService.setSessionData({ customerOrgSearch: '' });
        }
        this.authenticationService.logout().then(resp => {
                location.href = '/';
            }).catch(err => {
            });
    }

    private handleKey(event: any) {
        if (event.key && (event.target.tagName !== 'INPUT')) {
            switch (event.key.toUpperCase()) {
                case 'ESCAPE':
                    event.preventDefault();
                    this.dataService.setAction('esc');
                    break;
                default:
                    break;
            }
        }
    }

    public navEnd(event: any): void {
        if (event instanceof NavigationEnd) {
            if ((this.router.url === '/devices') && !this.dataService.getSelectedCustomer()) {
                this.router.navigateByUrl('/customers');
            }
            this.storageService.getCookieData(['dmpuser']).toPromise().then(vals => {
                // if dmpuser cookie was cleared on nav then session timed out so go to login
                this.userNameFull = vals['dmpuser'] ? vals['dmpuser'] : '';
                if (!this.userNameFull && (this.router.url !== '/')) {
                    location.href = '/';
                }
            });
        }
    }

    private handleGeneralClick(event: any) {
        if (!event.target || !event.target.className || (typeof event.target.className !== 'string')
            || (event.target.className.indexOf('drop-down-header') < 0)) {
            // if target has allowclick attribute set then don't set action
            if (!event.target.getAttribute('allowclick')) {
                this.dataService.setAction('click');
            }
            this.languagesVisible = false;
        }
    }

    public toggleLanguageDropDown(event: any): boolean {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.languagesVisible = !this.languagesVisible;
        return false;
    }

    public selectLanguage(language: Language) {
        this.languagesVisible = false;
        this.selectedLanguage = language;
        this.languageService.languageChanged(language.languageCode);
        this.storageService.setLocalData({ language: language });
    }

    public pan(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            let newTop = window.scrollY;
            let newLeft = window.scrollX;
            newTop = newTop - (event.deltaY / 8);
            newLeft = newLeft - (event.deltaX / 8);
            window.scrollTo(newLeft, newTop);
        }
    }

    public pinch(event: any) {
        const scale: number = (event ? this.zoomScale * event.scale : this.zoomScale);
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        document.body.style.transform = 'scale(' + scale + ',' + scale + ')';
        document.body.style.transformOrigin = '0 0';
        let width: string = document.body.scrollWidth + 'px';
        let height: string = document.body.scrollWidth + 'px';
        if (scale < 1) {
            width = Math.floor(screen.availWidth / scale) + 'px';
            height = Math.floor(screen.availHeight / scale) + 'px';
        } else {
            height = Math.floor(document.getElementsByTagName('html')[0].clientHeight * scale) + 'px';
            width = 'auto';
        }
        document.body.style.minWidth = width;
        document.body.style.minHeight = height;
        if (event && (event.type === 'pinchend')) {
            this.zoomScale = scale;
        }
    }

    public viewEula(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
            this.messageDialogSubscription = null;
        }
        this.apiService.pleaseWait(20);
        this.apiService.getEulaText().then(textArr => {
            if (textArr.length > 0) {
                this.messageDialogSubscription = this.messageDialog.confirmation.asObservable().subscribe(result => {
                    if (this.messageDialogSubscription) {
                        this.messageDialogSubscription.unsubscribe();
                        this.messageDialogSubscription = null;
                    }
                    if (result) {
                        // accept EULA
                        this.apiService.pleaseWait(20);
                        this.apiService.acceptEula().then(success => {
                            if (success) {
                                // recheck if need eula acceptance just to be sure
                                this.apiService.needEulaAcceptance(this.authenticationService.getUserEmail()).then(need => {
                                    this.showViewEula = need;
                                    this.apiService.pleaseWait(0);
                                });
                            } else {
                                this.apiService.pleaseWait(0);
                            }
                        });
                    }
                });
                this.apiService.pleaseWait(0);
                this.messageDialog.open(this.matDialog, 'EULA', textArr,
                    { confirm: true, requireEnd: true, labelYes: 'ACCEPT', labelNo: 'DECLINE' });
            } else {
                this.apiService.pleaseWait(0);
                this.messageDialog.open(this.matDialog, 'EULA', ['NO_RESPONSE']);
            }
        });
    }
}
