<div (keydown)="handleKey($event)">
    <div class='auth-text' [innerHTML]="'WELCOME' | translate"></div>
    <div class='auth-dialog narrow' [class.waiting]='wait'>
        <div class='auth-banner'>
            <img class='auth-logo' [src]='logoImage' />
        </div>
        <div class='auth-body'>
            <div class='auth-body-right full-center'>
                <div class='auth-form'>
                    <p>{{ 'EMAIL_PASSWORD' | translate }}</p>
                    <input id='auth_email' autocomplete='on' type='email' [placeholder]="'ENTER_EMAIL' | translate" required />
                    <input id='auth_password' autocomplete='new-password' type='password' [placeholder]="'ENTER_PASSWORD' | translate" required />
                    <button (click)='submitLogin($event)' class="social-button login" [class.waiting]='wait'>
                        {{ 'SUBMIT_LOGIN' | translate }}
                    </button>
                </div>
                <div id='auth_error' class='auth-error' [class.lower]='!showSocialLogins'>{{ errorMessage | translate }}</div>
            </div>
        </div>
    </div>
</div>
