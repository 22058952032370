import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faSortUp, faSortDown, faFileCsv, faFilePdf } from '@fortawesome/pro-light-svg-icons';

import { MessageDialog } from '../message-dialog/message-dialog.component';
import { ViewLogDialog } from '../log-view/log-view.component';
import { DataService } from '../data.service';
import { ApiService } from '../api.service';
import { StorageService } from '../storage.service';
import { Customer, Job } from '../../../../server/src/web-front-end/api-interfaces';
import { JobList } from '../models/job-list';

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class JobsComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription>;
    private messageDialog: MessageDialog;
    private viewLogDialog: ViewLogDialog;
    private messageDialogSubscription: Subscription;
    private devicesTimeout: number;

    public selectedCustomer: Customer;
    public jobList: JobList;
    public message: string;
    public filterJobForm: FormGroup;
    public allJobSelect: boolean;
    public showSearch: boolean;
    public refreshing: boolean;

    // instantiate icon references
    public faCaretDown = faCaretDown;
    public faCaretUp = faCaretUp;
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faFileCsv = faFileCsv;
    public faFilePdf = faFilePdf;

    constructor(
            private router: Router,
            private dataService: DataService,
            private apiService: ApiService,
            private formBuilder: FormBuilder,
            private matDialog: MatDialog,
            private storageService: StorageService,
        ) {
        this.subscriptions = [];
        this.messageDialog = new MessageDialog();
        this.viewLogDialog = new ViewLogDialog();

        this.jobList = new JobList(this.dataService, matDialog, this.messageDialog);

        this.message = '';
        this.allJobSelect = false;
        this.showSearch = false;
        this.devicesTimeout = 0;
        this.refreshing = false;
    }

    ngOnInit() {
        this.filterJobForm = this.formBuilder.group({
            filter: '',
            sn: '',
            status: ''
        });
        this.setupSubscriptions();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
        }
        this.jobList.destroy();
        if (this.devicesTimeout) {
            window.clearTimeout(this.devicesTimeout);
            this.devicesTimeout = 0;
        }
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.filterJobForm.get('filter').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.jobList.applySNFilter('');
                        this.jobList.applyStatusFilter('');
                    }
                    this.jobList.applyFilter(value);
                    this.allJobSelect = false;
            }));
            this.subscriptions.push(this.filterJobForm.get('sn').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterJobForm.get('filter').setValue('');
                    }
                    this.jobList.applySNFilter(value);
                    this.allJobSelect = false;
            }));
            this.subscriptions.push(this.filterJobForm.get('status').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterJobForm.get('filter').setValue('');
                    }
                    this.jobList.applyStatusFilter(value);
                    this.allJobSelect = false;
            }));
            this.subscriptions.push(this.jobList.getUpdatedObservable().subscribe(state => {
                if (state) {
                }
            }));
            this.subscriptions.push(this.dataService.getSelectedCustomerObservable().subscribe(customer => {
                this.message = '';
                if (customer) {
                    this.selectedCustomer = customer;
                    this.refreshJobs(false);
                }
            }));
            this.subscriptions.push(this.dataService.getActionObservable().subscribe(action => {
                this.handleAction(action);
            }));
        }
    }

    private refreshJobs(refresh: boolean = true) {
        this.refreshing = refresh;
        if (this.selectedCustomer) {
            this.apiService.getJobs(this.selectedCustomer.email, refresh).then(msg => {
                if (!refresh) {
                    this.apiService.pleaseWait(0);
                }
                this.message = msg;
            }).catch(err => {
                this.apiService.pleaseWait(0);
                this.message = err;
            });
        }
        if (this.devicesTimeout) {
            window.clearTimeout(this.devicesTimeout);
        }
        this.devicesTimeout = window.setTimeout(this.refreshJobs.bind(this), 10000);
    }

    public goDevices(event: any) {
        if (typeof event === 'string') {
            this.storageService.setSessionData({ manageMode: event });
            this.router.navigateByUrl('/devices');
        }
    }

    private handleAction(action: string) {
        switch (action) {
            case 'click':
                this.closeDropDowns();
                this.message = '';
                break;
            case 'escape':
                this.closeDropDowns();
                this.message = '';
                break;
            default:
                break;
        }
    }

    private closeDropDowns() {
        this.showSearch = false;
    }

    public viewLog(str: string) {
        const selected = this.jobList.getSelected();
        const ids: Array<string> = [];
        for (const job of selected) {
            ids.push(job.id.toString());
        }

        if (ids.length > 0) {
            this.viewLogDialog.open(this.matDialog, 'VIEW_JOB_LOG', this.selectedCustomer.email, ids.join(','), 'job');
        } else {
            this.apiService.pleaseWait(0);
            this.message = 'NO_JOBS_SELECTED';
        }
    }

    public ignoreClick(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    public fixClearInput(event: MouseEvent, ele: string) {
        if (event && (ele !== '')) {
            const targ = event.target;
            const rect = (targ as HTMLElement).getBoundingClientRect();
            if (event.x >= (rect.right - 20)) {
                this.filterJobForm.get(ele).setValue('');
            }
        }
    }

    public toggleJobSelect(event: any, selJob: Job) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.jobList.toggleSelect(selJob);
        this.allJobSelect = false;
    }

    public toggleAllJobSelect(event: any) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.allJobSelect = !this.allJobSelect;
        this.jobList.toggleAllSelect(this.allJobSelect);
    }

    public toggleSortOrder(event: any, which: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        // which = 'status', 'name', etc...
        this.jobList.toggleSortOrder(which);
    }

    public toggleSearch(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showSearch = !this.showSearch;
    }

}
