<div class='lv-title'>
    <span>{{ title | translate }}</span>
    <div class='pull-right'>
        <fa-icon class='clickable' [icon]="faFileCsv" (click)="exportCSV()"></fa-icon>
        <fa-icon class='clickable' [icon]="faFilePdf" (click)="exportPDF()"></fa-icon>
        <fa-icon class='clickable' [icon]="faTimes" (click)="close()"></fa-icon>
    </div>
</div>
<form class='lv-filter' [formGroup]='filterForm'>
    <input formControlName='filter' placeholder="{{ 'FILTER_TEXT' | translate }}"
        [class.disabled]="liveLogActive" type='text' value=''>
    <input formControlName='range' class='date' placeholder="{{ 'DATE_RANGE_RETRIEVE' | translate }}"
        [class.disabled]="liveLogActive"
        [selectMode]="'range'" [owlDateTime]="daterange" [owlDateTimeTrigger]="daterange">
    <owl-date-time #daterange [panelClass]="'lv-dtpicker'"></owl-date-time>
    <fa-icon *ngIf='!liveLogActive' class='clickable' [icon]="faCaretCircleRight"
        (click)="startLiveLog()" [attr.motipl]='"LIVE_LOG" | translate'></fa-icon>
    <fa-icon *ngIf='liveLogActive' class='clickable' [icon]="faStopCircle"
        (click)="stopLiveLog()" [attr.motipl]='"NORM_LOG" | translate'></fa-icon>
</form>
<div class='lv-body'>
    <table id='lv_list' class='lv-list' style="color: black">
        <ng-container *ngIf='headerColumns'>
            <tr class='lv-line'>
                <th *ngFor='let col of headerColumns'>{{ col }}</th>
            </tr>
        </ng-container>
        <ng-container *ngFor="let line of logLines">
            <tr *ngIf='line.show' class='lv-line'>
                <td *ngFor='let col of line.text'>{{ col }}</td>
            </tr>
        </ng-container>
    </table>
</div>
