import { PipeTransform } from '@angular/core';
import { versionInfo } from './models/constants';
import * as i0 from "@angular/core";
export class RegionService {
    constructor() {
        this.usRegionStrings = {
            'ZSPACE_URL': 'https://zspace.com',
            'LOGIN': 'LOGIN',
            'LOGIN_TAB_CONTACT': '',
            'LOGIN_TAB_FEEDBACK': '<a href=\'https://support.zspace.com/hc/en-us/requests/new\' '
                + 'rel=\'noopener\' target=\'_blank\'>Support Request Form</a>',
            'FEEDBACK_URL': '',
            'HOW_TO': '<a href=\'https://support.zspace.com/hc/en-us/articles/205703499\''
                + 'rel=\'noopener\' target=\'_blank\'>Click Here</a>',
            'DOWNLOADS': '<a href=\'https://zspace.com/downloads\''
                + 'rel=\'noopener\' target=\'_blank\'>https://zspace.com/downloads</a>',
        };
        this.chinaRegionStrings = {
            'ZSPACE_URL': 'https://zspace.asia',
            'LOGIN': '',
            'LOGIN_TAB_CONTACT': '',
            'LOGIN_TAB_FEEDBACK': '<a href=\'https://support.zspace.com/hc/en-us/requests/new\' '
                + 'rel=\'noopener\' target=\'_blank\'>Support Request Form</a>',
            'FEEDBACK_URL': '',
            'HOW_TO': 'a href=\'https://support.zspace.com/hc/en-us/articles/205703499\''
                + 'rel=\'noopener\' target=\'_blank\'>Click Here</a>',
            'DOWNLOADS': '<a href=\'https://zspace.com/downloads\''
                + 'rel=\'noopener\' target=\'_blank\'>https://zspace.com/downloads</a>',
        };
        this.region = 'US';
        // have to initialize these here to get version included
        this.usRegionStrings['LOGIN_TAB_CONTACT'] = '<a href=\'mailto:support@zspace.com?subject=DMP version '
            + versionInfo.packageVersion + '\' rel=\'noopener\' target=\'_blank\'>support@zspace.com</a>';
        this.chinaRegionStrings['LOGIN_TAB_CONTACT'] = '<a href=\'mailto:support@zspace.com?subject=DMP version '
            + versionInfo.packageVersion + '\' rel=\'noopener\' target=\'_blank\'>support@zspace.com</a>';
        this.usRegionStrings['FEEDBACK_URL'] = 'https://support.zspace.com/hc/en-us/requests/new?subject=DMP version '
            + versionInfo.packageVersion;
        this.chinaRegionStrings['FEEDBACK_URL'] = 'https://support.zspace.com/hc/en-us/requests/new?subject=DMP version '
            + versionInfo.packageVersion;
    }
    setRegion(region) {
        this.region = region;
        if (this.region === 'CN') {
            window._hmt = window._hmt || [];
            window._hmt.push(['_setAccount', '63627248e79d9f0d97fd22a0fedb8c80']);
            window._hmt.push(['_setAutoPageview', false]);
            window._hmt.push(['_setPageTag', '6436', 'zCentral']);
            (function (i, s, o, g, r, a, m) {
                i['BaiduAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }, i[r].l = 1 * Date.now();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 0;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(window, document, 'script', 'https://hm.baidu.com/hm.js?a7a6c0ce683dad4449cfa3b873284d1b', 'ba');
        }
        if (this.region !== 'CN') {
            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }, i[r].l = 1 * Date.now();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 0;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            window.ga('create', 'UA-27264310-1', 'auto'); // add your tracking ID here.
            window.ga('set', 'page', window.location.href);
            window.ga('send', 'pageview');
        }
        (function (i, s, o, g, r, a, m) {
            i['CrazyEggObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * Date.now();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//script.crazyegg.com/pages/scripts/0028/0083.js', 'cegg');
    }
    sendPageView(event) {
        if ((this.region === 'CN') && event.routerEvent) {
            window._hmt.push(['_setPageTag', '6436', 'zCentral']);
            window._hmt.push(['_trackPageview', event.routerEvent.urlAfterRedirects]);
        }
        if (event.routerEvent) {
            if (window.ga) {
                window.ga('set', 'page', event.urlAfterRedirects);
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'page',
                    eventAction: 'view',
                    eventLabel: event.urlAfterRedirects
                });
            }
        }
        else if (typeof event === 'string') {
            if (this.region === 'CN') {
                window._hmt.push(['_setPageTag', '6436', 'zCentral']);
                window._hmt.push(['_trackPageview', event]);
            }
            if (window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'page',
                    eventAction: 'view',
                    eventLabel: event
                });
            }
        }
    }
    sendLaunchEvent(name) {
        if (this.region === 'CN') {
            window._hmt.push(['_trackEvent', 'ContentItem', 'launch', name]);
        }
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'item',
                eventAction: 'launch',
                eventLabel: name
            });
        }
    }
    sendFeedbackEvent(name) {
        if (this.region === 'CN') {
            window._hmt.push(['_trackEvent', 'ContentItem', 'feedback', name]);
        }
        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'item',
                eventAction: 'feedback',
                eventLabel: name
            });
        }
    }
    allowLogin() {
        return (this.region !== 'CN');
    }
    getRegionName() {
        let name = '';
        switch (this.region) {
            case 'CN':
                name = 'china';
                break;
            case 'US':
            default:
                name = 'world';
                break;
        }
        return name;
    }
    lookupKey(key) {
        let str = key; // default to key if not found
        switch (this.region) {
            case 'CN':
                if (this.chinaRegionStrings.hasOwnProperty(key)) {
                    str = this.chinaRegionStrings[key];
                }
                break;
            case 'US':
            default:
                if (this.usRegionStrings.hasOwnProperty(key)) {
                    str = this.usRegionStrings[key];
                }
                break;
        }
        return str;
    }
}
RegionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegionService_Factory() { return new RegionService(); }, token: RegionService, providedIn: "root" });
export class RegionStringPipe {
    constructor(regionService) {
        this.regionService = regionService;
    }
    transform(str) {
        return this.regionService.lookupKey(str);
    }
}
