import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt, faPlus, faMinus, faTimes,
        faSortUp, faSortDown, faFileCsv, faFilePdf, faLaptop } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { Device, Customer, LicenseOrder, License, DeviceTypes,
        DeviceStatus, DeviceComm, BriefLicenseOrder, DeviceChange } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';
import { DeviceList } from '../models/device-list';
import { LicenseOrderList } from '../models/license-order-list';
import { StorageService } from '../storage.service';
import { ViewLogDialog } from '../log-view/log-view.component';
import { ApiService } from '../api.service';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DevicesComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription>;
    private messageDialog: MessageDialog;
    private viewLogDialog: ViewLogDialog;
    private messageDialogSubscription: Subscription;
    private devicesTimeout: number;
    private refocusCodeInputInterval: number;

    public selectedCustomer: Customer;
    public deviceList: DeviceList;
    public licenseOrderList: LicenseOrderList;
    public message: string;
    public filterDevForm: FormGroup;
    public manageMode: string;
    public allDeviceSelect: boolean;
    public draggable: any;
    public licExpiringCutoff: number;
    public showSearch: boolean;
    public onlineOnly: boolean;
    public statusVisible: boolean;
    public typesVisible: boolean;
    public showAddDeviceId: string;
    public briefLicenseOrders: Array<BriefLicenseOrder>;
    public focusedDevice: Device;
    public refreshing: boolean;

    // instantiate icon references
    public faCaretDown = faCaretDown;
    public faCaretUp = faCaretUp;
    public faSyncAlt = faSyncAlt;
    public faCheck = faCheck;
    public faPlus = faPlus;
    public faMinus = faMinus;
    public faTimes = faTimes;
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faFileCsv = faFileCsv;
    public faFilePdf = faFilePdf;
    public faLaptop = faLaptop;

    // make constants avail to html file
    public DeviceTypes = DeviceTypes;
    public DeviceStatus = DeviceStatus;

    constructor(
                private dataService: DataService,
                private apiService: ApiService,
                private formBuilder: FormBuilder,
                private matDialog: MatDialog,
                private storageService: StorageService,
            ) {
        this.subscriptions = [];
        this.focusedDevice = null;
        this.message = '';
        this.manageMode = 'dev';
        this.allDeviceSelect = false;
        this.showSearch = false;
        this.onlineOnly = false;
        this.statusVisible = false;
        this.typesVisible = false;
        this.showAddDeviceId = '';
        this.briefLicenseOrders = [];
        this.devicesTimeout = 0;
        this.refreshing = false;

        this.licExpiringCutoff = Date.now() + (60 * 86400000); // 60 days.

        this.messageDialog = new MessageDialog();
        this.viewLogDialog = new ViewLogDialog();
        this.licenseOrderList = new LicenseOrderList(this.dataService, matDialog, this.messageDialog);
        this.deviceList = new DeviceList(this.dataService, matDialog, this.messageDialog);
    }

    ngOnInit() {
        this.filterDevForm = this.formBuilder.group({
            filter: '',
            so: '',
            po: '',
            name: '',
            product: '',
            online: false
        });
        this.storageService.getSessionData([ 'manageMode' ]).toPromise().then(values => {
            if (values['manageMode']) {
                this.setManageMode(values['manageMode'], false);
            }
        });
        this.setupSubscriptions();
        this.refocusCodeInputInterval = window.setInterval(this.refocusCodeInput.bind(this), 250);
    }

    public ngOnDestroy() {
        if (this.refocusCodeInputInterval) {
            window.clearInterval(this.refocusCodeInputInterval);
        }
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
        }
        this.deviceList.toggleExpand(null);
        this.deviceList.destroy();
        if (this.devicesTimeout) {
            window.clearTimeout(this.devicesTimeout);
            this.devicesTimeout = 0;
        }
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.filterDevForm.get('filter').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('name').setValue('');
                        this.filterDevForm.get('so').setValue('');
                        this.filterDevForm.get('po').setValue('');
                        this.filterDevForm.get('product').setValue('');
                        this.deviceList.applyStatusFilter('');
                        this.deviceList.applyTypeFilter('');
                    }
                    this.deviceList.applyFilter(value);
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.filterDevForm.get('online').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('filter').setValue('');
                    }
                    this.deviceList.applyCommFilter(value ? DeviceComm[1] : '');
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.filterDevForm.get('name').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('filter').setValue('');
                    }
                    this.deviceList.applyNameFilter(value);
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.filterDevForm.get('so').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('filter').setValue('');
                    }
                    this.deviceList.applySOFilter(value);
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.filterDevForm.get('po').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('filter').setValue('');
                    }
                    this.deviceList.applyPOFilter(value);
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.filterDevForm.get('product').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterDevForm.get('filter').setValue('');
                    }
                    this.deviceList.applyProductFilter(value);
                    this.allDeviceSelect = false;
            }));
            this.subscriptions.push(this.deviceList.getUpdatedObservable().subscribe(state => {
                if (state) {
                }
            }));
            this.subscriptions.push(this.dataService.getSelectedCustomerObservable().subscribe(customer => {
                this.message = '';
                if (customer) {
                    this.selectedCustomer = customer;
                    this.apiService.getLicenseOrders(this.selectedCustomer.email).then(msg => {
                        this.apiService.pleaseWait(0);
                        this.message = msg;
                    }).catch(err => {
                        this.apiService.pleaseWait(0);
                        this.message = err;
                    });
                    this.refreshDevices(false);
                }
            }));
            this.subscriptions.push(this.dataService.getActionObservable().subscribe(action => {
                this.handleAction(action);
            }));
        }
    }

    private refreshDevices(refresh: boolean = true) {
        this.refreshing = refresh;
        if (this.selectedCustomer) {
            this.apiService.getDevices(this.selectedCustomer.email, refresh).then(msg => {
                this.message = msg;
            }).catch(err => {
                this.message = err;
            });
        }
        if (this.devicesTimeout) {
            window.clearTimeout(this.devicesTimeout);
        }
        this.devicesTimeout = window.setTimeout(this.refreshDevices.bind(this), 6000);
    }

    private handleAction(action: string) {
        switch (action) {
            case 'click':
                this.closeDropDowns();
                this.message = '';
                break;
            case 'escape':
                this.closeDropDowns();
                this.message = '';
                break;
            default:
                break;
        }
    }

    public canDeactivate(showMessage: boolean = true): boolean {
        if (this.deviceList.changesPending > 0) {
            if (showMessage) {
                return window.confirm('You have unsaved changes. Discard?');
            }
            return false;
        }
        return true;
    }

    @HostListener('window:beforeunload', ['$event'])
    public unloadCheck($event: any) {
        if (!this.canDeactivate(false)) {
            $event.preventDefault();
            $event.returnValue = false;
        }
    }

    public fixClearInput(event: MouseEvent, ele: string) {
        if (event && (ele !== '')) {
            const targ = event.target;
            const rect = (targ as HTMLElement).getBoundingClientRect();
            if (event.x >= (rect.right - 20)) {
                this.filterDevForm.get(ele).setValue('');
            }
        }
    }

    public ignoreClick(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private formatChangeLine(change: DeviceChange): string {
        let line = '';
        const devices = this.deviceList.getOldNewById(change.did);
        let license: License;
        let action: string;
        if (change.act === 'del') { // delete must use old device as may not be in new
            license = devices[0].lics.find(lic => {
                return lic.id === change.lid;
            });
            action = 'REMOVE';
        } else { // add must use new device as won't be in old
            license = devices[1].lics.find(lic => {
                return lic.id === change.lid;
            });
            action = 'ADD';
        }
        if (devices[0] && license) {
            line = '    ' + devices[0].sn + ' ' + action + ' ' + license.prod.replace(/,/g, ';');
        }
        return line;
    }

    public focusCodeInput(event: any, device: Device) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            this.closeDropDowns(); // this calls focusCodeInput again with null event
        }
        this.focusedDevice = device;
    }

    public refocusCodeInput() {
        if (this.focusedDevice) {
            const apd = document.querySelector('app-devices');
            if (apd) {
                const ele: HTMLInputElement = apd.shadowRoot.querySelector('#dev_code_' + this.focusedDevice.id);
                if (ele) {
                    ele.focus();
                }
            }
        }
    }

    public handleCodeInputKey(event: any, device: Device) {
        const targ = event.target;
        if (targ) {
            device.codeinput = targ.value;
        }
    }

    public saveChanges(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const changes = this.deviceList.getLicenseChanges();
        if (changes) { // do nothing if no changes
            this.apiService.saveDeviceLicenseChanges(this.selectedCustomer.email, changes).then(msg => {
                if (msg) {
                    if (typeof msg === 'string') {
                        this.message = msg;
                    } else if (msg.okay || msg.fail) {
                        const messageLines: Array<string> = [];
                        if (msg.fail && (msg.fail.length > 0)) {
                            messageLines.push('MOD_SAVE_FAIL');
                            for (const fail of msg.fail) {
                                messageLines.push(this.formatChangeLine(fail));
                            }
                        } else {
                            // save successfull clear changes pending
                            this.deviceList.changesPending = 0;
                        }
                        if (msg.okay && (msg.okay.length > 0)) {
                            if (messageLines.length > 0) {
                                messageLines.push(' ');
                            }
                            messageLines.push('MOD_SAVE_OKAY');
                            for (const okay of msg.okay) {
                                messageLines.push(this.formatChangeLine(okay));
                            }
                        }
                        if (messageLines.length > 0) {
                            this.messageDialog.open(this.matDialog, 'DEV_SAVE_LIC', messageLines);
                        }
                    }
                }
            });
        }
    }

    public applyChanges(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const devices: Array<Device> = this.deviceList.getSelected();
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
            this.messageDialogSubscription = null;
        }
        if (devices.length > 0) {
            const confirmList: Array<string> = [];
            const unregisteredList: Array<string> = [];
            const serialNumbersAndCode: Array<string> = [];
            devices.forEach(device => {
                confirmList.push(device.sn + ' / ' + device.name);
                serialNumbersAndCode.push(device.sn + ':' + device.id);

                if (device.stat !== 1) {
                    unregisteredList.push(device.sn + ' / ' + device.name);
                }
            });
            if (unregisteredList.length > 0) {
                // error out if any device is not registered
                unregisteredList.unshift('MOD_APPLY_UNREG');
                this.messageDialog.open(this.matDialog, 'DEV_APPLY_LIC', unregisteredList);
            } else {
                this.messageDialogSubscription = this.messageDialog.confirmation.asObservable().subscribe(result => {
                    if (this.messageDialogSubscription) {
                        this.messageDialogSubscription.unsubscribe();
                        this.messageDialogSubscription = null;
                    }
                    if (result) {
                        this.apiService.applyDeviceLicenses(this.selectedCustomer.email, serialNumbersAndCode).then(msg => {
                            if (msg.length > 1) {
                                this.messageDialog.open(this.matDialog, 'DEV_APPLY_LIC', msg);
                            } else {
                                this.message = msg[0];
                            }
                        });
                    }
                });
                this.messageDialog.open(this.matDialog, 'DEV_APPLY_LIC', confirmList, { confirm: true });
            }
        }
    }

    public toggleSortOrder(event: any, which: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.deviceList.toggleSortOrder(which);
    }

    public toggleStatusDropDown(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.statusVisible = !this.statusVisible;
    }

    public toggleTypesDropDown(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.typesVisible = !this.typesVisible;
    }

    public applyStatusFilter(event: any, status: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (status) {
            this.filterDevForm.get('filter').setValue('');
        }
        this.deviceList.applyStatusFilter(status);
        this.statusVisible = false;
    }

    public applyTypeFilter(event: any, type: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (type) {
            this.filterDevForm.get('filter').setValue('');
        }
        this.deviceList.applyTypeFilter(type);
        this.typesVisible = false;
    }

    public toggleOnlineOnly(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const online = (this.filterDevForm.get('online').value ? false : true);
        this.filterDevForm.patchValue({ online: online });
    }

    public clearFilters() {
        this.message = '';
        this.filterDevForm.get('so').setValue('');
        this.filterDevForm.get('po').setValue('');
        this.filterDevForm.get('name').setValue('');
        this.filterDevForm.get('product').setValue('');
        this.deviceList.clearFilters();
        this.licenseOrderList.applyLicenseOrderFilter('');
    }

    private closeDropDowns() {
        this.focusCodeInput(null, null);
        this.showSearch = false;
        this.showAddDeviceId = '';
        for (const dev of this.deviceList.filteredDeviceList) {
            dev.slo = false;
        }
    }

    public getLicenseStateText(state: number): string {
        let str = '';
        switch (state) {
            case 0:
                str = 'LIC_SAVED_NOT_APPLIED';
                break;
            case 1:
                str = 'LIC_APPLIED';
                break;
            case 2:
                str = 'LIC_REMOVED_NOT_APPLIED';
                break;
            case 3:
                str = 'LIC_NOT_SAVED';
                break;
            case 4:
                str = 'LIC_REMOVED_NOT_SAVED';
                break;
            }
        return str;
    }

    public showLicenseChoices(event: any, device: Device) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.closeDropDowns();
        const pIds: Array<string> = [];
        for (const lic of device.lics) {
            pIds.push(lic.pid);
        }
        device.blos = this.licenseOrderList.getOrdersExcludeProducts(pIds);
        if (device.blos) {
            device.slo = true;
        }
        // .dev-license-dropdown.expand max-height = 240px
        this.makeDropDownVisibleInDevList(event.target, 240);
    }

    public showLicensesForSelected(event: any, add: boolean = true) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.closeDropDowns();
        const selectedDevices: Array<Device> = this.deviceList.getSelected();
        if (add) {
            const pIds: Array<string> = [];
            for (const device of selectedDevices) {
                for (const lic of device.lics) {
                    if (pIds.indexOf(lic.pid) < 0) {
                        pIds.push(lic.pid);
                    }
                }
            }
            this.briefLicenseOrders = this.licenseOrderList.getOrdersExcludeProducts(pIds);
            this.showAddDeviceId = 'addselect';
        } else {
            const lIds: Array<number> = [];
            for (const device of selectedDevices) {
                for (const lic of device.lics) {
                    if (lIds.indexOf(lic.id) < 0) {
                        lIds.push(lic.id);
                    }
                }
            }
            this.briefLicenseOrders = this.licenseOrderList.getOrdersIncludeLicenses(lIds);

            this.showAddDeviceId = 'remselect';
        }
    }

    private removeBLic(blos: Array<BriefLicenseOrder>, pid: string) {
        // remove all licenses with matching product
        // must do in reverse order as will be removing items
        if (blos) {
            for (let ii = blos.length - 1; ii >= 0; ii--) {
                for (let jj = blos[ii].blics.length - 1; jj >= 0; jj--) {
                    if (blos[ii].blics[jj].pid === pid) {
                        if (blos[ii].blics.length === 1) {
                            // remove this blos because it will be empty
                            if (blos.length === 1) {
                                // last license in list, close list
                                for (const device of this.deviceList.filteredDeviceList) {
                                    device.slo = false;
                                }
                                this.showAddDeviceId = '';
                            } else {
                                blos.splice(ii, 1);
                            }
                        } else {
                            blos[ii].blics.splice(jj, 1);
                        }
                    }
                }
            }
        }
    }

    public addLicense(event: any, device: Device, licenseId: number) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const license = this.licenseOrderList.getLicenseById(licenseId);
        if (license) {
            const devices = (device ? [device] : this.deviceList.getSelected());
            this.deviceList.addLicenses(this.licenseOrderList, devices, [license]);
            this.removeBLic((device ? device.blos : this.briefLicenseOrders), license.pid);
        }
    }

    public remLicense(event: any, device: Device, licenseId: number) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const license = this.licenseOrderList.getLicenseById(licenseId);
        if (license) {
            const devices = (device ? [device] : this.deviceList.getSelected());
            this.deviceList.removeLicenses(this.licenseOrderList, devices, [license]);
            this.removeBLic((device ? device.blos : this.briefLicenseOrders), license.pid);
            if (device.exp) {
                const pIds: Array<string> = [];
                for (const lic of device.lics) {
                    pIds.push(lic.pid);
                }
                device.blos = this.licenseOrderList.getOrdersExcludeProducts(pIds);
            }
        }
    }

    public setManageMode(event: any, clearWait: boolean = true) {
        if (typeof event === 'string') {
            if (clearWait) {
                this.apiService.pleaseWait(0);
            }
            this.storageService.setSessionData({ manageMode: event });
            this.manageMode = event;
            this.filterDevForm.get('product').setValue('');
            this.deviceList.applyProductFilter('');
        }
    }

    public toggleSearch(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showSearch = !this.showSearch;
    }

    private makeDropDownVisibleInDevList(element: HTMLElement, height: number) {
        // if expanded section extends below visible region, scroll dev_list up
        if (element) {
            const tRect = element.getBoundingClientRect();
            const apd = document.querySelector('app-devices');
            if (apd) {
                const devList = apd.shadowRoot.querySelector('#dev_list');
                if (devList) {
                    const dlRect = devList.getBoundingClientRect();
                    // add 10px to height to insure totally visible
                    const overflow = (tRect.bottom + height + 10) - dlRect.bottom;
                    if (overflow > 0) {
                        devList.scrollTop += overflow;
                    }
                }
            }
        }
    }

    public toggleDeviceExpand(event: any, device: Device) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.manageMode === 'lic') {
            this.closeDropDowns();
            // if device above this one is expanded then by default this expanded
            //  section will be visible when previous one collapsed
            // .dev-row-expanded.expand max-height = 210px
            if (!this.deviceList.aboveExpanded(device)) {
                this.makeDropDownVisibleInDevList(event.target, 210);
            }
            this.refreshing = false;
            this.deviceList.toggleExpand(device);
        }
    }

    public toggleDeviceSelect(event: any, selDevice: Device) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.deviceList.toggleSelect(selDevice);
        this.allDeviceSelect = false;
    }

    public toggleAllDeviceSelect(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.allDeviceSelect = !this.allDeviceSelect;
        this.deviceList.toggleAllSelect(this.allDeviceSelect);
    }

    public toggleLicenseSelect(event: any, order: LicenseOrder, license: License) {
        license.sel = !license.sel;
        if (event.shiftKey) {
            // range
            let first = -1;
            let last = -1;
            let clicked = -1;
            for (let ii = 0; ii < order.lics.length; ii++) {
                if (order.lics[ii].sel) {
                    if (first < 0) {
                        first = ii;
                    }
                    last = ii;
                }
                if (order.lics[ii].id === license.id) {
                    clicked = ii;
                }
            }
            // if first === -1 then just unselected the only one selected, do nothing
            //  else if last === -1 then just selected only one, do nothing
            //  else if license.sel and clicked <= first set clicked to last-1 to license.sel
            //  else if license.sel and clicked > first set first to clicked-1 to license.sel
            //  else if last > clicked set last to clicked-1 to license.sel
            if ((first >= 0) && (last >= 0)) {
                if (license.sel) {
                    if (clicked <= first) {
                        for (let jj = clicked; jj < last; jj++) {
                            order.lics[jj].sel = license.sel;
                        }
                    } else if (clicked > first) {
                        for (let jj = first; jj < clicked; jj++) {
                            order.lics[jj].sel = license.sel;
                        }
                    }
                } else if (last > clicked) {
                    for (let jj = clicked + 1; jj <= last; jj++) {
                        order.lics[jj].sel = license.sel;
                    }
                    license.sel = !license.sel; // reselect the clicked row
                }
            }
        } else if (!event.ctrlKey) {
            // clear all others only if click without shift or ctrl pressed
            for (const lic of order.lics) {
                if (lic.id !== license.id) {
                    lic.sel = false;
                }
            }
        }
    }

    public registerWithCode(event: any, device: Device) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (device.codeinput) {
            const serialNumbersAndCode: Array<string> = [device.sn + ':' + device.ip + ':' + device.codeinput];
            this.message = '';
            this.apiService.setDeviceRegistration(this.selectedCustomer.email, serialNumbersAndCode, true).then(msg => {
                if (msg.length > 1) {
                    this.messageDialog.open(this.matDialog, 'SEL_DEV_REG', msg);
                } else {
                    this.message = msg[0];
                }
            });
        } else {
            this.message = 'MOD_DEV_REGC_EMPTY';
        }
    }

    public deviceRegistration(event: any, state: boolean) {
        this.message = '';
        let devices: Array<Device> = [];
        if (typeof event === 'string') {
            // is really serial number
            const thisDevice = this.deviceList.filteredDeviceList.find(device => {
                return device.sn === event;
            });
            if (thisDevice) {
                devices.push(thisDevice);
            }
        } else {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            devices = this.deviceList.getSelected();
        }
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
            this.messageDialogSubscription = null;
        }
        if (devices.length > 0) {
            const confirmList: Array<string> = [];
            const serialNumbersAndCode: Array<string> = [];
            devices.forEach(device => {
                confirmList.push(device.sn + ' / ' + device.name);
                serialNumbersAndCode.push(device.sn + ':' + device.ip + ':' + device.act);
            });
            this.messageDialogSubscription = this.messageDialog.confirmation.asObservable().subscribe(result => {
                if (this.messageDialogSubscription) {
                    this.messageDialogSubscription.unsubscribe();
                    this.messageDialogSubscription = null;
                }
                if (result) {
                    this.apiService.setDeviceRegistration(this.selectedCustomer.email, serialNumbersAndCode, state).then(msg => {
                        if (msg.length > 1) {
                            this.messageDialog.open(this.matDialog, (state ? 'SEL_DEV_REG' : 'SEL_DEV_UNREG'), msg);
                        } else {
                            this.message = msg[0];
                        }
                    });
                }
            });
            this.messageDialog.open(this.matDialog, (state ? 'SEL_DEV_REG' : 'SEL_DEV_UNREG'), confirmList,  { confirm: true });
        }
    }

    public viewLog(str: string) {
        const selectedDevices = this.deviceList.getSelected();
        const devIds: Array<string> = [];
        for (const device of selectedDevices) {
            devIds.push(device.id.toString());
        }

        if (devIds.length > 0) {
            this.viewLogDialog.open(this.matDialog, 'VIEW_DEV_LOG', this.selectedCustomer.email, devIds.join(','), 'device');
        } else {
            this.apiService.pleaseWait(0);
            this.message = 'NO_DEVS_SELECTED';
        }
    }

}
