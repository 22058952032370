<div class='nav-panel'>
    <button *ngIf='enableCustomers' (click)='goCustomerList($event)'>
        <fa-icon [icon]='faHomeLg'></fa-icon> {{ 'CUSTOMER_LIST' | translate }}
    </button>
    <button [class.selected]="mode == 'dev'" (click)='updateMode($event, "dev")' [disabled]='!enableDevices'>
        <fa-icon [icon]='faLaptop'></fa-icon> {{ 'MAN_DEV' | translate }}
    </button>
    <button [class.selected]="mode == 'lic'" (click)='updateMode($event, "lic")' [disabled]='!enableDevices'>
        <fa-icon [icon]='faAddressCard'></fa-icon> {{ 'MAN_LIC' | translate }}
    </button>
    <button [class.selected]="mode == 'job'" (click)='goJobList($event)' [disabled]='!enableJobs'>
        <fa-icon [icon]='faHammer'></fa-icon> {{ 'MAN_JOB' | translate }}
    </button>
    <button [class.selected]="mode == 'license'" (click)='goLicenseList($event)'>
        <fa-icon [icon]='faKeySkeleton'></fa-icon> {{ 'VIEW_LICS' | translate }}
    </button>
    <div class='nav-separator'></div>
    <button *ngIf='logLabel != ""' (click)='openLogView($event)' [disabled]='!enableLogs'>
            <fa-icon [icon]='faHardHat'></fa-icon> {{ logLabel | translate }}
    </button>
    <button (click)='openFeedback($event)'>
        <fa-icon [icon]='faCommentEdit'></fa-icon> {{ 'FEEDBACK' | translate }}
    </button>
</div>
