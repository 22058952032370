import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { faSortUp, faSortDown, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { Customer } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { CustomerList } from '../models/customer-list';
import { StorageService } from '../storage.service';
import { ApiService } from '../api.service';


@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomersComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription> = [];

    public customerList: CustomerList;
    public searchCustForm: FormGroup;
    public message: string;
    public showSearch: boolean;

    // instantiate icon references
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faTimes = faTimes;
    public faCaretDown = faCaretDown;
    public faCaretUp = faCaretUp;

    constructor(
            private dataService: DataService,
            private apiService: ApiService,
            private router: Router,
            private formBuilder: FormBuilder,
            private storageService: StorageService,
        ) {
        this.showSearch = false;
        this.customerList = new CustomerList(this.dataService, this.apiService, this.storageService);
     }

    ngOnInit() {
        this.message = '';
        this.searchCustForm = this.formBuilder.group({
            search: [''],
            name: [''],
            email: [''],
            org: [''],
        });
        this.setupSubscriptions();
        this.searchCustForm.get('search').setValue(this.customerList.getSearch());
        this.searchCustForm.get('name').setValue(this.customerList.getNameSearch());
        this.searchCustForm.get('email').setValue(this.customerList.getEmailSearch());
        this.searchCustForm.get('org').setValue(this.customerList.getOrgSearch());
        if (!this.customerList.searchOkay) {
            this.storageService.getCookieData(['dmpuser']).toPromise().then(vals => {
                const email = vals['dmpuser'] ? vals['dmpuser'] : '';
                this.searchCustForm.get('email').setValue(email);
                this.customerList.setEmailSearch(email);
                this.search(null);
            });
        } else {
            this.search(null);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    private setupSubscriptions() {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.searchCustForm.get('search').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.searchCustForm.get('name').setValue('');
                        this.searchCustForm.get('email').setValue('');
                        this.searchCustForm.get('org').setValue('');
                    }
                    this.customerList.setSearch(value);
            }));
            this.subscriptions.push(this.searchCustForm.get('name').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.searchCustForm.get('search').setValue('');
                    }
                    this.customerList.setNameSearch(value);
            }));
            this.subscriptions.push(this.searchCustForm.get('email').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.searchCustForm.get('search').setValue('');
                    }
                    this.customerList.setEmailSearch(value);
            }));
            this.subscriptions.push(this.searchCustForm.get('org').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.searchCustForm.get('search').setValue('');
                    }
                    this.customerList.setOrgSearch(value);
            }));
            this.subscriptions.push(this.dataService.getActionObservable().subscribe(action => {
                this.handleAction(action);
            }));
            this.subscriptions.push(this.customerList.getUpdatedObservable().subscribe(state => {
                if (state) {
                    this.apiService.pleaseWait(0);
                }
            }));
        }
    }

    public handleSearchKey(event: KeyboardEvent) {
        const key = event.key.toLowerCase();
        if (key === 'tab') {
            this.showSearch = true;
        } else if (key === 'enter') {
            this.customerList.performSearch();
        }
    }

    private handleAction(action: string) {
        switch (action) {
            case 'click':
                break;
            case 'escape':
                break;
            default:
                break;
        }
    }

    public clearSearch() {
        this.searchCustForm.get('search').setValue('');
        this.searchCustForm.get('email').setValue('');
        this.searchCustForm.get('name').setValue('');
        this.searchCustForm.get('org').setValue('');
        this.customerList.clearSearch();
    }

    public search(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showSearch = false;
        if (this.customerList.searchOkay) {
            this.customerList.performSearch().then(msg => {
                this.message = msg;
            });
        }
    }

    public toggleSearch(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showSearch = !this.showSearch;
    }

    public toggleSortOrder(event: any, which: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.customerList.toggleSortOrder(which);
    }

    public showDevices(customer: Customer) {
        this.apiService.pleaseWait(30);
        this.dataService.updateSelectedCustomer(customer);
        this.router.navigateByUrl('/devices');
    }

}
