import { Injectable } from '@angular/core';

import { Observable, from, BehaviorSubject } from 'rxjs';

import { TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
  })
export class LanguageService  {
    private language = new BehaviorSubject<string>('en-US');

    public languageChanged(language: string) {
        this.language.next(language);
    }

    public getLanguageSubscription(): Observable<any> {
        return this.language.asObservable();
    }
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams) {
        return params.key;
    }
}

export class WebpackTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        return from(import(`../assets/i18n/${lang}.json`));
    }
}
