import { from, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LanguageService {
    constructor() {
        this.language = new BehaviorSubject('en-US');
    }
    languageChanged(language) {
        this.language.next(language);
    }
    getLanguageSubscription() {
        return this.language.asObservable();
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(); }, token: LanguageService, providedIn: "root" });
export class MyMissingTranslationHandler {
    handle(params) {
        return params.key;
    }
}
export class WebpackTranslateLoader {
    getTranslation(lang) {
        return from(import(`../assets/i18n/${lang}.json`));
    }
}
