<app-nav (setMode)="goDevices($event)" (viewLog)="null" [mode]="'license'" [logLabel]="''"></app-nav>
<div class='lics-panel-right'>
    <div class='lics-head-row'>
        <div class='lics-customer lics-header'>
            <span *ngIf='selectedCustomer' class='lics-label'>{{ 'VIEW_FOR' | translate }}: {{ selectedCustomer.name }} ({{ selectedCustomer.email }})</span>
        </div>
        <div class='lics-hdr-butts'>
            <button (click)='viewPendingOrders($event)' [disabled]="!pendingOrders"
                class='lics-button wide back-blue'>{{ 'VPORDERS' | translate }}</button>
            <button (click)='manageViewers($event)' [disabled]="!allowManageViewers"
                class='lics-button wide back-blue'>{{ 'MNG_VIEWERS' | translate }}</button>
        </div>
    </div>
    <div class='lics-head-row lics-fixed'>
        <form [formGroup]='filterLicenseForm' class='lics-filter'>
            <input formControlName='filter' placeholder="{{ 'FILTER_TEXT' | translate }}" type='search'>
            <fa-icon *ngIf='!showSearch' [icon]='faCaretDown' class='header' (click)='toggleSearch($event)'></fa-icon>
            <fa-icon *ngIf='showSearch' [icon]='faCaretUp' class='header' (click)='toggleSearch($event)'></fa-icon>
            <div class='lics-filter-dropdown' [class.expand]='showSearch' (click)="ignoreClick($event)">
                <div class="lics-filter-row">
                    <div class="lics-filter-col">{{ 'APPLICATION' | translate }}</div>
                    <div class="lics-filter-col">
                        <input formControlName='name' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'name')">
                    </div>
                </div>
                <div class="lics-filter-row">
                    <div class="lics-filter-col">{{ 'STATUS' | translate }}</div>
                    <div class="lics-filter-col pointer">
                        <select formControlName='status'>
                            <option value='ALL'>{{ 'ALL_LIC' | translate }}</option>
                            <option value='ACT'>{{ 'ACT_LIC' | translate }}</option>
                        </select>
                    </div>
                </div>
                <div class="lics-filter-row">
                    <div class="lics-filter-col">{{ 'DEV_SORDER' | translate }}</div>
                    <div class="lics-filter-col">
                        <input formControlName='so' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'so')">
                    </div>
                </div>
                <div class="lics-filter-row">
                    <div class="lics-filter-col">{{ 'DEV_PORDER' | translate }}</div>
                    <div class="lics-filter-col">
                        <input formControlName='po' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'po')">
                    </div>
                </div>
                <div class="lics-filter-row">
                    <div class="lics-filter-col">{{ 'LIC_ORG' | translate }}</div>
                    <div class="lics-filter-col">
                        <input formControlName='org' placeholder="{{ 'FILTER_TEXT' | translate }}"
                            type='search' (click)="fixClearInput($event, 'org')">
                    </div>
                </div>
            </div>
        </form>
        <div class='status-message'>
            <span>{{ message | translate }}</span>
            <div>
                <fa-icon class='pointer' [icon]="faFileCsv" (click)="licenseList.exportCSV()"></fa-icon>
                <fa-icon class='pointer' [icon]="faFilePdf" (click)="licenseList.exportPDF()"></fa-icon>
            </div>
        </div>
    </div>
    <div class='lics-head-row'>
        <p class='sub-title'>{{ 'APP_LIC_T1' | translate }}</p>
        <p class='sub-title'>{{ 'APP_LIC_T2' | translate }} <span [innerHTML]="'DOWNLOADS' | regionstring"></span></p>
        <p class='sub-title'>{{ 'APP_LIC_T3' | translate }} <span [innerHTML]="'HOW_TO' | regionstring"></span></p>
    </div>
    <div class='lics-head-row'>
        <div class='lics-check lics-header'>
             <!-- (click)='toggleAllJobSelect($event)'> -->
            <!-- <div class="square-box">
                <input id="sub_job_all" type='checkbox' [(ngModel)]="allJobSelect" />
                <label for="sub_job_all"></label>
            </div> -->
        </div>
        <div class='lics-app lics-header'>
            {{ 'APPLICATION' | translate }}
        </div>
        <div class='lics-key lics-header'>
            {{ 'DEV_LIC_KEY' | translate }}
        </div>
        <div class='lics-qty lics-header'>
            {{ 'LIC_QTY' | translate }}
        </div>
        <div class='lics-exp lics-header pointer' (click)='toggleSortOrder($event, "expire")'>
            {{ 'EXPIRES' | translate }}
            <fa-icon *ngIf='licenseList.sortExpireAsc' [icon]='faSortDown'></fa-icon>
            <fa-icon *ngIf='!licenseList.sortExpireAsc' [icon]='faSortUp'></fa-icon>
        </div>
        <div class='lics-so lics-header'>
            {{ 'DEV_SORDER' | translate }}
        </div>
        <div class='lics-po lics-header'>
            {{ 'DEV_PORDER' | translate }}
        </div>
        <div class='lics-org lics-header'>
            {{ 'LIC_ORG' | translate }}
        </div>
    </div>
    <virtual-scroller class='lics-list' #jscroll [items]="licenseList.filteredLicenseList"
            [bufferAmount]='16' [enableUnequalChildrenSizes]='true' [stripedTable]='true'>
        <ng-container *ngFor='let license of jscroll.viewPortItems' >
            <section *ngIf='license.show' class='lics-section-row'>
                <div class='lics-row'>
                    <div class='lics-cell lics-check' (click)='toggleJobSelect($event, job)'>
                        <!-- <div class="square-box">
                            <input id="sub_{{ job.id }}" type='checkbox' [(ngModel)]="job.sel" />
                            <label for="sub_{{ job.id }}"></label>
                        </div> -->
                    </div>
                    <div class='lics-cell lics-app'>{{ license.name }}</div>
                    <div class='lics-cell lics-key'>{{ license.key }}</div>
                    <div class='lics-cell lics-qty'>{{ license.qty }}</div>
                    <div class='lics-cell lics-exp'>{{ license.exp }}</div>
                    <div class='lics-cell lics-so'>{{ license.so }}</div>
                    <div class='lics-cell lics-po'>{{ license.po }}</div>
                    <div class='lics-cell lics-org'>{{ license.org }}</div>
                </div>
            </section>
        </ng-container>
    </virtual-scroller>
</div>
<div *ngIf="showViewers" class='viewer-dialog' (click)="ignoreClick($event)">
    <div class='vd-title'>
        <span>{{ 'MNG_VIEWERS' | translate }}</span>
        <fa-icon [icon]='faTimes' (click)='closeViewersDialog($event)'></fa-icon>
        <p *ngIf="selectedCustomer">{{ selectedCustomer.email }}</p>
    </div>
    <ul>
        <li *ngFor="let viewer of viewerList; odd as isOdd" [class.odd]="isOdd">
            {{ viewer.email }}
            <fa-icon [icon]='faTrashAlt' (click)='deleteViewer($event, viewer.user_id)'></fa-icon>
        </li>
    </ul>
    <input id='input_viewer' type='email' value=''>
    <fa-icon class='add-viewer' [icon]='faPlusCircle' (click)='addViewer($event)'></fa-icon>
    <p *ngFor="let msg of msgViewers">{{ msg | translate }}</p>
</div>
