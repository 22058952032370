import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { StorageService } from './storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
export class DataService {
    constructor(storageService) {
        this.storageService = storageService;
        this.selectedCustomer = new BehaviorSubject(null);
        this.customers = new BehaviorSubject([]);
        this.liveLog = new BehaviorSubject([]);
        this.actionValue = new BehaviorSubject('');
        this.devices = new BehaviorSubject([]);
        this.licenseOrders = new BehaviorSubject([]);
        this.jobs = new BehaviorSubject([]);
        this.appLicenses = new BehaviorSubject([]);
        this.pendingOrdersFlag = new BehaviorSubject(false);
    }
    ngOnDestroy() {
    }
    clearLiveLog() {
        this.liveLog.next([]);
    }
    updateLiveLog(ev) {
        if (ev && ev.data) {
            try {
                const pdata = JSON.parse(ev.data);
                if (Array.isArray(pdata)) {
                    this.liveLog.next(pdata);
                }
            }
            catch (err) {
                console.error(err);
            }
        }
    }
    getLiveLogObservable() {
        return this.liveLog.asObservable();
    }
    updateSelectedCustomer(customer) {
        this.storageService.setSessionData({ selectedCustomer: customer });
        this.selectedCustomer.next(customer);
    }
    getSelectedCustomerObservable() {
        return this.selectedCustomer.asObservable();
    }
    getSelectedCustomer() {
        return this.selectedCustomer.value;
    }
    setCustomers(customers) {
        if (Array.isArray(customers)) {
            for (const customer of customers) {
                customer.org = customer.org.replace(/&amp;/g, '&').replace(/&apos;/g, '\'');
            }
            this.customers.next(customers);
        }
    }
    getCustomersObservable() {
        return this.customers.asObservable();
    }
    getCustomers() {
        return this.customers.getValue();
    }
    setDevices(devices) {
        // do deep copy of devices
        this.originalDevices = [];
        devices.forEach(dev => {
            this.originalDevices.push(cloneDeep(dev)); // make sure it's a copy
        });
        this.devices.next(devices);
    }
    getDevicesObservable() {
        return this.devices.asObservable();
    }
    getDevices() {
        return this.devices.getValue();
    }
    getOriginalDevices() {
        return this.originalDevices;
    }
    setLicenseOrders(orders) {
        for (const order of orders) {
            for (const lic of order.lics) {
                lic.prod = lic.prod.replace(/&amp;/g, '&').replace(/&apos;/g, '\'');
            }
        }
        this.licenseOrders.next(orders);
    }
    getLicenseOrdersObservable() {
        return this.licenseOrders.asObservable();
    }
    getLicenseOrders() {
        return this.licenseOrders.getValue();
    }
    setAppLicenses(orders) {
        this.appLicenses.next(orders);
    }
    getAppLicensesObservable() {
        return this.appLicenses.asObservable();
    }
    getAppLicenses() {
        return this.appLicenses.getValue();
    }
    setPendingOrdersFlag(flag) {
        this.pendingOrdersFlag.next(flag);
    }
    getPendingOrdersFlagObservable() {
        return this.pendingOrdersFlag.asObservable();
    }
    getPendingOrdersFlag() {
        return this.pendingOrdersFlag.getValue();
    }
    setAction(action) {
        this.actionValue.next(action);
    }
    getActionObservable() {
        return this.actionValue.asObservable();
    }
    setJobs(jobs) {
        this.jobs.next(jobs);
    }
    getJobsObservable() {
        return this.jobs.asObservable();
    }
    getJobs() {
        return this.jobs.getValue();
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.StorageService)); }, token: DataService, providedIn: "root" });
