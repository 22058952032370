import { DomSanitizer } from '@angular/platform-browser';
import zSpaceLogoUS from '../assets/images/zSpaceLogo_110x32.png';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class ImageService {
    constructor(sanitizer) {
        this.sanitizer = sanitizer;
        this.logoImageUS = this.getSafeUrl(zSpaceLogoUS);
    }
    getSafeUrl(url) {
        let safeUrl = '';
        if (url) {
            if (url.substring(0, 4) === 'data') {
                const arr = atob(url.split(',').pop()).match(/"(.*?)"/);
                if (arr && (arr.length > 1)) {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(arr[1]);
                }
            }
            if (safeUrl === '') {
                if ((url.substring(0, 4) !== 'http')
                    && (url.substring(0, window.location.origin.length) !== window.location.origin)) {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(window.location.origin + '/' + url);
                }
                else {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
                }
            }
        }
        return safeUrl;
    }
    getIconByContentType(type) {
        switch (type) {
            case 'logo': // not really a type
                return zSpaceLogoUS;
                break;
        }
        return '';
    }
}
ImageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageService_Factory() { return new ImageService(i0.ɵɵinject(i1.DomSanitizer)); }, token: ImageService, providedIn: "root" });
