import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { faLaptop, faAddressCard, faHardHat, faCommentEdit, faHammer, faKeySkeleton } from '@fortawesome/pro-light-svg-icons';
import { faHomeLg } from '@fortawesome/pro-solid-svg-icons';

import { RegionService } from '../region.service';
import { StorageService } from '../storage.service';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NavComponent implements OnInit, OnDestroy {
    @Input() mode: string;
    @Input() logLabel: string;
    @Output() setMode = new EventEmitter<string>();
    @Output() viewLog = new EventEmitter<string>();

    private subscriptions: Array<Subscription>;
    private userNameFull: string;

    public enableCustomers: boolean;
    public enableDevices: boolean;
    public enableJobs: boolean;
    public enableLogs: boolean;

    public faHomeLg = faHomeLg;
    public faLaptop = faLaptop;
    public faAddressCard = faAddressCard;
    public faHammer = faHammer;
    public faHardHat = faHardHat;
    public faCommentEdit = faCommentEdit;
    public faKeySkeleton = faKeySkeleton;

    constructor(
            private router: Router,
            private regionService: RegionService,
            private storageService: StorageService,
            private apiService: ApiService,
            private authenticationService: AuthenticationService,
        ) {
        this.subscriptions = [];
        this.userNameFull = '';
        this.enableCustomers = false;
        this.enableDevices = false;
        this.enableJobs = false;
        this.enableLogs = false;
    }

    ngOnInit() {
        this.storageService.getCookieData(['dmpuser']).toPromise().then(vals => {
            this.userNameFull = vals['dmpuser'] ? vals['dmpuser'] : '';
        });
        this.setupSubscriptions();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.authenticationService.getLoginObservable().subscribe(login => {
                if (login && login.res && (login.name !== '') && (login.id !== '')) {
                    switch (login.cc) {
                        case -1:
                            this.enableCustomers = true;
                            this.enableDevices = true;
                            this.enableJobs = true;
                            this.enableLogs = true;
                            break;
                        case 1:
                            this.enableCustomers = false;
                            this.enableDevices = true;
                            this.enableJobs = true;
                            this.enableLogs = true;
                            break;
                        default:
                            this.enableCustomers = false;
                            this.enableDevices = false;
                            this.enableJobs = false;
                            this.enableLogs = false;
                            break;
                    }
                }
            }));
        }
    }

    public goCustomerList(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.apiService.pleaseWait(30);
        this.router.navigateByUrl('/customers');
    }

    public updateMode(event: any, mode: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.apiService.pleaseWait(30);
        this.setMode.emit(mode);
    }

    public goJobList(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.mode !== 'job') {
            this.apiService.pleaseWait(30);
            this.router.navigateByUrl('/jobs');
        }
    }

    public goLicenseList(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (this.mode !== 'license') {
            this.apiService.pleaseWait(30);
            this.router.navigateByUrl('/licenses');
        }
    }

    public openLogView(event: any) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.logLabel !== '') {
            this.apiService.pleaseWait(30);
            this.viewLog.emit('');
        }
    }

    public openFeedback(event: any) {
        const url = this.regionService.lookupKey('FEEDBACK_URL')
                                    + '&email=' + this.userNameFull;
        window.open(url, '_blank');
    }


}
