/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authentication.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./authentication.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./authentication.service";
var styles_AuthenticationComponent = [i0.styles];
var RenderType_AuthenticationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AuthenticationComponent, data: {} });
export { RenderType_AuthenticationComponent as RenderType_AuthenticationComponent };
export function View_AuthenticationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.handleKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "auth-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "auth-dialog narrow"]], [[2, "waiting", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "auth-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "auth-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "auth-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "auth-body-right full-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "auth-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 1, "input", [["autocomplete", "on"], ["id", "auth_email"], ["required", ""], ["type", "email"]], [[8, "placeholder", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "input", [["autocomplete", "new-password"], ["id", "auth_password"], ["required", ""], ["type", "password"]], [[8, "placeholder", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "social-button login"]], [[2, "waiting", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitLogin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "auth-error"], ["id", "auth_error"]], [[2, "lower", null]], null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("WELCOME")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.wait; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.logoImage; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("EMAIL_PASSWORD")); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("ENTER_EMAIL")); _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("ENTER_PASSWORD")); _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.wait; _ck(_v, 16, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("SUBMIT_LOGIN")); _ck(_v, 17, 0, currVal_7); var currVal_8 = !_co.showSocialLogins; _ck(_v, 19, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform(_co.errorMessage)); _ck(_v, 20, 0, currVal_9); }); }
export function View_AuthenticationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "authentication", [], null, null, null, View_AuthenticationComponent_0, RenderType_AuthenticationComponent)), i1.ɵdid(1, 245760, null, 0, i3.AuthenticationComponent, [i4.DomSanitizer, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationComponentNgFactory = i1.ɵccf("authentication", i3.AuthenticationComponent, View_AuthenticationComponent_Host_0, {}, { closeDialog: "closeDialog" }, []);
export { AuthenticationComponentNgFactory as AuthenticationComponentNgFactory };
