import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import zSpaceLogoUS from '../assets/images/zSpaceLogo_110x32.png';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    private logoImageUS: SafeUrl;

    constructor(
                private sanitizer: DomSanitizer,
            ) {
        this.logoImageUS = this.getSafeUrl(zSpaceLogoUS);
    }

    public getSafeUrl(url: string): SafeUrl {
        let safeUrl: SafeUrl = '';
        if (url) {
            if (url.substring(0, 4) === 'data') {
                const arr = atob(url.split(',').pop()).match(/"(.*?)"/);
                if (arr && (arr.length > 1)) {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(arr[1]);
                }
            }
            if (safeUrl === '') {
                if ((url.substring(0, 4) !== 'http')
                        && (url.substring(0, window.location.origin.length) !== window.location.origin)) {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(window.location.origin + '/' + url);
                } else {
                    safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
                }
            }
        }
        return safeUrl;
    }

    public getIconByContentType(type: string): string {
        switch (type) {
            case 'logo': // not really a type
                return zSpaceLogoUS;
                break;
            }
        return '';
    }

}
