import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { LicenseOrder, BriefLicenseOrder, License } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';

export class LicenseOrderList {
    private subscriptions: Array<Subscription>;
    private matDialog: MatDialog;
    private messageDialog: MessageDialog;

    public orderList: Array<LicenseOrder>;

    constructor(
        dataService: DataService,
        matDialog: MatDialog,
        messageDialog: MessageDialog
    ) {
        this.subscriptions = [];
        this.orderList = [];

        this.matDialog = matDialog;
        this.messageDialog = messageDialog;

        this.subscriptions.push(dataService.getLicenseOrdersObservable().subscribe(licenseOrders => {
            if (!licenseOrders) {
                this.orderList = [];
            } else {
                licenseOrders.sort(this.sortLicenseOrderByExpires);
                for (const order of (licenseOrders as Array<LicenseOrder>)) {
                    let hasAvail = false;
                    for (const lic of order.lics) {
                        hasAvail = (lic.avail > 0);
                        if (lic.exp === 0) {
                            lic.texp = 'Never';
                        } else {
                            const date = new Date(lic.exp);
                            lic.texp = date.toLocaleDateString();
                        }
                    }
                    order.show = hasAvail;
                }
                this.orderList = licenseOrders;
            }
            this.applyLicenseOrderFilter('');
        }));
    }

    public destroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    private sortLicenseOrderByExpires(a: LicenseOrder, b: LicenseOrder): number {
        // get latest date from lics array with avail seats and compare
        let aexp = 0;
        let bexp = 0;
        for (const lic of a.lics) {
            aexp = Math.max(aexp, lic.exp);
        }
        for (const lic of b.lics) {
            bexp = Math.max(bexp, lic.exp);
        }
        return bexp - aexp;
    }

    public toggleOrderShow(order: LicenseOrder) {
        order.show = !order.show;
    }

    public applyLicenseOrderFilter(filter: string) {
        for (const order of this.orderList) {
            for (const lic of order.lics) {
                if (!filter || (lic.prod.indexOf(filter) >= 0)) {
                    lic.show = true;
                } else {
                    lic.show = false;
                }
            }
        }
    }

    public incrementAvail(id: number, count: number = 1) {
        for (const order of this.orderList) {
            for (const lic of order.lics) {
                if (lic.id === id) {
                    lic.avail = Math.min(lic.avail + count, lic.total);
                    return;
                }
            }
        }
    }

    public decrementAvail(id: number, count: number = 1) {
        for (const order of this.orderList) {
            for (const lic of order.lics) {
                if (lic.id === id) {
                    lic.avail = Math.max(lic.avail - count, 0);
                    return;
                }
            }
        }
    }

    public getOrdersIncludeLicenses(licenseIds: Array<number>): Array<BriefLicenseOrder> {
        const loList: Array<BriefLicenseOrder> = [];
        for (const order of this.orderList) {
            const bord: BriefLicenseOrder = { name: order.so + (order.po ? (' / ' + order.po) : ''), blics: []  };
            for (const lic of order.lics) {
                if (licenseIds.indexOf(lic.id) >= 0) {
                    bord.blics.push(
                        {   id: lic.id,
                            pid: lic.pid,
                            prod: lic.prod,
                            seats: lic.avail + '/' + lic.total,
                            exp: lic.texp
                        });
                }
            }
            if (bord.blics.length > 0) {
                loList.push(bord);
            }
        }
        return loList;
    }

    public getOrdersExcludeProducts(productIds: Array<string>): Array<BriefLicenseOrder> {
        const loList: Array<BriefLicenseOrder> = [];

        for (const order of this.orderList) {
            const bord: BriefLicenseOrder = { name: order.so + (order.po ? (' / ' + order.po) : ''), blics: []  };
            for (const lic of order.lics) {
                // if ((!productIds || (productIds.indexOf(lic.pid) < 0)) && (lic.avail > 0)) {
                if ((!productIds || (productIds.indexOf(lic.pid) < 0))) {
                    bord.blics.push(
                        {   id: lic.id,
                            pid: lic.pid,
                            prod: lic.prod,
                            seats: lic.avail + ' / ' + lic.total,
                            exp: lic.texp
                        });
                }
            }
            if (bord.blics.length > 0) {
                loList.push(bord);
            }
        }
        return loList;
    }

    public getLicenseById(id: number): License {
        for (const order of this.orderList) {
            for (const lic of order.lics) {
                if (lic.id === id) {
                    return lic;
                }
            }
        }
        return null;
    }

    public getOrderWithLicense(id: number): LicenseOrder {
        for (const order of this.orderList) {
            for (const lic of order.lics) {
                if (lic.id === id) {
                    return order;
                }
            }
        }
        return null;
    }

}
