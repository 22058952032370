import { of as observableOf } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "ngx-webstorage";
export class StorageService {
    constructor(cookieService, sessionStorage, localStorage) {
        this.cookieService = cookieService;
        this.sessionStorage = sessionStorage;
        this.localStorage = localStorage;
    }
    // set methods take object which should be a map of key=>value of items to be set
    setCookieData(obj) {
        const expires = new Date();
        expires.setTime(expires.getTime() + (18 * 60 * 60 * 1000));
        for (const key in obj) {
            if (obj[key]) {
                this.cookieService.set(key, obj[key], expires, '/', '.zspace.com', true, 'Lax');
            }
            else {
                this.removeCookieData([key]);
            }
        }
    }
    getCookieData(keys, defaultValue = '') {
        const valueMap = {};
        for (const key of keys) {
            const tmp = this.cookieService.get(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }
        return observableOf(valueMap);
    }
    removeCookieData(keys) {
        for (const key of keys) {
            this.cookieService.delete(key);
        }
    }
    clearCookieData() {
        this.cookieService.deleteAll();
    }
    setSessionData(obj) {
        for (const key in obj) {
            if (obj[key]) {
                this.sessionStorage.store(key, obj[key]);
            }
            else {
                this.removeSessionData([key]);
            }
        }
    }
    getSessionData(keys, defaultValue = '') {
        const valueMap = {};
        for (const key of keys) {
            const tmp = this.sessionStorage.retrieve(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }
        return observableOf(valueMap);
    }
    removeSessionData(keys) {
        for (const key of keys) {
            this.sessionStorage.clear(key);
        }
    }
    clearSessionData() {
        this.sessionStorage.clear();
    }
    setLocalData(obj) {
        for (const key in obj) {
            if (obj[key]) {
                this.localStorage.store(key, obj[key]);
            }
            else {
                this.removeLocalData([key]);
            }
        }
    }
    getLocalData(keys, defaultValue = '') {
        const valueMap = {};
        for (const key of keys) {
            const tmp = this.localStorage.retrieve(key);
            valueMap[key] = (tmp ? tmp : defaultValue);
        }
        return observableOf(valueMap);
    }
    removeLocalData(keys) {
        for (const key of keys) {
            this.localStorage.clear(key);
        }
    }
    clearLocalData() {
        this.localStorage.clear();
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.SessionStorageService), i0.ɵɵinject(i2.LocalStorageService)); }, token: StorageService, providedIn: "root" });
