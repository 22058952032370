import { Routes, CanDeactivate } from '@angular/router';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { AuthenticationComponent } from './authentication/authentication.component';
import { CustomersComponent } from './customers/customers.component';
import { DevicesComponent } from './devices/devices.component';
import { JobsComponent } from './jobs/jobs.component';
import { LicensesComponent } from './licenses/licenses.component';
polyfill({
    // use this to make use of the scroll behaviour
    dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});
// workaround to make scroll prevent work in iOS Safari > 10
try {
    window.addEventListener('touchmove', function () { }, { passive: false });
}
catch (e) { }
export class CanDeactivateGuard {
    canDeactivate(component) {
        return component.canDeactivate();
    }
}
// include both with and without hash so can work with both
const appRoutes = [
    { path: 'licenses', component: LicensesComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'customers', component: CustomersComponent },
    { path: 'devices', component: DevicesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: '**', component: AuthenticationComponent },
];
export class MyHammerConfig extends HammerGestureConfig {
    constructor() {
        super(...arguments);
        this.overrides = {
            // override hammerjs default configuration
            'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
            'pan': { direction: Hammer.DIRECTION_ALL },
            'pinch': { enable: true }
        };
    }
}
delete Hammer.defaults.cssProps.userSelect; // allow text selection
export class AppModule {
}
