import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import axios, { AxiosInstance } from 'axios';
import { StorageService } from '../storage.service';
import { DataService } from '../data.service';
import { ApiService } from '../api.service';

export interface CognitoLogin {
    name: string; // email
    id: string; // value for zs cookie
    cc: number; // number of customers this user has access to, -1=all, 0=none, 1=one
    vc: number; // 0=regular user, 1=can modify other account viewers
    res?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private loginError = new BehaviorSubject<string>('');
    private resetStatus = new BehaviorSubject<number>(0);
    private cognitoLogin = new BehaviorSubject<CognitoLogin>({name: '', id: '', cc: 0, vc: 0 });
    private myAxios: AxiosInstance;

    constructor(
            private storageService: StorageService,
            private dataService: DataService,
            private apiService: ApiService,
        ) {
        this.updateLogin({ name: '', id: '', cc: 0, vc: 0 });
        this.errorChanged('');

        axios.defaults.headers.post['Content-Type'] = 'application/json';
        this.myAxios = axios.create({
            timeout: 60000, // 60s timeout
        });
    }

    public clearError() {
        this.errorChanged('');
    }

    public async logout(): Promise<any> {
        await this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true });
        return this.myAxios.post('/logout', { withCredentials: true });
    }

    private validateEmail(email: string) {
        return /.+@.+\..+/.test(email);
    }

    public login(email: string, password: string): boolean {
        this.errorChanged('');
        if (!this.validateEmail(email)) {
            this.errorChanged('ENTER_EMAIL');
            return false;
        } else if (!password) {
            this.errorChanged('ENTER_PASSWORD');
            return false;
        }
        const authenticationData = {
            un: email,
            pw: password,
        };
        this.apiService.pleaseWait(30);

        this.storageService.clearSessionData();
        this.dataService.updateSelectedCustomer(null);

        this.myAxios.post('https://api.zspace.com/cognito/login', authenticationData, { withCredentials: true })
            .then(function(response: any) {
                if (response && response.data && (response.data.status === 'success')
                    && response.data.payload && response.data.payload.cookie_hash) {
                    this.storageService.setCookieData({ zs: response.data.payload.cookie_hash });
                    this.getUserInfo(true);
                } else {
                    this.apiService.pleaseWait(0);
                    this.errorChanged('INVALID_CREDENTIALS');
                }
            }.bind(this)).catch(function(error: any) {
                this.apiService.pleaseWait(0);
                console.error(error);
                this.errorChanged('HTTP_TIMEOUT');
            }.bind(this));

        return true;
    }

    public getUserInfo(setInsufficient: boolean) {
        this.errorChanged('');
        this.apiService.pleaseWait(10);

        this.myAxios.post('/login', { withCredentials: true }).then(resp => {
            if (resp && (resp.status === 200) && resp.data && resp.data.res && (resp.data.res === 'success')) {
                this.updateLogin(resp.data);
            } else {
                if (setInsufficient) {
                    this.errorChanged('NOT_AUTHORIZED');
                }
                // if unsufficient, then logout of zspace.com
                document.cookie = 'dmpuser=';
                document.cookie = 'dmpid=';
                this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true }).then(lo1resp => {
                    this.apiService.pleaseWait(0);
                }).catch(locatch => {
                    this.apiService.pleaseWait(0);
                });
            }
        }).catch (error => {
            // if error, then logout of zspace.com
            document.cookie = 'dmpuser=';
            document.cookie = 'dmpid=';
            this.myAxios.get('https://api.zspace.com/cognito/logout', { withCredentials: true }).then(lo1resp => {
                this.apiService.pleaseWait(0);
            }).catch(locatch => {
                this.apiService.pleaseWait(0);
            });
        });
    }

    private updateLogin(login: CognitoLogin) {
        this.cognitoLogin.next(login);
    }
    public getLoginObservable(): Observable<CognitoLogin> {
        return this.cognitoLogin.asObservable();
    }
    public getLogin(): CognitoLogin {
        return this.cognitoLogin.getValue();
    }
    public getUserEmail(): string {
        return this.getLogin().name;
    }

    private errorChanged(error: string) {
        this.loginError.next(error);
    }
    public getErrorSubscription(): Observable<string> {
        return this.loginError.asObservable();
    }

    private resetStatusChanged(status: number) {
        this.resetStatus.next(status);
    }
    public getResetStatusSubscription(): Observable<number> {
        return this.resetStatus.asObservable();
    }

}
