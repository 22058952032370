export class Constants {
}
Constants.languages = [
    { title: 'English', languageCode: 'en-US', subtag: 'en' },
];
Constants.jobStatusList = [
    'new',
    'blocked',
    'ready',
    'in-progress',
    'complete',
    'finalized',
    'ready-to-delete',
];
// LogHeader arrays should all be same length
Constants.deviceLogHeader = [
    'Date',
    'Event',
    'Device',
    'Product',
    'User',
    'From',
    'Device ip',
    'Code', ''
];
Constants.jobLogHeader = [
    'Date',
    'Event',
    'Devices',
    'Job Type',
    'User',
    'Completion Status',
    'Blocked by Job',
    '',
];
const ɵ0 = () => {
    try {
        // tslint:disable-next-line:no-var-requires
        return require('../../../version-info.json');
    }
    catch (_a) {
        // In dev the file might not exist:
        return { tag: 'v0.0.0', hash: 'dev' };
    }
};
export const versionInfo = (ɵ0)();
export { ɵ0 };
