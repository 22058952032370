import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faSortUp, faSortDown, faFileCsv, faFilePdf, faPlusCircle, faTimes } from '@fortawesome/pro-light-svg-icons';

import { Customer } from '../../../../server/src/web-front-end/api-interfaces';
import { DataService } from '../data.service';
import { MessageDialog } from '../message-dialog/message-dialog.component';
import { AppLicenseList, LicenseViewer } from '../models/app-license-list';
import { StorageService } from '../storage.service';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
    selector: 'app-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LicensesComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription>;
    private messageDialog: MessageDialog;
    private messageDialogSubscription: Subscription;
    private licensesLoaded: boolean;

    public message: string;
    public filterLicenseForm: FormGroup;
    public selectedCustomer: Customer;
    public showSearch: boolean;
    public licenseList: AppLicenseList;
    public pendingOrders: boolean;
    public showViewers: boolean;
    public viewerList: Array<LicenseViewer>;
    public msgViewers: Array<string>;
    public allowManageViewers: boolean;

    // instantiate icon references
    public faCaretDown = faCaretDown;
    public faCaretUp = faCaretUp;
    public faSortUp = faSortUp;
    public faSortDown = faSortDown;
    public faFileCsv = faFileCsv;
    public faFilePdf = faFilePdf;
    public faTrashAlt = faTrashAlt;
    public faPlusCircle = faPlusCircle;
    public faTimes = faTimes;

    constructor(
            private router: Router,
            private translate: TranslateService,
            private dataService: DataService,
            private apiService: ApiService,
            private formBuilder: FormBuilder,
            private matDialog: MatDialog,
            private storageService: StorageService,
            private authenticationService: AuthenticationService,
        ) {
        this.subscriptions = [];
        this.message = '';
        this.showSearch = false;
        this.messageDialog = new MessageDialog();
        this.licenseList = new AppLicenseList(this.dataService, matDialog, this.messageDialog);
        this.pendingOrders = false;
        this.showViewers = false;
        this.viewerList = [];
        this.msgViewers = [];
        this.allowManageViewers = false;
        this.licensesLoaded = false;
    }

    ngOnInit() {
        this.filterLicenseForm = this.formBuilder.group({
            filter: '',
            name: '',
            status: '',
            so: '',
            po: '',
            org: '',
        });
        this.clearFilters();
        this.setupSubscriptions();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        if (this.messageDialogSubscription) {
            this.messageDialogSubscription.unsubscribe();
        }
    }

    private setupSubscriptions(): void {
        if (this.subscriptions.length === 0) {
            this.subscriptions.push(this.filterLicenseForm.get('filter').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('name').setValue('');
                        this.filterLicenseForm.get('status').setValue('ALL');
                        this.filterLicenseForm.get('so').setValue('');
                        this.filterLicenseForm.get('po').setValue('');
                        this.filterLicenseForm.get('org').setValue('');
                        this.licenseList.applyNameFilter('');
                        this.licenseList.applyStatusFilter('ALL');
                        this.licenseList.applySOFilter('');
                        this.licenseList.applyPOFilter('');
                        this.licenseList.applyOrgFilter('');
                    }
                    this.licenseList.applyFilter(value);
            }));
            this.subscriptions.push(this.filterLicenseForm.get('name').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('filter').setValue('');
                    }
                    this.licenseList.applyNameFilter(value);
            }));
            this.subscriptions.push(this.filterLicenseForm.get('status').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('filter').setValue('');
                    }
                    this.licenseList.applyStatusFilter(value);
            }));
            this.subscriptions.push(this.filterLicenseForm.get('so').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('filter').setValue('');
                    }
                    this.licenseList.applySOFilter(value);
            }));
            this.subscriptions.push(this.filterLicenseForm.get('po').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('filter').setValue('');
                    }
                    this.licenseList.applyPOFilter(value);
            }));
            this.subscriptions.push(this.filterLicenseForm.get('org').valueChanges.pipe(
                distinctUntilChanged(), debounceTime(200) )
                .subscribe(value => {
                    if (value) {
                        this.filterLicenseForm.get('filter').setValue('');
                    }
                    this.licenseList.applyOrgFilter(value);
            }));
            this.subscriptions.push(this.licenseList.getUpdatedObservable().subscribe(state => {
                if (state) {
                    this.licensesLoaded = true;
                }
            }));
            this.subscriptions.push(this.dataService.getSelectedCustomerObservable().subscribe(customer => {
                this.message = '';
                if (customer) {
                    this.selectedCustomer = customer;
                    this.allowManageViewers = (customer.email === this.authenticationService.getUserEmail());
                } else {
                    this.allowManageViewers = false;
                }
                this.getAppLicenses();
            }));
            this.subscriptions.push(this.authenticationService.getLoginObservable().subscribe(login => {
                if (this.selectedCustomer) {
                    this.allowManageViewers = ((this.selectedCustomer.email === this.authenticationService.getUserEmail())
                                                || (login.vc === 1));
                }
                this.getAppLicenses();
            }));
            this.subscriptions.push(this.dataService.getActionObservable().subscribe(action => {
                this.handleAction(action);
            }));
            this.subscriptions.push(this.dataService.getPendingOrdersFlagObservable().subscribe(flag => {
                this.pendingOrders = flag;
            }));
        }
    }

    private getAppLicenses() {
        const email = (this.selectedCustomer ? this.selectedCustomer.email : this.authenticationService.getUserEmail());
        if (email && !this.licensesLoaded) {
            this.apiService.getAppLicenses(email).then(msg => {
                this.apiService.pleaseWait(0);
                this.message = msg;
            }).catch(err => {
                this.apiService.pleaseWait(0);
                this.message = err;
            });
        }
    }

    private handleAction(action: string) {
        switch (action) {
            case 'click':
                this.closeDropDowns();
                this.closeViewersDialog(null);
                if (this.licenseList.filteredLicenseList.length > 0) {
                    this.message = '';
                }
                break;
            case 'escape':
                this.closeDropDowns();
                this.closeViewersDialog(null);
                if (this.licenseList.filteredLicenseList.length > 0) {
                    this.message = '';
                }
                break;
            default:
                break;
        }
    }

    public fixClearInput(event: MouseEvent, ele: string) {
        if (event && (ele !== '')) {
            const targ = event.target;
            const rect = (targ as HTMLElement).getBoundingClientRect();
            if (event.x >= (rect.right - 20)) {
                this.filterLicenseForm.get(ele).setValue('');
            }
        }
    }

    public ignoreClick(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    public toggleSortOrder(event: any, which: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.licenseList.toggleSortOrder(which);
    }

    public clearFilters() {
        this.message = '';
        this.filterLicenseForm.get('name').setValue('');
        this.filterLicenseForm.get('status').setValue('ALL');
        this.filterLicenseForm.get('so').setValue('');
        this.filterLicenseForm.get('po').setValue('');
        this.filterLicenseForm.get('org').setValue('');
        this.licenseList.clearFilters();
    }

    private closeDropDowns() {
        this.showSearch = false;
    }

    public toggleSearch(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showSearch = !this.showSearch;
    }

    public goDevices(event: any) {
        if (typeof event === 'string') {
            this.storageService.setSessionData({ manageMode: event });
            this.router.navigateByUrl('/devices');
        }
    }

    public manageViewers(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.msgViewers = [];
        this.apiService.getViewers('').then(viewers => {
            this.showViewers = true;
            this.viewerList = viewers;
        });
    }
    public addViewer(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.msgViewers = [];
        // get input and validate email address format
        const app = document.querySelector('app-licenses');
        if (app) {
            const shadow = app.shadowRoot;
            if (shadow) {
                const inp: HTMLInputElement = shadow.querySelector('#input_viewer');
                if (inp && inp.value) {
                    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
                    if (!regex.test(inp.value)) {
                        this.msgViewers = ['BAD_EMAIL'];
                    } else {
                        this.apiService.addViewer(inp.value).then(result => {
                            if (!result || !result.added) {
                                this.msgViewers = ['FA_VIEWER'];
                                if (result.title) {
                                    this.msgViewers.push(result.title);
                                }
                                if (result.msg) {
                                    this.msgViewers.push(result.msg);
                                }
                            } else {
                                this.manageViewers(null);
                            }
                        });
                    }
                }
            }
        }
    }
    public deleteViewer(event: any, user_id: string) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.msgViewers = [];
        this.apiService.deleteViewer(user_id).then(result => {
            if (!result || (result.title !== 'Removed')) {
                this.msgViewers = ['FD_VIEWER'];
                if (result.title) {
                    this.msgViewers.push(result.title);
                }
                if (result.msg) {
                    this.msgViewers.push(result.msg);
                }
            } else {
                this.manageViewers(null);
            }
        });
    }
    public closeViewersDialog(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.showViewers = false;
    }

    public viewPendingOrders(event: any) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.apiService.getPendingOrders(this.selectedCustomer.email).then(orders => {
            if (typeof orders === 'string') {
                this.message = orders;
            } else {
                const labels = [
                    'DEV_SORDER',
                    'LIC_ORG',
                    'LIC_PDESC',
                    'LIC_PCODE',
                    'LIC_QTY',
                    'STATUS',
                    'LIC_SHIP_DATE'
                ];
                this.translate.get(labels).toPromise().then(text => {
                    let header = '';
                    for (const label of labels) {
                        if (header !== '') {
                            header += ',';
                        }
                        header += text[label];
                    }
                    const lines = [header];
                    for (const order of orders) {
                        lines.push(
                            order.sales_order + ',' +
                            order.end_user_licensee + '\n' + order.end_user_email + ',' +
                            order.product_name.replace(/,/g, ' ') + ',' +
                            order.product_code + ',' +
                            order.qty.replace(/,/g, '') + ',' +
                            order.status.replace(/,/g, ' ') + ',' +
                            order.estimated_ship_date
                        );
                    }
                    this.messageDialog.open(this.matDialog, 'VPORDERS', lines, { split: true });
                });
            }
        });
    }

}
